import InputForm from '../../../../_common/components/forms/InputForm';
import React from 'react';
import SelectForm from '../../../../_common/components/form_components/select/SelectForm';
import {
    Button,
    Form,
    InputNumber,
    Spin
} from 'antd';
import { createFeeConfigService, updateFeeConfigService } from '../../../../_services/fee_config/FeeConfigServices';
import { FormInstance } from 'antd/lib/form';
import { IConfigurationFeeConfigFormProps } from '../interfaces/IConfigurationFeeConfigFormProps';
import { loading_icon } from '../../../../_common/components/loading/LoadingIcon';
import { openErrorNotification, openSuccessNotification } from '../../../../_heplers/NotificationHelper';
import { validateMessages } from '../../../../_common/ValidationCominications';

export class ConfigurationFeeConfigForm extends React.Component<IConfigurationFeeConfigFormProps> {
    _is_mounted = true;
    _form_ref = React.createRef<FormInstance>();

    state = {
        loading: false,
        message: undefined
    };

    componentWillUnmount = () => {
        this._is_mounted = false;
    };

    public render() {
        const { loading } = this.state;
        const {
            data,
            available_sites,
            dictionaries,
            loading_dictionaries,
            setModalVisible,
        } = this.props;

        const filtered_dictionaries_currencies = dictionaries.currencies?.filter(x => x.name !== 'NULL')

        return (
            <Spin spinning={loading} indicator={loading_icon(60)}>
                <Form
                    style={{ textAlign: 'left', marginTop: 30 }}
                    layout='vertical'
                    name='control-ref'
                    onFinish={this.onFinish}
                    validateMessages={validateMessages()}
                    ref={this._form_ref}
                    initialValues={{
                        feePercentage: data?.feePercentage,
                        feeType: data?.feeType,
                        fixed: data?.fixed,
                        // formulaType: data?.formulaType,
                        siteUuid: data?.siteUuid,
                        threshold: data?.threshold,
                        trigger: data?.trigger,
                        baseCurrency: data?.baseCurrency,
                        value: data?.value?.toString(),
                        order: data?.order
                    }}
                >
                    <div className='grid_col_2'>
                        <Form.Item
                            name='baseCurrency'
                            label='Base Currency'
                            rules={[{ required: true }]}
                        >
                            <SelectForm
                                placeholder='select'
                                showSearch
                                loading={loading_dictionaries}
                                data={filtered_dictionaries_currencies}
                                filterOption={(input: any, option: any) => (option.props.children ? option.props.children : "").toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            />
                        </Form.Item>
                        <Form.Item
                            name='feePercentage'
                            label='Fee Percente'
                            rules={[{ required: true }]}
                        >
                            <InputNumber
                                min={0}
                                max={100}
                                size='large'
                                precision={2}
                                style={{ width: '100%' }}
                                formatter={value => `${value}%`}
                                placeholder='enter fee percente'
                                parser={(value: any) => value.replace('%', '')}
                            />
                        </Form.Item>
                        <Form.Item
                            name='feeType'
                            label='Fee Type'
                            rules={[{ required: true }]}
                        >
                            <SelectForm
                                placeholder='select'
                                showSearch
                                loading={loading_dictionaries}
                                data={dictionaries.fee_types}
                            />
                        </Form.Item>
                        <Form.Item
                            name='fixed'
                            label='Fixed'
                            rules={[{ required: true }]}
                        >
                            <InputNumber
                                min={0}
                                size='large'
                                precision={2}
                                style={{ width: '100%' }}
                                placeholder='enter fixed'
                            />
                        </Form.Item>
                        {/* <Form.Item
                            name='formulaType'
                            label='Formula Type'
                        >
                            <SelectForm
                                placeholder='select'
                                allowClear
                                loading={loading_dictionaries}
                                data={dictionaries.formula_types}
                            />
                        </Form.Item> */}
                        <Form.Item
                            name='siteUuid'
                            label='Site Uuid'
                        >
                            <SelectForm
                                allowClear
                                showSearch
                                placeholder='select'
                                loading={loading_dictionaries}
                                data={available_sites}
                                filterOption={(input: any, option: any) => (option.props.children ? option.props.children : "").toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            />
                        </Form.Item>
                        <Form.Item
                            name='threshold'
                            label='Threshold'
                            rules={[{ required: true }]}
                        >
                            <InputNumber
                                min={0}
                                size='large'
                                precision={2}
                                style={{ width: '100%' }}
                                placeholder='enter threshold'
                            />
                        </Form.Item>
                        <Form.Item
                            name='trigger'
                            label='Trigger'
                            rules={[{ required: true }]}
                        >
                            <InputForm
                                placeholder='enter trigger'
                            />
                        </Form.Item>
                        <Form.Item
                            name='value'
                            label='Value'
                        >
                            <InputNumber
                                min={0}
                                size='large'
                                precision={2}
                                style={{ width: '100%' }}
                                placeholder='enter value'
                            />
                        </Form.Item>
                        <Form.Item
                            name='order'
                            label='Order'
                            rules={[{ required: true }]}
                        >
                            <InputNumber
                                min={0}
                                size='large'
                                precision={0}
                                style={{ width: '100%' }}
                                placeholder='enter order'
                            />
                        </Form.Item>
                    </div>
                    <div className='grid_col_2'>
                        <Button
                            className='common_cancel_button'
                            onClick={() => setModalVisible(false)}
                        >
                            Cancel
                        </Button>
                        <Form.Item shouldUpdate={true}>
                            <Button
                                className='common_submit_button'
                                htmlType='submit'
                                type='primary'
                                style={{ width: '100%' }}
                            >
                                Save
                            </Button>
                        </Form.Item>
                    </div>
                </Form>
            </Spin>
        );
    }

    onFinish = async (values: any) => {
        this.setState({ loading: true });

        const {
            data,
            updateObjectInArray,
            addObjectToArray
        } = this.props;

        let fee_config: any = {
            baseCurrency: values.baseCurrency,
            feePercentage: values.feePercentage,
            feeType: values.feeType,
            fixed: values.fixed,
            // formulaType: values.formulaType,
            siteUuid: values.siteUuid,
            trigger: values.trigger,
            threshold: values.threshold,
            value: values.value?.toString(),
            order: values.order
        };

        if (data?.id) {
            // Update fee config
            await updateFeeConfigService(data.id, fee_config)
                .then(response => {
                    if (response) {
                        fee_config.id = data.id;
                        fee_config.key = data.id;

                        updateObjectInArray!(fee_config);
                        openSuccessNotification(`Fee cofig updated`);
                    }
                    else {
                        openErrorNotification(`Service doesn't work`);
                    }
                })
                .finally(() => this._is_mounted && this.setState({ loading: false }));
        }
        else {
            // Add new fee config
            await createFeeConfigService(fee_config)
                .then(response => {
                    if (response) {
                        fee_config.id = response;
                        fee_config.key = response;
                        addObjectToArray!(fee_config);
                        openSuccessNotification(`Fee config created`);
                    }
                    else {
                        openErrorNotification(`Service doesn't work`);
                    }
                })
                .finally(() => this._is_mounted && this.setState({ loading: false }));
        }
    };
}
