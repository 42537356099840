import axios from '../api';

const url = 'api/RiskConfigApi';

export const listRiskConfigsService = async () => {
  return axios
    .get(`${url}/ListRiskConfigs`)
    .then((response) =>
      response.data.map((x: any) => {
        return { key: x.id, ...x };
      }),
    )
    .catch((error) => {
      console.log('--------------- riskConfigsListService', error.message);
      return [];
    });
};

export const addRiskConfigService = async (
  valueFrom?: number,
  valueTo?: number,
  name?: string,
) => {
  return axios
    .put(`${url}/AddRiskConfig`, {
        valueFrom: valueFrom,
        valueTo:valueTo,
        name:name,
    })
    .then((response) => {
      return response
    }
    )
    .catch((error) => {
      console.log('--------------- addRiskConfigService', error.message);
      return [];
    });
};

export const updateRiskConfigService = async (
  valueFrom?: number,
  valueTo?: number,
  name?: string,
  id?: number
) => {
  return axios
    .patch(`${url}/UpdateRiskConfig`, {
        valueFrom : valueFrom,
        valueTo: valueTo,
        name: name,
        id: id
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log('--------------- updateRiskConfigService', error.message);
      return [];
    });
};

export const deleteRiskConfigService = async (
  id?: number,
) => {
  return axios
    .delete(`${url}/DeleteRiskConfig/${id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log('--------------- deleteRiskConfigService', error.message);
      return [];
    });
};

