import React from 'react';
import SearchElements from '../../../../_common/components/searchElements/SearchElements';
import { Button, Col, Row, Switch } from 'antd';
import { createAbsoluteUrl } from '../../../../_heplers/UrlCreatorHelpres';
import { Link } from 'react-router-dom';
import { MobilumPartnersContentProps } from '../interfaces/MobilumPartnersContentProps';
import { MobilumPartnersTable } from '../tables/MobilumPartnersTable';
import { navPath } from '../../../../_common/NavPath';
import { openErrorNotification, openSuccessNotification } from '../../../../_heplers/NotificationHelper';
import { PageTitleComponent } from '../../../../_common/components/page_title/PageTitleComponent';
import { PlusOutlined } from '@ant-design/icons';
import { setSiteActiveService, setSiteArchiveService } from '../../../../_services/mobilum_partners/MobilumPartnersService';

export class MobilumPartnersContent extends React.Component<MobilumPartnersContentProps> {
  _is_mounted = true;

  state = {
    available_sites: this.props.available_sites.map((x, index) => {
      x.key = index;
      return x;
    }),
    search: this.props.mobilum_partner_filters.search,
    showArchived: this.props.mobilum_partner_filters.showArchived,
  };

  componentWillUnmount = () => (this._is_mounted = false);

  public render() {
    const { available_sites, search, showArchived } = this.state;
    let filtered_data = available_sites;
    
    if (!showArchived) {
      filtered_data = available_sites?.filter((item: any) => !item.archived);
    }
     filtered_data = filtered_data?.filter((item: any) =>
      item.siteName?.toLowerCase().includes(search! || '')
    );

    return (
      <>
        <Col>
          <PageTitleComponent page_title="Mobilum Partners" />
        </Col>
        <Row justify="space-between" align="middle">
          <Col>
            <SearchElements
              placeholder="Search by name"
              onSearch={this.onGlobalSearch}
              value={search}
              showIcon
            />
          </Col>
          <Row>
            <Col
              style={{
                border: '1px solid #A3B0BF80',
                backgroundColor: '#FFFFFF',
                padding: 16,
                borderRadius: 8,
                height: 50,
                marginRight: 8,
              }}
            >
              <Row style={{ alignItems: 'center', marginTop: -4 }}>
                <Switch
                  checked={showArchived}
                  onChange={() => this.changeArchived()}
                />
                <div style={{ fontSize: 16, fontWeight: 500, marginLeft: 8 }}>
                  Show Archived Partners
                </div>
              </Row>
            </Col>
            <Col>
              <Link to={createAbsoluteUrl(`${navPath.mobilum_partners}/new`)}>
                <Button
                  className="common_add_new_button margin_bottom_20"
                  icon={<PlusOutlined />}
                  size="large"
                  type="primary"
                  style={{ backgroundColor: '#FBA338' }}
                >
                  Add New Partner
                </Button>
              </Link>
            </Col>
          </Row>
        </Row>
        <MobilumPartnersTable
          data={filtered_data}
          changeAccountStatus={this.changeAccountStatus}
          changeAccountArchived={this.changeAccountArchived}
        />
      </>
    );
  }

  changeArchived = () => {
    this.setState({ showArchived: !this.state.showArchived });
    this.props.setMobilumPartnerFilters({
      ...this.props.mobilum_partner_filters,
      showArchived: !this.state.showArchived});
  };

  onGlobalSearch = (event: any) => {
    this.setState({
      search: event.target.value.toLocaleLowerCase(),
    });
    this.props.setMobilumPartnerFilters({
      ...this.props.mobilum_partner_filters,
       search: event.target.value.toLocaleLowerCase()});
  };

  changeAccountStatus = async (available_site: any) => {
    available_site.disabled = !available_site.disabled;

    const available_sites = this.state.available_sites.map((x) => {
      if (x.siteUuid === available_site.siteUuid) {
        return available_site;
      } else {
        return x;
      }
    });

    await setSiteActiveService(available_site.siteUuid, available_site.disabled)
      .then((response) => {
        if (response) {
          this._is_mounted && this.setState({ available_sites });

          openSuccessNotification(
            `Site ${available_site.siteName} status changed`,
          );
        } else {
          openErrorNotification('Service temporary unavaible');
        }
      })
      .finally(() => this._is_mounted && this.setState({ loading: false }));
  };

  changeAccountArchived = async (available_site: any) => {
    available_site.archived = !available_site.archived;

    const available_sites = this.state.available_sites.map((x) => {
      if (x.siteUuid === available_site.siteUuid) {
        return available_site;
      } else {
        return x;
      }
    });

    await setSiteArchiveService(available_site.siteUuid, available_site.archived)
      .then((response) => {
        if (response) {
          this._is_mounted && this.setState({ available_sites });

          openSuccessNotification(
            `Site ${available_site.siteName} status changed`,
          );
        } else {
          openErrorNotification('Service temporary unavaible');
        }
      })
      .finally(() => this._is_mounted && this.setState({ loading: false }));
  };
}
