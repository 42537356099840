import React from 'react';
import SearchElements from '../../../../_common/components/searchElements/SearchElements';
import { ConfigurationBlockedCardsTable } from '../tables/ConfigurationBlockedCardsTable';
import { getBlockedCardsService, unblockBlockedCardsService } from '../../../../_services/blocked_cards/BlockedCardsServices';
import { IBlockedCard } from '../interfaces/IBlockedCard';
import { Input, Spin } from 'antd';
import { loading_icon } from '../../../../_common/components/loading/LoadingIcon';
import { openErrorNotification, openSuccessNotification } from '../../../../_heplers/NotificationHelper';
import { PageTitleComponent } from '../../../../_common/components/page_title/PageTitleComponent';

export class ConfigurationBlockedCardsContent extends React.Component {
    _is_mounted = true;

    state = {
        loading: true,
        search: undefined,
        blocked_cards: Array<IBlockedCard>()
    };

    componentDidMount = () => {
        this.getBlockedCards();
    }

    componentWillUnmount = () => {
        this._is_mounted = false;
    }

    public render() {
        const {
            loading,
            search,
            blocked_cards
        } = this.state;

        let filtred_blocked_cards: Array<IBlockedCard> = [];

        if (search) {
            let list: Array<IBlockedCard> = [];

            blocked_cards?.map(item => {
                if (
                    (item.userUuid.toLocaleLowerCase().includes(search!)) ||
                    (item.declineCode && item.declineCode.toString().toLocaleLowerCase().includes(search!)) ||
                    (item.userEmail?.toLocaleLowerCase().includes(search!))
                ) {
                    list = [...list, item];
                }
                else
                    return;
            });

            filtred_blocked_cards = list;
        }
        else {
            filtred_blocked_cards = blocked_cards
        }

        return (
            <>
                <PageTitleComponent page_title='Blocked Cards' />
                <SearchElements onSearch={this.onGlobalSearch} showIcon />
                <Spin className='spin_60' spinning={loading} indicator={loading_icon(40)}>
                    <ConfigurationBlockedCardsTable
                        data={filtred_blocked_cards}
                        unblockCard={this.unblockCard}
                    />
                </Spin>
            </>
        );
    }

    getBlockedCards = async () => {
        this.setState({ loading: true });

        await getBlockedCardsService()
            .then((response: any) => {
                let blocked_cards: Array<IBlockedCard> = [];

                blocked_cards = response.map((cart: IBlockedCard) => {
                    cart.txDate = new Date(cart.txDate).getTime();
                    cart.key = cart.id;

                    return cart;
                });

                this._is_mounted && this.setState({ blocked_cards });
            })
            .finally(() => this._is_mounted && this.setState({ loading: false }));
    }

    unblockCard = async (id: number, reason: string) => {
        await unblockBlockedCardsService(id, reason)
            .then((response: any) => {
                if (response) {
                    const blocked_cards = this.state.blocked_cards.filter((x: any) => x.id !== id);

                    this.setState({ blocked_cards });

                    openSuccessNotification('Card unlocked');
                }
                else {
                    openErrorNotification('Error')
                }
            });
    }

    onGlobalSearch = (event: any) => {
        this.setState({ [event.target.name]: event.target.value.toLocaleLowerCase() });
    }
}