import React from 'react';
import styles from '../styles/ConfigurationBlockedTerritories.module.scss';
import { AddNewButtonComponent } from '../../../../_common/components/add_new_button/AddNewButtonComponent';
import { ClearBoth } from '../../../../_common/components/clearBoth/ClearBoth';
import { ConfigurationBlockedTerritoriesTable } from '../tables/ConfigurationBlockedTerritoriesTable';
import { ConfigurationTerritoryForm } from '../forms/ConfigurationTerritoryForm';
import { deleteBlockedRegionsServices, getBlockedRegionsServices } from '../../../../_services/blocked_regions/BlockedRegionsServices';
import { IBlockedTerritory } from '../interfaces/IBlockerTerritory';
import { IConfigurationBlockedTerritoriesContentProps } from '../interfaces/IConfigurationBlockedTerritoriesContentProps';
import { Left } from '../../../../_common/components/left/Left';
import { loading_icon } from '../../../../_common/components/loading/LoadingIcon';
import { Modal, Row, Spin } from 'antd';
import { openSuccessNotification } from '../../../../_heplers/NotificationHelper';
import { PageTitleComponent } from '../../../../_common/components/page_title/PageTitleComponent';
import { Right } from '../../../../_common/components/right/Right';

export class ConfigurationBlockedTerritoriesContent extends React.Component<IConfigurationBlockedTerritoriesContentProps> {
    _is_mounted = true;

    state = {
        loading: true,
        blocked_territories: Array<IBlockedTerritory>(),
        // Modal
        show_modal: false
    };

    componentDidMount = () => {
        this.getBlockedTerritories();
    }

    componentWillUnmount = () => {
        this._is_mounted = false;
    }

    public render() {
        const {
            loading,
            blocked_territories,
            // Modal
            show_modal
        } = this.state;

        const {
            dictionaries,
            loading_dictionaries,
            available_sites
        } = this.props;

        return (
            <>
                <Row align='middle' justify='space-between'>
                    <Left>
                        <PageTitleComponent page_title='Blocked Territories' />
                    </Left>
                    <Right>
                        <AddNewButtonComponent
                            title="Add New Territory"
                            onClick={() => this.setModalVisible(true)}
                        />
                    </Right>
                </Row>
                <ClearBoth />
                <Spin className='spin_60' spinning={loading} indicator={loading_icon(40)}>
                    <ConfigurationBlockedTerritoriesTable
                        data={blocked_territories}
                        dictionaries={dictionaries}
                        available_sites={available_sites}
                        loading_dictionaries={loading_dictionaries}
                        unblockTerritory={this.unblockTerritory}
                    />
                </Spin>
                <Modal
                    title={undefined}
                    visible={show_modal}
                    closable={false}
                    footer={false}
                    width={500}
                    destroyOnClose
                >
                    <div className={styles.modal_content}>
                        <div className={styles._modal_title}>
                            Add New Territory
                        </div>
                        <ConfigurationTerritoryForm
                            dictionaries={dictionaries}
                            available_sites={available_sites}
                            loading_dictionaries={loading_dictionaries}
                            setModalVisible={this.setModalVisible}
                            addObjectToArray={this.addNewTerritory}
                        />
                    </div>
                </Modal>
            </>
        );
    }

    setModalVisible = (show_modal: boolean) => {
        this.setState({ show_modal });
    }

    getBlockedTerritories = async () => {
        this.setState({ loading: true });

        await getBlockedRegionsServices()
            .then(response => {
                const blocked_territories = response.map((x: any) => {
                    return {
                        key: x.id,
                        created_at: x.created_at && new Date(x.created_at).getTime(),
                        ...x
                    }
                });

                this._is_mounted && this.setState({ blocked_territories });
            })
            .finally(() => this._is_mounted && this.setState({ loading: false }));
    }

    unblockTerritory = async (id: number) => {
        await deleteBlockedRegionsServices(id)
            .then(response => {
                if (response) {
                    const blocked_territories = this.state.blocked_territories.filter((x: any) => x.id !== id);

                    this.setState({ blocked_territories });

                    openSuccessNotification('Unblocked');
                }
            });
    }

    addNewTerritory = (blocked_territory: any) => {
        const blocked_territories = [blocked_territory, ...this.state.blocked_territories];

        this.setState({
            blocked_territories,
            show_modal: false
        });
    }
}
