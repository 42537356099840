import axios from '../api';

const url = 'api/SiteApi';

export const getFraudRules = (siteUuid: string) =>
	axios
		.get(`${url}/GetSiteFraudRules/${siteUuid}`)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});
export const getFraudRulesByLimitType = async (
	siteUuid: string,
	limitType: number
) => {
	try {
		const response = await axios.get(
			`${url}/GetSiteFraudRulesByLimitType/${siteUuid}?limitType=${limitType}`
		);
		return response.data;
	} catch (error) {
		return error;
	}
};
export const toggleFraudRule = (siteUUid: string, limitType: number) =>
	axios
		.put(`${url}/ToggleFraudRuleActivation/${siteUUid}?limitType=${limitType}`)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});

export const updateFraudRule = async (payload: any) => {
	try {
		const response = await axios.put(`${url}/UpdateFraudRule`, payload);
		return response;
	} catch (error) {
		return error;
	}
};

export const getFraudRulesBlockedUsers = async (params?: any) => {
	try {
		const response = await axios.get(`api/ClientApi/GetFraudRulesBlockedUsers`, {
      params: {
        ...params
      },
    });
		return response.data;
	} catch (error) {
		return error;
	}
};
