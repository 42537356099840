import { _hide_when_less_than, _page_size_options } from '../../../../_heplers/GlobalValues';
import { Button, Table, Tooltip } from 'antd';
import { convertNumberToDateTimeString } from '../../../../_heplers/DateHelpers';
import { findInDictionaryAndReturnValueWithSpin } from '../../../../_heplers/DictionariesHelper';
import { IBlockedTerritory } from '../interfaces/IBlockerTerritory';
import { IConfigurationBlockedTerritoriesTableProps } from '../interfaces/IConfigurationBlockedTerritoriesTableProps';
import { useState } from 'react';
import {
    QuestionCircleOutlined
} from '@ant-design/icons';

export const ConfigurationBlockedTerritoriesTable = (props: IConfigurationBlockedTerritoriesTableProps) => {
    const [pageSize, setPageSize] = useState(10);

    const {
        data,
        dictionaries,
        loading_dictionaries,
        available_sites,
        unblockTerritory,
    } = props;

    const columns: any[] = [
        {
            title: 'Country',
            dataIndex: 'country',
            key: 'country',
            ellipsis: true
        },
        {
            title: 'Region',
            dataIndex: 'region',
            key: 'region',
            ellipsis: true,
            onHeaderCell: () =>
            ({
                hasTooltip: true,
                tooltipTitle: 'Empty region affects whole country'
            } as React.HTMLAttributes<HTMLElement>)
        },
        {
            title: 'Site',
            key: 'siteUuid',
            render: (record: IBlockedTerritory) => (
                record.siteUuid && findInDictionaryAndReturnValueWithSpin(record.siteUuid, false, available_sites)
            )
        },
        {
            title: 'Access Mode',
            key: 'accessMode',
            render: (record: IBlockedTerritory) => (
                findInDictionaryAndReturnValueWithSpin(record.accessMode, loading_dictionaries, dictionaries.access_mode)
            )
        },
        {
            title: 'Date Blocked',
            key: 'created_at',
            width: 200,
            render: (record: IBlockedTerritory) => (
                convertNumberToDateTimeString({ number: record.created_at, request_date_format: 'DD MMM YY' })
            )
        },
        {
            title: 'Action',
            key: 'actions',
            render: (record: IBlockedTerritory) => (
                <Button
                    type='primary'
                    className='common_unlock_button'
                    onClick={() => unblockTerritory(Number(record.id))}
                >
                    Unblock
                </Button>
            )
        }
    ];
    // components is used to Add tooltip to the header cell. on Column (eg: Region column) add hasTooltip true and tooltipTitle to onHeaderCell
    const components = ({
        header: {
            cell: ({ hasTooltip, ...restProps }: any) => {
                if (hasTooltip) {
                    return <th {...restProps}>
                        {restProps.title}
                        <Tooltip placement={restProps.placement} arrowPointAtCenter title={restProps.tooltipTitle}> <QuestionCircleOutlined style={{ color: '#6D6D6D' }} />
                        </Tooltip>
                    </th>;
                } else return <th {...restProps}/>
            }
        }
      })

    return (
        <Table
            bordered
            size='small'
            columns={columns}
            dataSource={data}
            components={components}
            pagination={{
                pageSize: pageSize,
                onChange: (e: number, b: number) => setPageSize(b),
                showSizeChanger: true,
                pageSizeOptions: _page_size_options,
                hideOnSinglePage: data.length < _hide_when_less_than
            }}
        />
    )
}
