import * as React from 'react';

function MenuBlockedTerritories(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.5 23C17.299 23 22 18.299 22 12.5S17.299 2 11.5 2 1 6.701 1 12.5 5.701 23 11.5 23zM6.652 9.98l-.036-.016a5.526 5.526 0 00.001 5.072l.035-.016a11.74 11.74 0 012.49-.783 8.4 8.4 0 01-.002-3.47c-.854-.171-1.687-.43-2.488-.787zm6.35 4.822a7.642 7.642 0 01-1.501 2.694A7.633 7.633 0 0110 14.803a11.451 11.451 0 013.002 0zM11.5 11.005c-.568 0-1.126-.053-1.679-.13a7.692 7.692 0 00.002 3.258 12.19 12.19 0 013.355 0 7.703 7.703 0 00.002-3.259c-.553.077-1.111.131-1.68.131zm0-3.5a7.639 7.639 0 011.502 2.694c-.997.132-2.007.132-3.002 0a7.646 7.646 0 011.5-2.694zM9.31 10.09A8.339 8.339 0 0111.026 7a5.488 5.488 0 00-4.052 2.365c.75.33 1.535.57 2.336.726zm6.716-.726c-.75.33-1.535.57-2.336.726A8.337 8.337 0 0011.974 7a5.49 5.49 0 014.052 2.365zm.32.615a11.79 11.79 0 01-2.486.787 8.39 8.39 0 01-.001 3.471c.854.17 1.69.426 2.488.782l.036.016a5.51 5.51 0 000-5.071l-.036.015zm-2.656 4.93c.802.156 1.587.395 2.336.725A5.49 5.49 0 0111.974 18a8.34 8.34 0 001.716-3.09zM11.026 18a8.329 8.329 0 01-1.716-3.091 11.02 11.02 0 00-2.336.726A5.488 5.488 0 0011.026 18z"
                fill="#A3B0BF"
            />
        </svg>
    );
}

const MemoMenuBlockedTerritories = React.memo(MenuBlockedTerritories);
export default MemoMenuBlockedTerritories;
