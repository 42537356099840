import Column from 'antd/lib/table/Column';
import InputForm from '../../../_common/components/forms/InputForm';
import styles from '../../notifications/styles/Notifications.module.scss';
import { _default_page_size, _hide_when_less_than, _page_size_options } from '../../../_heplers/GlobalValues';
import {
  addRiskConfigService,
  deleteRiskConfigService,
  listRiskConfigsService,
  updateRiskConfigService
  } from '../../../_services/user_risk/UserRiskService';
import {
  Button,
  Col,
  Form,
  FormInstance,
  InputNumber,
  Modal,
  Row,
  Spin,
  Table
  } from 'antd';
import { createRef, useEffect, useState } from 'react';
import { loading_icon } from '../../../_common/components/loading/LoadingIcon';
import { PageTitleComponent } from '../../../_common/components/page_title/PageTitleComponent';
import { PlusOutlined } from '@ant-design/icons';
import { validateMessages } from '../../../_common/ValidationCominications';

export const UserRiskTable = () => {
  const [list, setList] = useState([]);
  const [showModal, setModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [add, setAdd] = useState(false);
  const [id, setId] = useState<number>();
  const [modal_data, setModalData] = useState({});
  const form_ref = createRef<FormInstance>();
  const [pageSize, setPageSize] = useState(_default_page_size);

  //#region ---- Styles ----

  const common_link_style = { color: '#A3B0BF' };

  const action_button_style = {
    width: 85,
    borderRadius: 8,
    marginBottom: 5,
    marginRight: 10
  };

  //#endregion ---- Styles ----

  useEffect(() => {
    getList();
  }, []);

  const getList = async () => setList(await listRiskConfigsService().finally(() => setLoading(false)));

  const editButton = (id: number) => {
    setId(id);
    setAdd(false);
    setModal(true);
  };

  const addButton = () => {
    setAdd(true);
    setModal(true);
  };

  const deleteButton = (id: number) => {
    setId(id);
    deleteRiskConfigService(id);
    setTimeout(() => {
      getList();
    }, 1000);
  };

  const onSubmit = async (values: any) => {
    setModal(false);

    if (!add) {
      updateRiskConfigService(values.valueFrom, values.valueTo, values.name, id);
    }
    else {
      addRiskConfigService(values.valueFrom, values.valueTo, values.name);
    }

    setModalData([]);

    setTimeout(() => {
      getList();
    }, 1000);
  };

  return (
    <>
      <Row justify="space-between" align="middle">
        <Col>
          <PageTitleComponent page_title="User Risk Level" />
        </Col>
        <Col>
          <Button
            className="common_add_new_button margin_bottom_20"
            icon={<PlusOutlined />}
            size="large"
            type="primary"
            style={{ backgroundColor: '#FBA338' }}
            onClick={() => addButton()}
          >
            Add New
          </Button>
        </Col>
      </Row>
      <Spin className="spin_60" spinning={loading} indicator={loading_icon(40)}>
        <Table
          scroll={{ y: 600 }}
          dataSource={list}
          bordered
          size="middle"
          className="transaction-table"
          style={{ marginTop: 20, borderRadius: 20 }}
          pagination={{
            pageSize: pageSize,
            onChange: (e: number, b: number) => setPageSize(b),
            showSizeChanger: true,
            pageSizeOptions: _page_size_options,
            hideOnSinglePage: list.length < _hide_when_less_than
          }}
        >
          <Column
            title="From"
            dataIndex="valueFrom"
            key="valueFrom"
            sorter={{
              compare: (a: any, b: any) =>
                a.valueFrom.localeCompare(b.valueFrom),
            }}
            width={200}
            render={(text, record: any) => (
              <a style={common_link_style}>{record.valueFrom}</a>
            )}
          />
          <Column
            title="To"
            dataIndex="valueTo"
            key="valueTo"
            sorter={{
              compare: (a: any, b: any) => a.valueTo.localeCompare(b.valueTo),
            }}
            width={200}
            render={(text, record: any) => (
              <a style={common_link_style}>{record.valueTo}</a>
            )}
          />
          <Column
            title="Name"
            dataIndex="name"
            width={300}
            key="name"
            sorter={{
              compare: (a: any, b: any) => a.name.localeCompare(b.name),
            }}
            render={(text, record: any) => (
              <a style={common_link_style}>{record.name}</a>
            )}
          />
          <Column
            title="Actions"
            key="id"
            width={230}
            render={(record: any) => (
              <>
                <Button
                  type="primary"
                  style={action_button_style}
                  onClick={() => {
                    editButton(record.id);
                    setModalData(record);
                  }}
                >
                  Edit
                </Button>
                <Button
                  type="primary"
                  style={action_button_style}
                  onClick={() => deleteButton(record.id)}
                >
                  Delete
                </Button>
              </>
            )}
          />
        </Table>
      </Spin>
      <Modal
        title={undefined}
        visible={showModal}
        closable={false}
        footer={false}
        width={400}
        destroyOnClose
      >
        <div className={styles.modal_content}>
          <div className={styles._modal_title} style={{ marginBottom: '30px' }}>
            {'Add new risk config'}
          </div>
          <Form
            layout="vertical"
            name="control-ref"
            onFinish={onSubmit}
            ref={form_ref}
            validateMessages={validateMessages()}
            className="modal_content"
            initialValues={{ ...modal_data }}
          >
            <Col>
              <Form.Item
                label='Value from (incl.)'
                name="valueFrom"
                rules={[{ required: true }]}
              >
                <InputNumber
                  min={0}
                  max={100}
                  placeholder="from"
                  className='width_100_percent modal_input_number'
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                label='Value to (excl.)'
                name="valueTo"
                rules={[{ required: true }]}
              >
                <InputNumber
                  min={0}
                  max={100}
                  placeholder="to"
                  className='width_100_percent modal_input_number'
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                label='Name'
                name="name"
                rules={[{ required: true }]}
              >
                <InputForm placeholder="name" />
              </Form.Item>
            </Col>
            <div className="grid_col_2">
              <Button
                className="common_cancel_button common_previous_button"
                onClick={() => {
                  setModal(false);
                  setModalData({});
                }}
              >
                Cancel
              </Button>
              <Form.Item shouldUpdate={true}>
                {() => (
                  <Button
                    className="common_submit_button"
                    htmlType="submit"
                    type="primary"
                  >
                    Submit
                  </Button>
                )}
              </Form.Item>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
};
