import { AppContext } from '../../../_common/app_context_provider/AppContextProvider';
import { ConfigurationBlockedTerritoriesContent } from './content/ConfigurationBlockedTerritoriesContent';
import { useContext } from 'react';

const ConfigurationBlockedTerritoriesView = () => {
    const context = useContext(AppContext);

    return <ConfigurationBlockedTerritoriesContent
        loading_dictionaries={context.loading_dictionaries}
        dictionaries={context.dictionaries}
        available_sites={context.app_user_inf.availableSites.map((x: any) => { return { value: x.siteUuid, name: x.siteName } })}
    />
}

export default ConfigurationBlockedTerritoriesView;
