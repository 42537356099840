import Card_icon_1 from '../../../images/Card_icon_1.png';
import Card_icon_3 from '../../../images/Card_icon_3.png';
import Card_icon_5 from '../../../images/Card_icon_5.png';
import moment from 'moment';
import React from 'react';
import SelectOnChange from '../../../_common/components/form_components/SelectOnChange';
import styles from '../styles/HomeStyles.module.scss';
import { BarChart } from '../components/BarChart';
import { Button, DatePicker, Image } from 'antd';
import { ClearBoth } from '../../../_common/components/clearBoth/ClearBoth';
import { getAnaliticsService, IAnaliticsData } from '../../../_services/analytics/AnalyticsServices';
import { IAnalitics } from '../interfaces/IAnalitics';
import { IHomeContentProps } from '../interfaces/IHomeContentProps';
import { PageTitleComponent } from '../../../_common/components/page_title/PageTitleComponent';
import { SpinComponent } from '../../../_common/components/spin/SpinComponent';

export class HomeContent extends React.Component<IHomeContentProps> {
    _is_mounted = true;

    state = {
        loading: false,
        // Filters
        disable_applay_filters: true,
        beginDate: moment().add(-12, 'months'),
        endDate: moment(),
        country: undefined,
        currency: undefined,
        sites: undefined,
        // Crypto filters
        selected_crypto: undefined,
        // Analitics
        analitics: {
            barChart: {
                sales: [],
                commission: [],
                caption: []
            },
            pieChart: [],
            summary: {
                commission: 0,
                conversionRate: 0,
                cryptoCount: 0,
                giftCardCount: 0,
                kycDropOff: 0,
                purchases: 0,
                sales: 0,
                transactions: 0,
                userSignUp: 0
            }
        } as IAnalitics
    }

    componentDidMount = () => {
        this.getStatistics();
    }

    componentWillUnmount = () => {
        this._is_mounted = false;
    }

    render(): React.ReactNode {
        const {
            loading,
            analitics,
            disable_applay_filters,
            beginDate,
            endDate,
        } = this.state;

        const {
            dashboard_filter_countries,
            dashboard_filter_currency,
            sites
        } = this.props;

        const displayFormatedValue = (value?: string | number | null) => value ? value.toLocaleString() : '-';
        const displayValue = (value?: string | number | null) => value ?  value.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 2}) : '-';


        return (
            <>
                <PageTitleComponent
                    page_title='Account Summary'
                />
                <DatePicker
                    name='beginDate'
                    value={beginDate}
                    placeholder='Start Date'
                    className='common_date_picker margin_rigth'
                    onChange={this.onChangeDateStartAt}
                />
                <DatePicker
                    name='endDate'
                    value={endDate}
                    placeholder='End Date'
                    className='common_date_picker margin_rigth'
                    onChange={this.onChangeDateEndAt}
                />
                <SelectOnChange
                    allowClear
                    name='country'
                    placeholder='Country'
                    className='margin_rigth'
                    items={dashboard_filter_countries.map(x => { return { name: x.name, value: x.value, id: x.value } })}
                    onChange={this.onChangeSelect}
                />
                <SelectOnChange
                    allowClear
                    className='margin_rigth'
                    placeholder='Currency'
                    name='currency'
                    items={dashboard_filter_currency.map(x => { return { name: x.name, value: x.value, id: x.value } })}
                    onChange={this.onChangeSelect}
                />
                <SelectOnChange
                    allowClear
                    className='margin_rigth'
                    placeholder='Partners'
                    name='sites'
                    items={sites.map(x => { return { name: x.name, value: x.value, id: x.value } })}
                    onChange={this.onChangeSelect}
                />
                <Button
                    type='primary'
                    className='common_apply_filters_button'
                    disabled={disable_applay_filters}
                    onClick={() => this.getStatistics()}
                >
                    Apply Filter
                </Button>
                <div className={styles.statistics_container}>
                    <div className={styles._total_sales}>
                        <Image
                            preview={false}
                            src={Card_icon_1}
                        />
                        <div className={styles.__title}>
                            Total Sales
                        </div>
                        <div className={styles.__value}>
                            {loading ? <SpinComponent height='auto' icon_sise={25} /> : displayValue(analitics?.summary?.sales)}
                        </div>
                    </div>
                    <div className={styles._no_of_transactions}>
                        <Image
                            preview={false}
                            src={Card_icon_3}
                        />
                        <div className={styles.__title}>
                            No. of Transactions
                        </div>
                        <div className={styles.__value}>
                            {loading ? <SpinComponent height='auto' icon_sise={25} /> : displayFormatedValue(analitics?.summary?.transactions)}
                        </div>
                    </div>
                    <div className={styles._new_user_sign_up}>
                        <Image
                            preview={false}
                            src={Card_icon_5}
                        />
                        <div className={styles.__title}>
                             No. of Registered Users
                        </div>
                        <div className={styles.__value}>
                            {loading ? <SpinComponent height='auto' icon_sise={25} /> : displayFormatedValue(analitics?.summary?.userSignUp)}
                        </div>
                    </div>
                </div>
                <ClearBoth />
                <div className={styles.charts_containter}>
                        <div className={styles.__title}>
                            12 Month Sales history
                        </div>
                        {loading ? <SpinComponent height={330} /> : <BarChart data={analitics.barChart} />}
                </div>
            </>
        )
    }

    getStatistics = async () => {
        this.setState({
            loading: true,
            disable_applay_filters: true
        });

        const {
            beginDate,
            endDate,
            sites,
            country,
            currency,
        }: any = this.state;

        const params: IAnaliticsData = {
            beginDate: beginDate ? beginDate.toISOString().split('T')[0] : undefined,
            endDate: endDate ? endDate.toISOString().split('T')[0] : undefined,
            country: country ? country : undefined,
            sites: sites ? [sites] : undefined,
            currency: currency
        }

        await getAnaliticsService(params)
            .then(response => {
                this._is_mounted && this.setState({
                    analitics: {
                        barChart: response.barChart,
                        pieChart: response.pieChart,
                        summary: response.summary
                    }
                });
            })
            .finally(() => this._is_mounted && this.setState({ loading: false }));
    }

    onChangeSelect = (name: string, value: any): void => {
        this.setState({
            [name]: value,
            disable_applay_filters: false
        });
    };

    onChangeSelectedCrypto = (name: string, value: any): void => {
        this.setState({ [name]: value });
    };

    onChangeDateStartAt = (date: any, dateString: any) => {
        this.setState({
            beginDate: date,
            disable_applay_filters: false
        });
    }

    onChangeDateEndAt = (date: any, dateString: any) => {
        this.setState({
            endDate: date,
            disable_applay_filters: false
        });
    }
}
