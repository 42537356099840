import axios from '../api';

const url = 'api/KYCUserRequestApi';

export const removeKyc = (userId: string) =>
  axios
    .delete(`${url}/RemoveByUserId`, { params: { userId } })
    .then((response) => response)
    .catch((error) => {
      console.log('--------------- removeKycAttemptsServices', error.message);

      return [];
    });
