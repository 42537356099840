import moment from 'moment';
import styles from '../../notifications/styles/Notifications.module.scss';
import { AppContext, ITransactionFilters } from '../../../_common/app_context_provider/AppContextProvider';
import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Select
  } from 'antd';
import { CSVLink } from 'react-csv';
import { DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import { useContext, useEffect, useState } from 'react';
import { formatOrderStatus } from '../../../_heplers/OrderStatusHelper';

const { Option } = Select;

export interface FilterProps {
  id?: string;
  transactionType: string; // to be the same as the filters object in the AppContextProvider for setTransactionFilters method
  list: any[];
  exportCsvData?: () => void;
}
export const Filters = (props: FilterProps) => {
  //#region ---- Styles ----

  const data_picker_styles = {
    borderRadius: 8,
    width: 138,
    height: 52,
    marginBottom: 7,
    marginRight: 11,
  };

  const custom_filters = {
    borderRadius: 8,
    backgroundColor: 'white',
    minWidth: 260,
    width: 'auto',
    marginBottom: 7,
    marginRight: 11,
  };


  const filters_styles = {
    borderRadius: 8,
    backgroundColor: 'white',
    minWidth: 180,
    width: 'auto',
    marginBottom: 7,
    marginRight: 11,
  };

  const select_styles = {
    borderRadius: 8,
    backgroundColor: 'white',
    minWidth: 138,
    width: 'auto',
    marginBottom: 7,
    marginRight: 11,
  };

  const export_svg_button_styles = {
    width: 184,
    height: 52,
    borderRadius: 8,
    backgroundColor: '#FBA338',
    color: 'white',
    marginBottom: 7,
    marginRight: 11,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  };

  const apply_filters_button = {
    width: 184,
    height: 52,
    borderRadius: 8,
    marginBottom: 7,
    marginRight: 11
  };

  const button_style = {
    fontSize: 16,
    fontWeight: 700
  };

  //#endregion ---- Styles ----

  const context = useContext(AppContext);
  const [showModal, setModal] = useState(false);
  const [startDate, changeStart] = useState(context[props.transactionType].firstDate);
  const [endDate, changeEnd] = useState(context[props.transactionType].lastDate);
  const [sites, changeSite] = useState(context[props.transactionType].sites);
  const [status, changeStatus] = useState(context[props.transactionType].status);
  const [wireType, changeWireType] = useState(context[props.transactionType].wireType);
  const [search, changeSearch] = useState(context[props.transactionType].search);
  const [disable_apply, setApply] = useState(false);
  const [siteUuid, changeSiteUuid] = useState(context[props.transactionType].siteUuid);
  const [isSiteFilterDirty, setSiteFilterDirty] = useState(context.wired_filters.isSiteFilterDirty);
  const available_sites = context.app_user_inf ? context.app_user_inf.availableSites.filter((item: any) => !item.archived).map((x: any) => { return { value: x.siteUuid, key: x.siteUuid, label: x.siteName } }) : [];
  const [csvHeaders, setCsvHeaders] = useState([
    { label: 'Transaction ID', key: props.id || 'id' },
    { label: 'Site Name', key: 'siteName' },
    { label: 'Transaction Date', key: 'txDate' },
    { label: 'Name', key: 'name' },
    { label: 'Email', key: 'email' },
    { label: 'Amount', key: 'orderAmount' },
    { label: 'Fiat Currency', key: 'currency' }
  ]);

  useEffect(() => {
    setExport();
  }, [context[props.transactionType]]);

  const setExport = async () => {

    if (props.transactionType === 'completed') {
      setCsvHeaders([
        { label: 'Site Name', key: 'site' },
        { label: 'Client Type', key: 'fiatOnlyMode' },
        { label: 'Mobilum Order Timestamp', key: 'mobilumOrderTimestamp' },
        { label: 'Mobilum Order UUID', key: 'orderUUID' },
        { label: 'CC Transaction Timestamp', key: 'createdDate' },
        { label: 'CC Transaction UUID', key: props.id || 'id' },
        { label: 'Mobilum Order Status', key: 'orderStatus' },
        // cc tx status prop?
        { label: 'CC Transaction Status', key: 'statusFenige' },
        { label: 'Crypto Delivery Status', key: 'udtState' },
        // crypto tx hash props?
        { label: 'Crypto Transaction Hash', key: 'txHash' },
        { label: 'Total TXN Value', key: 'price' },
        { label: 'Fiat Currency', key: 'currency' },
        { label: 'Crypto Amount', key: 'amount' },
        { label: 'Crypto Currency', key: 'cryptoCurrency' },
        // crypto price props?
        { label: 'Crypto Price', key: '' },
        { label: 'Crypto Price - Currency', key: '' },
        { label: 'Network Fee to End User', key: '' },
        { label: 'Network Fee - Currency', key: '' },
        { label: 'Processing Fee - %', key: '' },
        { label: 'Processing Fee - Value', key: '' },
        { label: 'Processing Fee - Currency', key: '' },
        // up values
        { label: 'User UUID', key: 'userId' },
        { label: 'User Wallet Address', key: 'userWallet' },
        { label: 'Card number', key: 'cardIdentificationNumber' },
        { label: 'First Name', key: 'name' },
        { label: 'Last Name', key: 'surname' },
        { label: 'Email', key: 'email' },
      ])
    } else if (props.transactionType === 'wired_filters') {
      setCsvHeaders([
        { label: 'Settlement Date', key: 'settlementDate' },
        { label: 'Transaction UUID', key: 'transactionUUID' },
        { label: 'Transaction', key: 'transactionType' },
        { label: 'Status', key: 'status' },
        { label: 'User UUID', key: 'userUUID' },
        { label: 'From Account', key: 'fromAccount' },
        { label: 'To Account', key: 'toAccount' },
        { label: 'Amount', key: 'amount' },
        { label: 'Currency', key: 'currency' },
        { label: 'Date Created', key: 'dateCreated' },
        { label: 'Account Holder', key: 'accountHolder' },
        { label: 'Beneficiary Type', key: 'beneficiaryType' }
      ]);
    }
    else {
      setCsvHeaders([
        { label: 'Site Name', key: 'txSiteName' },
        { label: 'Client Type', key: 'fiatOnlyMode' },
        { label: 'Mobilum Order Timestamp', key: 'mobilumOrderTimestamp' },
        { label: 'Mobilum Order UUID', key: 'orderId' },
        { label: 'CC Transaction Timestamp', key: 'txDate' },
        { label: 'CC Transaction UUID', key: props.id || 'id' },
        { label: 'Mobilum Order Status', key: 'orderStatus' },
        { label: 'CC Transaction Status', key: 'statusFenige' },
        { label: 'Crypto Delivery Status', key: 'udtState' },
        // crypto tx hash props?
        { label: 'Crypto Transaction Hash', key: 'txHash' },
        { label: 'Total TXN Value', key: 'price' },
        { label: 'Fiat Currency', key: 'currency' },
        { label: 'Crypto Amount', key: 'orderAmount' },
        { label: 'Crypto Currency', key: 'cryptoCurrency' },
        // crypto price props?
        { label: 'Crypto Price', key: '' },
        { label: 'Crypto Price - Currency', key: '' },
        { label: 'Network Fee to End User', key: '' },
        { label: 'Network Fee - Currency', key: '' },
        { label: 'Processing Fee - %', key: '' },
        { label: 'Processing Fee - Value', key: '' },
        { label: 'Processing Fee - Currency', key: '' },
        // up values
        { label: 'User UUID', key: 'userId' },
        { label: 'User Wallet Address', key: 'userWallet' },
        { label: 'Card number', key: 'cardIdentificationNumber' },
        { label: 'First Name', key: 'name' },
        { label: 'Last Name', key: 'surname' },
        { label: 'Email', key: 'email' },
      ])
    }
  }
  const formattedList = props.list.map((item: any) => {
    return {
      ...item, 
      'settlementDate': moment(item.settlementDate).format('MM/DD/YYYY'),
      'dateCreated': moment(item.dateCreated).format('MM/DD/YYYY'),
      'fiatOnlyMode' : item.fiatOnlyMode ? 'Fiat Only' : 'Fiat & Liquidity',
      'statusFenige': item.statusFenige || '-',
      'orderStatus': item.orderStatus ? formatOrderStatus(item.orderStatus) : '-',
    }
  })
  const csvReport = {
    data: formattedList,
    headers: csvHeaders,
    filename: 'Report.csv'
  };

  function onStartDate(date: any) {
    // @ts-ignore
    changeStart(date ? moment(date) : null);
    setApply(true);
  }

  function onEndDate(date: any) {
    // @ts-ignore
    changeEnd(date ? moment(date) : null);
    setApply(true);
  }
  const handleSite = (value: any) => {
    changeSite(value);
    setApply(true);
  }

  const handleSiteUuid = (value: any) => {
    changeSiteUuid(value);
    setSiteFilterDirty(true);
    setApply(true);
  }

  const handleStatus = (value: any) => {
    changeStatus(value);
    setApply(true);
  }

  const onSearch = (value: any) => {
    changeSearch(value.target.value);
    setApply(true);
  }

  const applyFilters = (setTransactionFilters: (type: string, transaction_filters: ITransactionFilters) => void) => {
    setApply(false);
    let firstDate = startDate;
    let lastDate = endDate;
    const sitesArray = typeof sites === 'string' ? sites.split(' ') : sites?.length > 0 ? sites.toString().split(' ') : [];
    setTransactionFilters( props.transactionType, {
      firstDate,
      lastDate,
      country: '',
      currency: '',
      sites: sitesArray,
      search,
      wireType,
      status,
      siteUuid,
      isSiteFilterDirty
    });
  };

  const exportCSV = () => {
    setModal(false);
    if(props.transactionType !== 'wired_filters') {
      props.exportCsvData?.();
    }
  }

  const prefix = (
    <SearchOutlined
      style={{
        fontSize: 23,
        color: '#A3B0BF'
      }}
    />
  );

  return (
    <div className="flex" style={{ flexWrap: 'wrap' }}>
      <DatePicker
        onChange={onStartDate}
        value={startDate}
        placeholder="Start Date"
        style={data_picker_styles}
        format={'DD/MM/YYYY'}
        allowClear={false}
      />
      <DatePicker
        onChange={onEndDate}
        value={endDate}
        placeholder="End Date"
        format={'DD/MM/YYYY'}
        style={data_picker_styles}
        allowClear={false}
      />
      {props.transactionType !== 'wired_filters' && (
      <Select
        placeholder={status || 'Status'}
        onChange={handleStatus}
        value={status}
        className="filters"
        allowClear
        style={filters_styles}
        bordered={false}
        loading={context.loading_dictionaries}
      >
        {context.dictionaries &&
        context.dictionaries.order_statuses?.map((x: any) => (
          <Option key={x.name} value={x.name}>
            {formatOrderStatus(x.name)}
          </Option>
        ))}
      </Select>
      )}
      {props.transactionType !== 'wired_filters' && (
        <Select
          allowClear
          value={sites}
          bordered={false}
          className="filters custom_filters"
          style={custom_filters}
          onChange={handleSite}
          options={available_sites}
          optionFilterProp='children'
          placeholder='Select a Partner'
          maxTagCount={'responsive' as const}
          showSearch
          filterOption={(input, option: any) => {
            if (option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0) {
              return option;
            }
          }}
        />
      )}
      {props.transactionType === 'wired_filters' && (
        <Select
          placeholder={wireType || 'Transaction Type'}
          onChange={(e) => {
            changeWireType(e);
            setApply(true);
          }}
          value={wireType}
          className="filters"
          allowClear
          style={select_styles}
          bordered={false}
          showSearch
          filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {context.dictionaries &&
            context.dictionaries.wire_transfer_transaction_type?.map(
              (x: any) => (
                <Option key={x.key} value={x.name}>
                  {x.name}
                </Option>
              ),
            )}
        </Select>
      )}
      {props.transactionType === 'wired_filters' && available_sites.length > 1 && (
      <Select
        allowClear
        value={siteUuid}
        defaultValue={siteUuid}
        bordered={false}
        className="filters custom_filters"
        style={custom_filters}
        onChange={handleSiteUuid}
        options={available_sites}
        optionFilterProp='children'
        placeholder='Partners'
        maxTagCount={'responsive' as const}
        showSearch
        filterOption={(input, option: any) => {
          if (option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0) {
            return option;
          }
        }}
      />
      )}
      <Button
        type="primary"
        style={apply_filters_button}
        disabled={!disable_apply}
        onClick={() => applyFilters(context.setTransactionFilters)}
      >
        <div style={button_style}>Apply Filter</div>
      </Button>
      <Button
        style={export_svg_button_styles}
        icon={<DownloadOutlined />}
        size="large"
        onClick={() => setModal(true)}
      >
        <div style={button_style}>
          <span style={{ paddingLeft: 5 }}>Export CSV</span>
        </div>
      </Button>
      <Input
        placeholder={
          search || props.transactionType !== 'wired_filters'
            ? ' Search by Email or Transaction ID'
            : 'Search by IBAN or User ID'
        }
        onChange={onSearch}
        onPressEnter={() => applyFilters(context.setTransactionFilters)}
        allowClear
        bordered={false}
        prefix={prefix}
        value={search}
        style={{
          width: props.transactionType !== 'wired_filters' ? '343px' : '415px',
          borderRadius: '8px',
          backgroundColor: 'white',
          height: 50,
          marginRight: 11,
          marginBottom: 7,
        }}
      />
      <Modal
        title={undefined}
        visible={showModal}
        closable={false}
        footer={false}
        width={450}
        destroyOnClose
      >
        <Form className={styles.modal_content}>
          <div className={styles._modal_title} style={{ marginBottom: '30px' }}>
            {'Do you want to export the CSV file?'}
          </div>
          <div className="grid_col_2">
            <Button
              className="common_cancel_button"
              onClick={() => setModal(false)}
            >
              Cancel
            </Button>
            <Form.Item shouldUpdate={true}>
              {() => (
                <Button
                  className="common_submit_button"
                  htmlType="submit"
                  type="primary"
                  onClick={() => exportCSV()}
                >
                 {props.transactionType === 'wired_filters' ? 
                  <CSVLink {...csvReport}>Confirm</CSVLink> :
                  'Confirm'
                 } 
                </Button>
              )}
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </div>
  );
};
