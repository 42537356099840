import InputForm from '../../../../_common/components/forms/InputForm';
import React, { useContext } from 'react';
import { AppContext } from '../../../../_common/app_context_provider/AppContextProvider';
import { Button, Form, Select } from 'antd';
import { customUrlValidator } from '../../../../_heplers/RegexHelper';
import { FormInstance } from 'antd/lib/form';
import { validateMessages } from '../../../../_common/ValidationCominications';

const { Option } = Select;

export const MobilumPartnerDetailsCompanyInformationForm = (props: any) => {
  const _form_ref = React.createRef<FormInstance>();

  const showSuimtuBtton = () => {
    if (_form_ref.current) {
      const errors = _form_ref.current
        .getFieldsError()
        .filter(({ errors }) => errors.length).length;

      // Check if error
      if (errors) {
        return true;
      } else {
        return false;
      }
    }

    return true;
  };

  const onFinish = (values: any) => {
    let { setCurrentStep, updateState, mobilum_partner_details } = props;

    mobilum_partner_details = { ...mobilum_partner_details, ...values };

    updateState(mobilum_partner_details);
    setCurrentStep(1);
  };

  const context = useContext(AppContext);

  return (
    <Form
      layout="vertical"
      name="control-ref"
      ref={_form_ref}
      onFinish={onFinish}
      validateMessages={validateMessages()}
      initialValues={{ ...props.mobilum_partner_details }}
      className='flex' style={{ flexDirection: 'column', height: '100%' }}
    >
      <div className='column_description'>{props.title}</div>
      <Form.Item label="Company Name" name="companyName" rules={[{ required: true }]}>
        <InputForm placeholder="Acme Inc." />
      </Form.Item>
      <Form.Item label="Address 1" name="companyAddress1" rules={[{ required: true }]}>
        <InputForm placeholder="123 Main St." />
      </Form.Item>
      <Form.Item label="Address 2" name="companyAddress2">
        <InputForm placeholder="Suite #, Building etc." />
      </Form.Item>
      <div className="grid_col_2">
        <Form.Item label="City" name="companyCity" rules={[{ required: true }]}>
          <InputForm placeholder="Enter city name" />
        </Form.Item>
        <Form.Item
          label="State/Province"
          name="companyState"
          rules={[{ required: true }]}
        >
          <InputForm placeholder="Enter state/province" />
        </Form.Item>
      </div>
      <div className="grid_col_2">
        <Form.Item label="Postal Code" name="companyPostalCode" rules={[{ required: true }]}>
          <InputForm placeholder="Enter code" />
        </Form.Item>
        <Form.Item
          label="Country"
          name="companyCountry"
          rules={[{ required: true }]}
        >
          <Select
            showSearch
            placeholder='Canada'
            loading={context.loading_dictionaries}
            filterOption={(input: any, option: any) => (option.props.children ? option.props.children : "").toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {context.dictionaries &&
              context.dictionaries.countries?.map((x: any) => (
                <Option key={x.key} value={x.value}>
                  {x.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </div>
      <Form.Item
        name="companyWebsite"
        rules={[
          { required: true },
          {
            pattern: customUrlValidator(),
            message: 'Incorrect address',
          },
        ]}
        label="Website"
      >
        <InputForm placeholder="https://www.phaze.io" />
      </Form.Item>
      <div className='flex grid_col_2'
        style={{ marginTop: 'auto' }}
      >
        <Form.Item shouldUpdate={true}>
          {() => (
            <Button
              className="common_previous_button"
              onClick={() => props.navigate(-1)}
            >
              Previous
            </Button>
          )}
        </Form.Item>
        <Form.Item shouldUpdate={true}>
          {() => (
            <Button
              className="common_next_button"
              htmlType="submit"
              type="primary"
              disabled={showSuimtuBtton()}
            >
              Next
            </Button>
          )}
        </Form.Item>
      </div>
    </Form>
  );
};
