import React from 'react';
import styles from '../styles/Notifications.module.scss';
import { AddNewButtonComponent } from '../../../_common/components/add_new_button/AddNewButtonComponent';
import { ClearBoth } from '../../../_common/components/clearBoth/ClearBoth';
import { deleteNotificationsService, getNotificationsService, updateNotificationsService } from '../../../_services/notifications/NotificationsServices';
import { INotification } from '../interfaces/INotification';
import { INotificationsContentProps } from '../interfaces/INotificationsContentProps';
import { Left } from '../../../_common/components/left/Left';
import { loading_icon } from '../../../_common/components/loading/LoadingIcon';
import { Modal, Row, Spin } from 'antd';
import { NotificationForm } from '../forms/NotificationForm';
import { NotificationsTable } from '../tables/NotificationsTable';
import { openErrorNotification, openSuccessNotification } from '../../../_heplers/NotificationHelper';
import { PageTitleComponent } from '../../../_common/components/page_title/PageTitleComponent';
import { Right } from '../../../_common/components/right/Right';

export class NotificationsContent extends React.Component<INotificationsContentProps> {
    _is_mounted = true;

    state = {
        loading: true,
        notifications: [] as INotification[],
        // Modal
        show_modal: false,
        modal_data: {} as INotification
    };

    componentDidMount = () => this.getNotifications();

    componentWillUnmount = () => this._is_mounted = false;

    public render() {
        const {
            loading,
            notifications,
            // Modal
            show_modal,
            modal_data
        } = this.state;

        const {
            dictionaries,
            loading_dictionaries,
            available_sites
        } = this.props;

        return (
            <>
                <Row align='middle' justify='space-between'>
                    <Left>
                        <PageTitleComponent page_title='Notifications' />
                    </Left>
                    <Right>
                        <AddNewButtonComponent
                            title="Add New"
                            onClick={() => this.setModalVisible(true)}
                        />
                    </Right>
                </Row>
                <ClearBoth />
                <Spin className='spin_60' spinning={loading} indicator={loading_icon(40)}>
                    <NotificationsTable
                        data={notifications}
                        dictionaries={dictionaries}
                        loading_dictionaries={loading_dictionaries}
                        available_sites={available_sites}
                        changeNotificationStatus={this.changeNotificationStatus}
                        updateObjectInArray={this.editNotification}
                        deleteObjectFromArray={this.deleteNotification}
                    />
                </Spin>
                <Modal
                    title={undefined}
                    visible={show_modal}
                    closable={false}
                    footer={false}
                    width={800}
                    destroyOnClose
                >
                    <div className={styles.modal_content}>
                        <div className={styles._modal_title}>
                            {modal_data?.id ? 'Edit' : 'Add New'}
                        </div>
                        <NotificationForm
                            data={modal_data}
                            dictionaries={dictionaries}
                            loading_dictionaries={loading_dictionaries}
                            available_sites={available_sites}
                            setModalVisible={this.setModalVisible}
                            updateObjectInArray={this.updateObjectInArray}
                            addObjectToArray={this.addObjectToArray}
                        />
                    </div>
                </Modal>
            </>
        );
    }

    setModalVisible = (show_modal: boolean) => {
        if (!show_modal) {
            this.setState({
                show_modal,
                modal_data: {}
            });
        }
        else {
            this.setState({ show_modal });
        }
    }

    getNotifications = async () => {
        this.setState({ loading: true });

        await getNotificationsService()
            .then(response => {
                const notifications: Array<INotification> = response?.map((x: any) => {
                    return {
                        key: x.id,
                        ...x
                    }
                });

                this._is_mounted && this.setState({ notifications });
            })
            .finally(() => this._is_mounted && this.setState({ loading: false }));
    }

    editNotification = (modal_data: INotification) => {
        this.setState({
            modal_data,
            show_modal: true
        });
    }

    addNewnotification = () => {
        this.setState({
            modal_data: {},
            show_modal: true
        });
    }

    changeNotificationStatus = async (object: INotification) => {
        let { notifications } = this.state;

        object.disabled = !object.disabled;

        await updateNotificationsService(object)
            .then(response => {
                if (response) {
                    const index = notifications.findIndex((x: INotification) => x.id === object.id);

                    notifications[index].disabled = object.disabled;

                    this._is_mounted && this.setState({ notifications });

                    openSuccessNotification(`Notification ${object.subject} updated`)
                }
            });
    }

    updateObjectInArray = (notification: INotification) => {
        const notifications: Array<INotification> = this.state.notifications.map((x: INotification) => {
            if (x.id == notification.id) {
                return notification;
            }
            else {
                return x;
            }
        });

        this._is_mounted && this.setState({
            notifications,
            modal_data: undefined,
            show_modal: false
        });
    }

    addObjectToArray = (notification: INotification) => {
        const notifications = [notification, ...this.state.notifications];

        this._is_mounted && this.setState({
            notifications,
            modal_data: undefined,
            show_modal: false
        });
    }

    deleteNotification = async (notification: INotification) => {
        await deleteNotificationsService(notification.siteUuid!, notification.id!.toString())
            .then(response => {
                if (response) {
                    let { notifications } = this.state;

                    notifications = notifications.filter((x: INotification) => x.id !== notification.id);

                    this._is_mounted && this.setState({ notifications });

                    openSuccessNotification('Notification deleted');
                }
                else {
                    openErrorNotification(`Service doesn't work`);
                }
            });
    }
}
