import { IForgotPasswordService } from '../../_common/interfaces/services/IForgotPasswordService';
import { ILoginService } from '../../_common/interfaces/services/ILoginService';
import { IResetPasswordByCodeService } from '../../_common/interfaces/services/IResetPasswordByCodeService';
import { setToken } from '../../_heplers/LocalStorageHelpers';
import { openErrorNotification } from '../../_heplers/NotificationHelper';
import axios, { auth } from '../api';

const url = "api/UserApi";

export const loginService = (data: ILoginService) =>
  axios.post(`${url}/Authenticate`, { ...data })
    .then(response => {
      if (response.status === 200) {
        setToken(response.data.token);
      }
      return response;
    })
    .catch(error => {
      console.log("--------------- loginService", error.message);

      return {
        error: true,
        status: error.response.status,
        message: error.response.data.response,
        data: undefined
      };
    });

export const forgotPasswordService = (data: IForgotPasswordService) =>
  axios.post(`${url}/ForgotPassword`, { ...data })
    .then(response => response)
    .catch(error => {
      console.log("--------------- forgotPasswordService", error.message);

      return false;
    });

export const resetPasswordByCodeService = (data: IResetPasswordByCodeService) =>
  axios.post(`${url}/ResetPasswordByCode`, { ...data })
    .then(response => response)
    .catch(error => {
      console.log("--------------- resetPasswordByCodeService", error.message);

      return false;
    });
export const verifyOtpCode = (code: any, email: any) =>
  axios.post(`${url}/VerifyEmailCode`, {code, email})

  export const resetPassword = (payload: any) =>
  axios.post(`${url}/SetNewPassword`, payload)
  
  export const refreshToken = () => {
    return new Promise((resolve, reject) => {
      auth.post(`${url}/RefreshToken`).then(
        (success) => {
          if(success.data && success.data.errors && success.data.errors.length) {
            openErrorNotification(success.data.errors[0])
            return resolve(success.data.errors[0])
          } else {
            return resolve(success.data)
          }
        },
        (error) => reject(error)
      );
    });
  }
  export const revokeToken = () => {
    return new Promise((resolve, reject) => {
      auth.post(`${url}/RevokeToken`, {}).then(
        (success) => {
          resolve(success)
        },
        (error) => reject(error)
      );
    });
  }
  