export enum navPath {
  mobilum_partners = 'mobilum_partners',
  mobilum_partners_id = 'mobilum_partners/:id',
  partner_admins = 'partner_admins',
  partner_admins_id = 'partner_admins/:id',
  // Configuration
  configuration_blocked_territories = 'configuration/blocked_territories',
  configuration_blocked_cards = 'configuration/blocked_cards',
  configuration_coins_and_tokens = 'configuration/coins_and_tookens',
  configuration_roles = 'configuration/roles',
  configuration_role = 'configuration/role',
  configuration_role_id = 'configuration/role/:id',
  configuration_fee_config = 'configuration/fee_config',
  configuration_user_risk_level = 'configuration/user_risk_level',
  // Transactions
  transactions_transaction_history = 'transactions/transaction_history',
  transactions_delivered_transactions = 'transactions/delivered_transactions',
  transactions_pending_transactions = 'transactions/pending_transactions',
  transactions_failed_transactions = 'transactions/failed_transactions',
  transactions_transaction_explorer = 'transactions/transaction_explorer',
  transactions_wire_transactions = 'transactions/wire_transactions',
  transactions_settlement_transactions = 'transactions/settlement_transactions',
  // Notifications
  notifications = 'notifications',
  // Reports
  reports = 'reports',
  // Order
  order_details = 'order_details',
  order_details_id = 'order_details/:id',

  // User Profile
  user_profile = 'user_profile',
  user_profile_id = 'user_profile/:id/:type',
  user_history = 'user_history',
  user_history_id = 'user_history/:id',

  reset_password = 'reset_password',
  login = 'login',
  forgot_password = 'forgot_password',
  set_password = 'set_password',

  home = 'home',
  coins_and_tokens = 'coins_and_tokens',
  registered_users = 'registered_users',

  // wallets
  wallets = 'wallets',
  wallets_id = 'wallets/:id',
  transactions_wallet = 'wallet',
  transactions_wallet_params = 'wallet/:siteUuid/:wallet',

  // anti fraud rules
  anti_fraud = 'antifraud',
  anti_fraud_id = 'antifraud/:id/:name',
  anti_fraud_details = 'antifraud/:id/:name/:type',
  anti_fraud_blocked_users = 'antifraud/blocked_users'
}