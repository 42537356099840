import { AppContext } from '../../_common/app_context_provider/AppContextProvider';
import { RegisteredUsersContent } from './content/RegisteredUsersContent';
import { useContext } from 'react';

const RegisteredUsersView = () => {
    const context = useContext(AppContext);
    
    return <RegisteredUsersContent context={context} />;
}

export default RegisteredUsersView;
