import _ from 'lodash';
import InputForm from '../../../../_common/components/forms/InputForm';
import React from 'react';
import {
    Button,
    Form,
    Select,
    Spin
    } from 'antd';
import { createAbsoluteUrl } from '../../../../_heplers/UrlCreatorHelpres';
import { createUser, updateUser } from '../../../../_services/users/UsersServices';
import { FormInstance } from 'antd/lib/form';
import { loading_icon } from '../../../../_common/components/loading/LoadingIcon';
import { navPath } from '../../../../_common/NavPath';
import { openSuccessNotification } from '../../../../_heplers/NotificationHelper';
import { validateMessages } from '../../../../_common/ValidationCominications';

export class PartnerAdminForm extends React.Component<any> {
    _is_mounted = false;
    _form_ref = React.createRef<FormInstance>();
    state = {
        loading: false,
        message: undefined,
        modalOpen: false,
        availableSites: [] as any,
    };

    componentDidMount = () => {
        this._is_mounted = true;
        this.setInitData();
    }

    componentDidUpdate = (prevProps: any) => {
        if (prevProps !== this.props) {
            this.setInitData();
        }
    }

    componentWillUnmount = () => {
        this._is_mounted = false;
    };
    

    public render() {
        const { loading, availableSites } = this.state;
        return (
            <div>
                <Spin spinning={loading} indicator={loading_icon(60)}>
                    <Form
                        className='content-wrapper'
                        layout='vertical'
                        name='control-ref'
                        onFinish={this.onFinish}
                        ref={this._form_ref}
                        validateMessages={validateMessages()}
                        initialValues={{ ...this.props.data }}
                    >
                        <div className='grid_col_3'>
                            <Form.Item
                                name='name'
                                label='Name'
                                rules={[{ required: true }]}
                            >
                                <InputForm
                                    placeholder='enter name'
                                />
                            </Form.Item>
                            <Form.Item
                                name='surname'
                                label='Surname'
                                rules={[{ required: true }]}
                            >
                                <InputForm
                                    placeholder='enter surname'
                                />
                            </Form.Item>
                            <Form.Item
                                name='email'
                                label='Email'
                                rules={[{ type: 'email', required: true }]}
                            >
                                <InputForm
                                    placeholder='enter email address'
                                />
                            </Form.Item>
                            <Form.Item
                                label="Access level"
                                rules={[{ required: true }]}
                                name="userRole"
                            >
                                <Select
                                    showSearch
                                    placeholder="Select"
                                    filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {this.props.context?.dictionaries?.available_roles?.map((x: any) => (
                                        <Select.Option key={x.value} value={x.name}>
                                            {x.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label='Partner'
                                rules={[{ required: true }]}
                                name='selectedSites'
                            >
                                 <Select
                                    showSearch
                                    placeholder="Select"
                                    filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {availableSites?.filter((item: any) => !item.archived).map((x: any) => (
                                        <Select.Option key={x.siteUuid} name={x.siteName} value={x.siteUuid}>
                                            {x.siteName}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                        <div className='grid_col_2 action-buttons'>
                                <Button onClick={() => this.props.navigate(-1)} className='cancel_button common_previous_button'>
                                    Cancel
                                </Button>
                            <Form.Item shouldUpdate={true}>
                                {() => (
                                    <Button
                                        className="ant-btn ant-btn-primary common_next_button"
                                        htmlType='submit'
                                        type='primary'
                                        disabled={this.showSuimtuBtton()}
                                    >
                                        Save
                                    </Button>
                                )}
                            </Form.Item>
                        </div>
                    </Form>
                </Spin>
            </div >
        );
    }

    showSuimtuBtton = () => {
        if (this._form_ref.current) {
            const fields = this._form_ref.current.getFieldsValue();
            const errors = this._form_ref.current
                .getFieldsError()
                .filter(({ errors }) => errors.length).length;
            // Check if error
            if (errors) {
                return true;
            }
            // Check if email or sites or userRole exist
            if (!fields.email || !fields.userRole || !fields.selectedSites) {
                return true;
            } else {
                return false;
            }
        }

        return true;
    };

    onFinish = (value: any) => {
        this.setState({
            loading: true,
            message: undefined
        });
        let payload = {
            ...value,
            id: this.props.data.id,
            disabled: this.props.data.disabled,
            selectedSites: _.isString(value.selectedSites) ? value.selectedSites?.split(' ') : value.selectedSites
        }
        const api = this.props.edited ? updateUser(payload) : createUser(payload)
        api.then(() => {
            openSuccessNotification(this.props.edited ? 'Account updated' : 'Account created');
            this.props.navigate(-1);
        }).finally(() => {
            this.setState({ loading: false })
        })
    };

    setInitData = () => {
        const { context } = this.props
        this.setState({ availableSites: _.cloneDeep(context.app_user_inf?.availableSites).filter((item: any) => !item.archived)})
    }
}


