import axios from '../api';

export interface IAnaliticsData {
  beginDate?: string;
  endDate?: string;
  sites?: string[];
  country?: string;
  currency?: string;
}

export const getAnaliticsService = (data: IAnaliticsData) =>
  axios
    .post('api/DashboardAnalytics', data )
    .then((response) => response.data)
    .catch((error) => {
      console.log('--------------- getClientDetails', error.message);
      return undefined;
    });
