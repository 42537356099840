import { useContext, useEffect, useState } from 'react';
import { Button, Col, Modal, Row, Spin } from 'antd';
import { ClearBoth } from '../../../../_common/components/clearBoth/ClearBoth';
import { createAbsoluteUrl } from '../../../../_heplers/UrlCreatorHelpres';
import { getUsersServices, setUserDisabled } from '../../../../_services/users/UsersServices';
import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { loading_icon } from '../../../../_common/components/loading/LoadingIcon';
import { navPath } from '../../../../_common/NavPath';
import { openErrorNotification, openSuccessNotification } from '../../../../_heplers/NotificationHelper';
import { PageTitleComponent } from '../../../../_common/components/page_title/PageTitleComponent';
import { PartnerAdminsTable } from '../tables/PartnerAdminsTable';
import SearchElements from '../../../../_common/components/searchElements/SearchElements';
import styles from '../styles/PartnerAdminsList.module.scss';
import { AppContext } from '../../../../_common/app_context_provider/AppContextProvider';

const PartnerAdminsContent = (props: any) => {
  const context = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [partnerAdmins, setPartnerAdmins] = useState([] as any);
  const [showModal, setShowModal] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [modalData, setModalData] = useState({} as any);
  const [search, setSearch] = useState(context.partner_admin_filters.search);

  useEffect(() => {
    getPartnerAdmins();
  }, []);

  const onGlobalSearch = (event: any) => {
    setSearch(event.target.value.toLowerCase());
    context.setPartnerAdminFilters({
      ...context.partner_admin_filters,
      search: event.target.value.toLowerCase()})
  };

  const getPartnerAdmins = () => {
    setLoading(true);
    getUsersServices()
      .then((response) => {
        const partner_admins = response.data.map((x: any) => {
          return {
            key: x.id,
            lastSeen: x.lastSeen && new Date(x.lastSeen).getTime(),
            ...x,
          };
        });

        setPartnerAdmins(partner_admins);
      })
      .finally(() => setLoading(false));
  };

  const setModalVisible = (show_modal: boolean) => {
    setShowModal(show_modal);
  };

  const userStatusModal = (modal_data: any) => {
    setModalData(modal_data);
    setShowModal(true);
  };

  const changeUserStatusService = () => {
    setLoadingModal(true);

    let updatedPartnerAdmins = [...partnerAdmins];
    const index = updatedPartnerAdmins.findIndex((x) => x.id === modalData.id);
    setUserDisabled({ userId: modalData.id, disabled: !modalData.disabled })
      .then(() => {
        updatedPartnerAdmins[index].disabled = !updatedPartnerAdmins[index].disabled;
        openSuccessNotification('User successfully updated!');
      })
      .catch(() => openErrorNotification('An error occurred!'))
      .finally(() => {
        setLoadingModal(false);
        setShowModal(false);
        setPartnerAdmins(updatedPartnerAdmins);
      });
  };
  
  let filteredUsers = partnerAdmins?.filter(
    (user: any) =>
      user.name?.toLowerCase().includes(search) ||
      user.email?.toLowerCase().includes(search)
  );

  return (
    <>
      <Row justify="space-between" align="middle">
        <Col>
          <PageTitleComponent page_title="Partner Admins" />
        </Col>
        {props.validatePermission('EditUsers') && (
          <Col>
            <Link to={createAbsoluteUrl(`${navPath.partner_admins}/new`)}>
              <Button
                className="common_add_new_button margin_bottom_20"
                icon={<PlusOutlined />}
                size="large"
                type="primary"
                style={{ backgroundColor: '#FBA338' }}
              >
                Add New User
              </Button>
            </Link>
          </Col>
        )}
      </Row>
      <SearchElements placeholder="Search by name or email" value={search} onSearch={onGlobalSearch} showIcon />
      <ClearBoth />
      <Spin className="spin_60" spinning={loading} indicator={loading_icon(40)}>
        <PartnerAdminsTable
          data={filteredUsers}
          userStatusModal={userStatusModal}
        />
      </Spin>
      <Modal
        title={undefined}
        visible={showModal}
        closable={false}
        footer={false}
        width={706}
      >
        <div className={styles.modal_content}>
          <InfoCircleOutlined />
          <div className={styles._modal_title}>
            Confirm you want to{' '}
            {modalData.disabled ? 're-activate' : 'deactivate'}{' '}
            {modalData.name}'s account.
          </div>
          <Spin className="spin_60" spinning={loadingModal} indicator={loading_icon(40)}>
            <div className="grid_col_2">
              <Button
                type="primary"
                className="common_submit_button"
                onClick={changeUserStatusService}
              >
                {modalData.disabled ? 'Activate' : 'Deactivate'}
              </Button>
              <Button
                className="common_cancel_button"
                onClick={() => setModalVisible(false)}
              >
                Cancel
              </Button>
            </div>
          </Spin>
        </div>
      </Modal>
    </>
  );
};

export default PartnerAdminsContent;
