import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import SearchElements from '../../../../_common/components/searchElements/SearchElements';
import styles from '../styles/MobilumPartnerDetails.module.scss';
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Spin
  } from 'antd';
import { IMobilumPartnerDetailsTechnicalSetUpFormProps } from '../interfaces/IMobilumPartnerDetailsTechnicalSetUpFormProps';
import { loading_icon } from '../../../../_common/components/loading/LoadingIcon';
import { validateMessages } from '../../../../_common/ValidationCominications';

export const MobilumPartnerDetailsCurrenciesSelect = (data: IMobilumPartnerDetailsTechnicalSetUpFormProps) => {
  const [availableCryptos, setAvailableCryptos] = useState([]) as any
  const [availableCurrencies, setAvailableCurrencies] = useState([]) as any
  const [loading, setLoading] = useState(false)
  const [cryptoSearch, setSearch] = useState('')
  const [currencySearch, setCurrencySearch] = useState('')
  const [checkAllCrypto, setCheckAllCrypto] = React.useState(false);
  const [checkAllCurrency, setCheckAllCurrency] = React.useState(false);
  const onCryptoSearch = (event: any) => {
    setSearch(event.target.value.toLocaleLowerCase())
  }
  const onCurrencySearch = (event: any) => {
    setCurrencySearch(event.target.value.toLocaleLowerCase())
  }
  useEffect(() => {
    const { mobilum_partner_details, dictionaries } = data;
    const selectedCurrencies = mobilum_partner_details.currencies;
    const selectedCryptos = mobilum_partner_details.availableTokenModel;
    const dictionaryCurrency = dictionaries.currencies;
    const dictionaryCoins = dictionaries.coins;
    dictionaryCurrency?.forEach((element: any) => {
      element.selected = false;
    });
    dictionaryCoins?.forEach((element: any) => {
      element.selected = false;
    });
    let userCurrencies, userCryptos;
    if (mobilum_partner_details.id) {
      userCurrencies = dictionaryCurrency?.filter((currency: any) => selectedCurrencies?.some((selected: any) => currency.value === selected));
      userCurrencies?.forEach((element: any) => {
        element.selected = true;
      });
      userCryptos = dictionaryCoins?.filter((coin: any) => selectedCryptos?.some((selected: any) => coin.id === selected.tokenId));
      userCryptos?.forEach((element: any) => {
        element.selected = true;
      });
    } else {
      userCurrencies = dictionaryCurrency?.forEach((element: any) => {
        element.selected = false;
      });
      userCryptos = dictionaryCoins?.forEach((element: any) => {
        element.selected = false;
      });
    }
    const _allCurrencies = _.unionBy(userCurrencies || [], dictionaryCurrency, 'value');
    const _allCryptos = _.unionBy(userCryptos || [], dictionaryCoins, 'id');
    setAvailableCurrencies(_allCurrencies)
    setAvailableCryptos(_allCryptos)
  }, [])

  const onCheckAllChange = (e: any, type: string) => {
    const updateType = type === 'crypto' ? availableCryptos : availableCurrencies;
    const newArr = updateType.map((item: any) => ({
      ...item,
      selected: e.target.checked ? true : false
    }));
    type === 'crypto' ? updateCryptos(e, newArr) : updateCurrency(e, newArr)
    type === 'crypto' ? updateCryptos(e, newArr) : updateCurrency(e, newArr)
  };
  const updateCryptos = (e: any, newArr: any) => {
    setAvailableCryptos(newArr);
    setCheckAllCrypto(e.target.checked);
  }
  const updateCurrency = (e: any, newArr: any) => {
    setAvailableCurrencies(newArr);
    setCheckAllCurrency(e.target.checked);
  }

  const changeCryptoStatus = (event: any) => {
    const recordId = event.id
    const index = availableCryptos?.findIndex((x: any) => x.id === recordId);
    availableCryptos[index]['selected'] = !availableCryptos[index]['selected'];
    setAvailableCryptos([...availableCryptos]);
  }
  const changeCurrencyStatus = (event: any) => {
    const recordId = event.value
    const index = availableCurrencies?.findIndex((x: any) => x.value === recordId);
    availableCurrencies[index]['selected'] = !availableCurrencies[index]['selected'];
    setAvailableCurrencies([...availableCurrencies]);
  }
  const onFinish = () => {
    setLoading(true)
    let {
      setCurrentStep,
      updateState,
      mobilum_partner_details
    } = data;
    mobilum_partner_details = {
      ...mobilum_partner_details,
      purchasableTokens: availableCryptos.filter((crypto: any) => crypto.selected)
        .map((crypto: any) => crypto.id),
      currencies: availableCurrencies?.filter((coin: any) => coin.selected)
        .map((coin: any) => coin.value)
    }
    updateState(mobilum_partner_details);
    setLoading(false);
    setCurrentStep(6);
  };
  let { setCurrentStep } = data;
  let filtered_cryptos = availableCryptos?.filter((item: any) => item.name?.toLowerCase().includes(cryptoSearch!) ||
    item.value?.toString().toLowerCase().includes(cryptoSearch!))
  let filtered_currencies = availableCurrencies?.filter((item: any) => item.name?.toLowerCase().includes(currencySearch!) ||
    item.value?.toString().toLowerCase().includes(currencySearch!))
  let activeCryptos = availableCryptos?.filter((item: any) => item.selected)?.length
  let activeCurrencies = availableCurrencies?.filter((item: any) => item.selected)?.length
  return (
    <Form
      layout="vertical"
      name="control-ref"
      onFinish={onFinish}
      validateMessages={validateMessages()}
      className='flex' style={{ flexDirection: 'column', height: '100%' }}
    >
      <Spin
        className="spin_60"
        spinning={loading}
        indicator={loading_icon(40)}
      >
        <div className='column_description'>
          Currencies to Enable
        </div>
        <div className={styles.content_wrapper}>
          
          <Form.Item valuePropName="checked">
            <div className={styles.select_wrapper}>
              <div className={styles.sticky_position}>
                <SearchElements placeholder='Search for Fiat Currency' onSearch={onCurrencySearch} allowClear={false} showIcon className={styles.search_element_style} />
                <Checkbox onChange={(e: any) => onCheckAllChange(e, 'currency')} checked={checkAllCurrency}>
                  Select all Fiat
                </Checkbox>
                <Divider className={styles.divider} />
              </div>
              <div className={styles.scrollbar}>
                {filtered_currencies.map((currency: any) => (
                  <div style={{ marginBottom: 7 }} key={currency.value}>
                    <Checkbox checked={currency.selected} onChange={() => changeCurrencyStatus(currency)}>{currency.name} ({currency.value})</Checkbox>
                  </div>
                ))}
              </div>
            </div>
          </Form.Item>
        </div>
      </Spin>
      <div className='flex grid_col_2'
        style={{ marginTop: 'auto' }}
      >
        <Button
          className="common_previous_button"
          onClick={() => setCurrentStep(4)}
          disabled={loading}
        >
          Previous
        </Button>
        <Form.Item shouldUpdate={true}>
          {() => (
            <Button
              type="primary"
              htmlType="submit"
              className="common_next_button"
              disabled={activeCurrencies === 0 || loading}
            >
              Next
            </Button>
          )}
        </Form.Item>
      </div>
    </Form>
  );
}
