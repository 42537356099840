import React from 'react';
import ReactEcharts from 'echarts-for-react';

export class BarChart extends React.Component<any> {
    render() {
        const { caption, sales, commission } = this.props.data;
        const option = {
            color: ["#458FE6", "#FBA338"],
            tooltip: {
                trigger: 'axis'
            },
            legend: {
                data: ['Sales', 'Commissions'],
                top: 'bottom',
                x: '10%',
                itemHeight: 13,
                itemWidth: 13
            },
            xAxis: [
                {
                    data: caption ? caption : [],
                    axisTick: false
                }
            ],
            yAxis: [
                {
                    type: 'value'
                },
            ],
            series: [
                {
                    name: 'Sales',
                    type: 'bar',
                    data: sales ? sales : [],
                    itemStyle: {
                        borderRadius: [4, 4, 0, 0]
                    },
                    barGap: "0%",
                    barWidth: 18
                },
                {
                    name: 'Commissions',
                    type: 'bar',
                    data: commission ? commission : [],
                    itemStyle: {
                        borderRadius: [4, 4, 0, 0]
                    },
                    barWidth: 18
                }
            ]
        };
        return (
            <ReactEcharts option={option} />
        )
    }
}
