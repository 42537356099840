import { Button, Select, Spin } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../_common/app_context_provider/AppContextProvider';
import { loading_icon } from '../../../_common/components/loading/LoadingIcon';
import { PageTitleComponent } from '../../../_common/components/page_title/PageTitleComponent';
import SearchElements from '../../../_common/components/searchElements/SearchElements';
import { getFraudRulesBlockedUsers } from '../../../_services/antifraud/AntiFraudService';
import { AntiFraudTable } from '../Table';

const AntifraudBlockedUsersContent = () => {
	const context = useContext(AppContext);
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState({} as any);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [searchName, setSearchName] = useState('');
	const [searchSite, setSearchSite] = useState();

  const onChangePage = (pagination: any) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const fetchData = async (params?: any) => {
    setLoading(true);
    const queryParams = {
      searchName,
      searchSite,
      rows: pageSize,
      startRow: (currentPage - 1) * pageSize + 1,
      ...params,
    };
    await getFraudRulesBlockedUsers(queryParams)
      .then((response) => {
        if (response.results) setData(response);
      })
      .finally(() => setLoading(false));
  };

	const onFiltersApply = () => {
		setCurrentPage(1);
		fetchData();
	};

  useEffect(() => {
    fetchData();
  }, []);

	return (
		<>
			<PageTitleComponent page_title='Blocked Users' />
			<div className="flex" style={{alignItems: 'center', flexWrap: 'wrap', columnGap: 8 }}>
      <SearchElements
				placeholder='Search by Name or Email'
				onSearch={(e: React.ChangeEvent<HTMLInputElement>) => setSearchName(e.target.value)}
				value={searchName}
				showIcon
			/>
			 <Select
            placeholder='Sites'
            value={searchSite}
            onChange={(value: any) =>
							setSearchSite(value)
						}
						style={{marginBottom: '16px'}}
            className="filters mb-16"
            allowClear
            showSearch
            filterOption={(input: any, option: any) => (option.props.children ? option.props.children : "").toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {context.app_user_inf &&
            context.app_user_inf.availableSites?.filter((item: any) => !item.archived).map((x: any) => (
                <Select.Option key={x.siteUuid} value={x.siteUuid}>
                  {x.siteName}
                </Select.Option>
              ))}
          </Select>
			<Button
				type='primary'
				className='common_apply_filters_button mb-16'
				onClick={() => onFiltersApply()}
        style={{
          marginBottom: 16,
      }}
			>
				Apply Filter
			</Button>
      </div>
			<Spin
				className='spin_60'
				spinning={loading}
				indicator={loading_icon(40)}
			>
				<AntiFraudTable
					onChangePage={onChangePage}
					list={data?.results}
					total={data?.total}
					pageSize={pageSize}
					currentPage={currentPage}
				/>
			</Spin>
		</>
	);
};

export default AntifraudBlockedUsersContent;
