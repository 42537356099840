import { Button, Tooltip } from 'antd';
import React from 'react';
import ReactToPrint from 'react-to-print';
import { PrinterFilled } from '@ant-design/icons';

export class PDFGenerator extends React.Component {
  state = {
    hidden: true
  }

  render() {
    const { hidden } = this.state;

    return (
      <div>
        <ReactToPrint
          trigger={() =>  <Tooltip title='Print'><Button style={{ marginLeft: 10   }} icon={<PrinterFilled />} onClick={() => this.onClick()} /></Tooltip>}
          content={() => this.componentRef}
          bodyClass={"printElement1"}
        />
        <div style={{ display: 'none' }}>
          <div ref={el => (this.componentRef = el)}>
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }

  onClick = () => {
    this.setState({ hidden: false });

    setTimeout(() => {
      this.setState({ hidden: true });
    }, 2200);
  }
}
