import { _default_page_size, _hide_when_less_than, _page_size_options } from '../../../../_heplers/GlobalValues';
import { AppContext } from '../../../../_common/app_context_provider/AppContextProvider';
import { createAbsoluteUrl } from '../../../../_heplers/UrlCreatorHelpres';
import { Link } from 'react-router-dom';
import { memo, useContext, useState } from 'react';
import { navPath } from '../../../../_common/NavPath';
import { Button, Divider, Switch, Table, Tooltip } from 'antd';

export const MobilumPartnersTable = (props: any) => {
    const {
        data,
        changeAccountStatus,
        changeAccountArchived
    } = props;

    const context = useContext(AppContext);
    const [pageSize, setPageSize] = useState(context.mobilum_partner_filters.perPage);
    const validatePermission = context.validatePermission;
    const listOfPermissions = useContext(AppContext).app_user_inf.permissions;
    const [defaultCurrent, setDefaultCurrentPage] = useState(context.mobilum_partner_filters.currentPage);

    const onPageChange = (currentPage: number, pageSize: number) => {
      setDefaultCurrentPage(currentPage);
      setPageSize(pageSize);
      context.setMobilumPartnerFilters({
        ...context.mobilum_partner_filters,
        perPage: pageSize,
        currentPage})
    }

    const columns: any[] = [
      {
        title: 'Client Name',
        key: 'siteName',
        ellipsis: true,
        render: (record: any) =>
          validatePermission('Sites') ? (
            <a
              target="_blank"
              rel="noreferrer"
              href={`${context.dictionaries.widgetUrl}?apikey=${record.siteUuid}`}
            >
              {record.siteName}
            </a>
          ) : (
            record.siteName
          ),
        sorter: (a: any, b: any) =>
          (a.siteName ? a.siteName : '').localeCompare(
            b.siteName ? b.siteName : '',
          ),
      },
      {
        title: 'Account Active',
        key: 'disabled',
        sorter: (a: any, b: any) => a.disabled - b.disabled,
        render: (record: any) => (
          <Switch
            checked={!record.disabled}
            onChange={() => changeAccountStatus(record)}
          />
        ),
      },
      {
        title: 'Actions',
        key: 'actions',
        render: (record: any) => (
          <>
            <Tooltip placement="rightTop" title="Edit Partner">
              <Link
                to={createAbsoluteUrl(
                  `${navPath.mobilum_partners}/${record.siteUuid}`,
                )}
              >
                <Button
                  size="small"
                  type="link"
                  style={{ fontSize: '12px', padding: '5px' }}
                >
                  Edit Profile
                </Button>
              </Link>
              <Divider
              type="vertical"
              style={{ margin: 0, background: '#458FE6' }}
            />
            </Tooltip>
            {!!listOfPermissions?.includes('SuperAdmin') && (<Tooltip placement="rightTop" title="Fraud Protection Filters">
              <Link
                to={createAbsoluteUrl(
                  `${navPath.anti_fraud}/${record.siteUuid}/${record.siteName}`,
                )}
              >
                <Button
                  size="small"
                  type="link"
                  style={{ fontSize: '12px', padding: '5px' }}
                >
                 Fraud Protection Filters
                </Button>
              </Link>
              </Tooltip>)}
            <Divider
              type="vertical"
              style={{ margin: 0, background: '#458FE6' }}
            />
            <Tooltip
              placement="rightTop"
              title={record.archived ? 'Unarchive' : 'Archive'}
            >
              <Button
                size="small"
                type="link"
                onClick={() => changeAccountArchived(record)}
                style={{ fontSize: '12px', padding: '3px' }}
              >
                {record.archived ? 'Unarchive' : 'Archive'}
              </Button>
            </Tooltip>
          </>
        ),
      },
    ];

    return (
        <Table
            bordered
            size='small'
            columns={columns}
            dataSource={data}
            scroll={{ x: 500 }}
            pagination={{
                pageSize: pageSize,
                defaultCurrent: defaultCurrent,
                onChange: (currentPage: number, pageSize: number) => onPageChange(currentPage, pageSize),
                showSizeChanger: true,
                pageSizeOptions: _page_size_options,
                hideOnSinglePage: data.length < _hide_when_less_than
            }}
        />
    )
}

export default memo(MobilumPartnersTable)
