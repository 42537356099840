import { PageTitleComponent } from '../../_common/components/page_title/PageTitleComponent';
import { ReportsTable } from './ReportsTable';

const ReportsView = () => {
  return (
    <>
      <PageTitleComponent page_title="Generated Reports" />
      <ReportsTable />
    </>
  );
};

export default ReportsView;
