import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import {
    Button,
    Checkbox,
    Col,
    Form,
    Row,
    Spin
    } from 'antd';
import { IMobilumPartnerDetailsTechnicalSetUpFormProps } from '../interfaces/IMobilumPartnerDetailsTechnicalSetUpFormProps';
import { loading_icon } from '../../../../_common/components/loading/LoadingIcon';
import { validateMessages } from '../../../../_common/ValidationCominications';

export const MobilumPartnerDetailsFeaturesSelect = (data: IMobilumPartnerDetailsTechnicalSetUpFormProps) => {
    const [availableFeatures, setAvailableFeatures] = useState([]) as any;
    const [loading, setLoading] = useState(false);
    const [canContinue, setCanContinue] = useState(false);


    useEffect(() => {
        const { mobilum_partner_details, dictionaries } = data;
        const selectedFeatures = mobilum_partner_details.features?.split(',');
        let userFeatures;

        if (mobilum_partner_details.id) {
            userFeatures = dictionaries.features?.filter((feature: any) => selectedFeatures?.some((selected: any) => feature.name === selected));
            userFeatures?.forEach((element: any) => {
                element.selected = true;
            });
        }
        else {
            userFeatures = dictionaries.features?.forEach((element: any) => {
                element.selected = false;
            });
        }
        const _allFeatures = _.unionBy(userFeatures || [], dictionaries.features, 'value') || [];
        setAvailableFeatures(_allFeatures);
        const activeFeatures = _allFeatures?.filter((item: any) => item.selected)?.length;
        setCanContinue(activeFeatures > 0);
    }, [])

    const changeFeatureStatus = (event: any) => {
        const recordId = event.value
        const index = availableFeatures?.findIndex((x: any) => x.value === recordId);
        availableFeatures[index]['selected'] = !availableFeatures[index]['selected'];
        setAvailableFeatures(availableFeatures)
        const activeFeatures = availableFeatures?.filter((item: any) => item.selected)?.length
        setCanContinue(activeFeatures > 0)
    }
    const onFinish = () => {
        setLoading(true)
        let {
            setCurrentStep,
            updateState,
            mobilum_partner_details
        } = data;
        mobilum_partner_details = {
            ...mobilum_partner_details,
            features: availableFeatures?.filter((feature: any) => feature.selected)
                .map((coin: any) => coin.name).join()
        }
        updateState(mobilum_partner_details);
        setLoading(false)
        setCurrentStep(5);
    };
    let { setCurrentStep } = data;

	let setPrevStepVal = data.id === 'new' ? 2 : 3;
    return (
        <Form
            layout="vertical"
            name="control-ref"
            onFinish={onFinish}
            validateMessages={validateMessages()}
            initialValues={{ ...availableFeatures}}
            className='flex' style={{ flexDirection: 'column', height: '100%' }}
        >
            <Spin
                className="spin_60"
                spinning={loading}
                indicator={loading_icon(40)}
            >
                <div className='column_description'>
                    Features to Activate
                </div>
                <Form.Item valuePropName="checked">
                    <Row gutter={[32, 32]}>
                        {availableFeatures.length > 0 && _.sortBy(availableFeatures, 'value').map((feature: any) => (
                            <Col span={8} key={feature.value}>
                                <Checkbox defaultChecked={feature.selected} onChange={() => changeFeatureStatus(feature)}>{feature.name}</Checkbox>
                            </Col>
                        ))}
                    </Row>
                </Form.Item>
            </Spin>
            <div className='flex grid_col_2'
                style={{ marginTop: 'auto' }}
            >
                <Button
                    className="common_previous_button"
                    onClick={() => setCurrentStep(setPrevStepVal)}
                    disabled={loading}
                >
                    Previous
                </Button>
                <Form.Item shouldUpdate={true}>
                    {() => (
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="common_next_button"
                            disabled={!canContinue || loading}
                        >
                            Next
                        </Button>
                    )}
                </Form.Item>
            </div>
        </Form>
    );
}
