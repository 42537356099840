import * as React from 'react';

function MenuBlockedCards(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.5 23C17.299 23 22 18.299 22 12.5S17.299 2 11.5 2 1 6.701 1 12.5 5.701 23 11.5 23zM6.126 8.077c.123-.051.255-.077.389-.077h9.97A1.012 1.012 0 0117.5 9.015v6.698a1.014 1.014 0 01-1.015 1.014h-9.97A1.012 1.012 0 015.5 15.713V9.015a1.012 1.012 0 01.626-.938zm1.17 6.854c.102.102.241.16.386.16h7.636a.546.546 0 000-1.091H7.682a.545.545 0 00-.386.931zm9.003-3.769c.07-.07.11-.166.11-.266V9.467a.377.377 0 00-.376-.376h-1.43a.377.377 0 00-.376.376v1.43a.376.376 0 00.377.376h1.429c.1 0 .195-.04.266-.11z"
                fill="#A3B0BF"
            />
        </svg>
    );
}

const MemoMenuBlockedCards = React.memo(MenuBlockedCards);
export default MemoMenuBlockedCards;
