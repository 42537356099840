import * as React from 'react';

function MenuRoles(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.5 23C17.299 23 22 18.299 22 12.5S17.299 2 11.5 2 1 6.701 1 12.5 5.701 23 11.5 23zm4.632-12.238c.196.132.419.218.652.252a.732.732 0 01.616.596c.067.352.1.71.1 1.068-.005.34-.04.679-.104 1.012a.74.74 0 01-.628.608 1.56 1.56 0 00-1.252 2.156.74.74 0 01-.216.844 6 6 0 01-1.6.956.736.736 0 01-.864-.244 1.569 1.569 0 00-1.98-.454 1.549 1.549 0 00-.556.494.736.736 0 01-.852.268 5.943 5.943 0 01-1.744-1.004.728.728 0 01-.204-.872 1.56 1.56 0 00-1.272-2.204.74.74 0 01-.652-.62 5.727 5.727 0 01.036-2.06.74.74 0 01.648-.596 1.56 1.56 0 001.3-2.144.744.744 0 01.232-.852 5.928 5.928 0 011.64-.92.74.74 0 01.836.232 1.565 1.565 0 002.464 0 .74.74 0 01.836-.232 5.9 5.9 0 011.76 1.02.736.736 0 01.22.828 1.568 1.568 0 00.584 1.868zm-2.97 3.027a2 2 0 10-3.325-2.222 2 2 0 003.326 2.223z"
                fill="#A3B0BF"
            />
        </svg>
    );
}

const MemoMenuRoles = React.memo(MenuRoles);
export default MemoMenuRoles;
