import Card from 'antd/lib/card';
import moment from 'moment';
import { Button, Spin } from 'antd';
import { getBlockedUsersService, unblockBlockedUsersService } from '../../../_services/blocked_users/BlockedUsersServices';
import { getUserDetails } from '../../../_services/clients/ClientsService';
import { loading_icon } from '../../../_common/components/loading/LoadingIcon';
import { openErrorNotification } from '../../../_heplers/NotificationHelper';
import { Row } from 'antd/lib/grid';
import { useEffect, useState } from 'react';

export const UserTab = (props: any) => {
  const [profile, setProfile] = useState<any>();
  const [loading, setLoading] = useState<any>(false);

  const { id } = props;

  const show_kyc_button = {
    width: 154,
    height: 50,
    borderRadius: 8,
    marginTop: 74
  };

  const common_row_style = {
    border: '1px solid black',
    borderRadius: '14px',
    width: 413,
    height: 52,
    padding: 14
  }

  const common_div_1_style = { marginBottom: 15, fontSize: 16 };
  const margin_bottom_5 = { marginBottom: 5 };
  const button_style = { fontSize: 16, fontWeight: 700 };

  useEffect(() => {
    setLoading(true);

    getUserDetails(id)
      .then((response: any) => {
        if (!response.user) {
          openErrorNotification(response.message);
        }
        else {
          setProfile(response);
        }
      })
      .finally(() => setLoading(false));
  }, []);

  const changeUser = () => {
    setLoading(true);

    if (profile.user.disabled) {
      unblockBlockedUsersService(profile.user.id);
    }
    else {
      getBlockedUsersService(profile.user.id);
    }

    getUserDetails(id)
      .then((response: any) => setProfile(response))
      .finally(() => setLoading(false));
  }

  return (
    <Spin className='spin_60' spinning={loading} indicator={loading_icon(40)}>
      <Card style={{ borderRadius: '14px' }}>
        <Row>
          <div style={{ marginRight: 32 }}>
            <div style={common_div_1_style}>
              <div style={margin_bottom_5}>First Name</div>
              <div
                style={common_row_style}
              >
                {profile && profile.user.name ? profile.user.name : '-'}
              </div>
            </div>
            <div style={common_div_1_style}>
              <div style={margin_bottom_5}>Last Name</div>
              <div
                style={common_row_style}
              >
                {profile && profile.user.surname ? profile.user.surname : '-'}
              </div>
            </div>
            <div style={common_div_1_style}>
              <div style={margin_bottom_5}>Email address</div>
              <div
                style={common_row_style}
              >
                {profile && profile.user.email ? profile.user.email : '-'}
              </div>
            </div>
            <div style={{ marginBottom: 10, fontSize: 16 }}>
              <div style={margin_bottom_5}>Address</div>
              <div
                style={common_row_style}
              >
                {profile && profile.user.address ? profile.user.address : '-'}
              </div>
            </div>
          </div>
          <div>
            <div style={common_div_1_style}>
              <div style={margin_bottom_5}>Country</div>
              <div
                style={common_row_style}
              >
                {profile && profile.user.country ? profile.user.country : '-'}
              </div>
            </div>
            <div style={common_div_1_style}>
              <div style={margin_bottom_5}>Date of birth</div>
              <div
                style={common_row_style}
              >
                {profile && profile.user.dob
                  ? moment(profile.user.dob).format('DD MMM, YYYY')
                  : '-'}
              </div>
            </div>
            <div style={common_div_1_style}>
              <div style={margin_bottom_5}>Phone Number</div>
              <div
                style={common_row_style}
              >
                {profile && profile.user.phoneNumber
                  ? profile.user.phoneNumber
                  : '-'}
              </div>
            </div>
            <div style={{ marginBottom: 10, fontSize: 16 }}>
              <div style={margin_bottom_5}>UUID</div>
              <div
                style={common_row_style}
              >
                {profile && profile.user.userUUid ? profile.user.userUUid : '-'}
              </div>
            </div>
          </div>
        </Row>
        <Button type="primary" style={show_kyc_button} onClick={() => changeUser()}>
          {profile && (<div style={button_style}>{profile.user.disabled ? "Unblock" : "Block"}</div>)}
        </Button>
      </Card>
    </Spin>
  );
};
