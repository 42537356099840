import * as React from 'react';

function MenuTransactionHistory(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.5 12c0 5.799-4.701 10.5-10.5 10.5S1.5 17.799 1.5 12 6.201 1.5 12 1.5 22.5 6.201 22.5 12zM12.312 6.812c-2.86 0-5.226 2.124-5.622 4.875H5l2.438 2.438 2.437-2.438H8.332a4.07 4.07 0 013.98-3.25 4.067 4.067 0 014.063 4.063 4.067 4.067 0 01-4.063 4.063 4.077 4.077 0 01-3.299-1.691l-1.318.949a5.705 5.705 0 004.617 2.367A5.694 5.694 0 0018 12.5a5.694 5.694 0 00-5.688-5.688zm-.812 6.554v-3.303h1.625v2.383l1.637.982-.836 1.394-2.426-1.455z"
                fill="#A3B0BF"
            />
        </svg>
    );
}

const MemoMenuTransactionHistory = React.memo(MenuTransactionHistory);
export default MemoMenuTransactionHistory;
