export const setToken = (value: string): void => {
    localStorage.setItem('jwt', value);
}

export const getToken = (): string | null => {
    const jwt = localStorage.getItem('jwt');

    return jwt === 'undefined' ? null : jwt;
}

export const clearLocalStorage = () => {
    localStorage.clear();
}