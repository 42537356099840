import axios from '../api';
import {createAbsoluteUrl} from '../../_heplers/UrlCreatorHelpres';

const url = createAbsoluteUrl('api/SiteApi');

export const getMobilumPartnersService = () =>
  axios.get(`${url}/SiteWizard`)
    .then(response => response.data)
    .catch(error => {
      console.log('--------------- getMobilumPartnersService', error.message);

      return [];
    });

export const getMobilumPartnerDetailsService = (id: string) =>
  axios.get(`${url}/GetSite?siteId=${id}`)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.log('--------------- getMobilumPartnerDetailsService', error.message);

      return [];
    });

export const getEmptySiteModelService = () =>
  axios.get(`${url}/GetEmptySiteModel`)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.log('--------------- getEmptySiteModelService', error.message);

      return undefined;
    });

export const createMobilumPartnerService = (body: any) =>
  axios.post(`${url}/CreateSite`, body)

export const updateMobilumPartnerService = (body: any) =>
  axios.post(`${url}/Edit`, body)

export const setSiteActiveService = (siteId: string, disabled: boolean) =>
  axios.get(`${url}/SetSiteActive`, { params: { siteId, disabled } })
    .then(response => response.data.Success)
    .catch(error => {
      console.log('--------------- SetSiteActive', error.message);

      return false;
    });

export const setSiteArchiveService = (siteId: string, archived: boolean) =>
  axios
    .get(`${url}/SetSiteArchived`, { params: { siteId, archived } })
    .then((response) => response.data.Success)
    .catch((error) => {
      console.log('--------------- SetSiteArchived', error.message);

      return false;
    });