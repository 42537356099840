import axios from '../api';
import {createAbsoluteUrl} from '../../_heplers/UrlCreatorHelpres';
import { ICreateSiteTierModel } from '../../views/mobilum_partners/details/interfaces/ICreateSiteTierModel';
import { IUpdateSiteTierModel } from '../../views/mobilum_partners/details/interfaces/IUpdateSiteTierModel';


const url = 'api/SiteTierApi';

export const getAll = async (siteUuid: string) =>
  await axios.get(`${url}/GetAll?siteUuid=${siteUuid}`)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.log('--------------- api/SiteTierApi/getAll', error.message);
      return [];
    });

export const create = async (payload: ICreateSiteTierModel) =>
  await axios.post(`${url}/Create`, payload);

export const deleteSiteTier = async (siteTierId: number) =>
  await axios.delete(`${url}/Delete?siteTierId=${siteTierId}`);

export const update = async (payload: IUpdateSiteTierModel) =>
  await axios.put(`${url}/Update`, payload);