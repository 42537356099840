import axios from '../api';
import {createAbsoluteUrl, createApiUrl} from '../../_heplers/UrlCreatorHelpres';

const url = createAbsoluteUrl('api/FeeConfigApi');

export const getFeeConfigsService = () =>
  axios.get(`${url}/GetAll`)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.log('--------------- getFeeConfigsService', error.message);

      return [];
    });

export const createFeeConfigService = (body: any) =>
  axios.post(`${url}/Create`, body)
    .then(() => true)
    .catch(error => {
      console.log('--------------- createFeeConfigService', error.message);

      return false;
    });

export const updateFeeConfigService = (id: number, body: any) =>
  axios.put(`${url}/UpdateFeeConfig/${id}`, body)
    .then(() => true)
    .catch(error => {
      console.log('--------------- updateFeeConfigService', error.message);

      return false;
    });

export const deleteFeeConfigService = (id: number) =>
  axios.delete(`${url}/DeleteFeeConfig/${id}`)
    .then(() => true)
    .catch(error => {
      console.log('--------------- deleteFeeConfigService', error.message);

      return false;
    });