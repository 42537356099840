const url = '';
const api_url = `${process.env.REACT_APP_BASE_URL}`;

export const createAbsoluteUrl = (relative: string): string => {
    return `${url}/${relative}`;
}

export const createApiUrl = (relative: string): string => {
    return `${api_url}${relative}`;
}
