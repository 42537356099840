import { LoginLogoComponent } from "../../_common/components/login_logo/LoginLogoComponent";
import SetPasswordContent from "./content/SetPasswordContent";

const ResetPasswordLinkView = () => {
    return (
        <>
        <SetPasswordContent />
        <LoginLogoComponent />
        </>
    )
}

export default ResetPasswordLinkView;