import Card from 'antd/lib/card';
import Column from 'antd/lib/table/Column';
import moment from 'moment';
import styles from '../../notifications/styles/Notifications.module.scss';
import { _default_page_size, _hide_when_less_than, _page_size_options } from '../../../_heplers/GlobalValues';
import { AppContext } from '../../../_common/app_context_provider/AppContextProvider';
import {
  Button,
  Modal,
  Spin,
  Switch,
  Table,
  Tooltip
  } from 'antd';
import { getKycData, getUserDetails, setKycVerifiedService } from '../../../_services/clients/ClientsService';
import { loading_icon } from '../../../_common/components/loading/LoadingIcon';
import { openErrorNotification, openSuccessNotification } from '../../../_heplers/NotificationHelper';
import { Row } from 'antd/lib/grid';
import { useContext, useEffect, useState } from 'react';
import {
  QuestionCircleOutlined
} from '@ant-design/icons';

export const KycDetails = (props: any) => {

  //#region ---- Styles ----
  const show_kyc_button = {
    width: 224,
    height: 50,
    borderRadius: 8,
    marginTop: 74
  };
  const common_div_1_style = { marginBottom: 15, fontSize: 16 };
  const common_margin_bottom = { marginBottom: 5 };
  const common_border_style = '1px solid black';
  const common_border_radius_style = '14px';
  const button_style = { fontSize: 16, fontWeight: 700 };
  const common_div = {
    border: common_border_style,
    borderRadius: common_border_radius_style,
    width: 413,
    height: 52,
    padding: 14
  };

  //#endregion ---- Styles ----

  const [profile, setProfile] = useState<any>();
  const [data, setData] = useState<any>([]);
  const [showModal, setModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [pageSize, setPageSize] = useState(_default_page_size);
  const [disabled_switch, setDisabledSwitch] = useState<any>(false);

  const { id } = props;

  const validatePermission = useContext(AppContext).validatePermission;

  useEffect(() => {
    getUserDetails(id)
      .then((response: any) => response.user ? setProfile(response) : openErrorNotification(response.message))
      .catch(e => console.log('----- error', e.message))
      .finally(() => setLoading(false));
  }, []);

  const openModal = async () => {
    setData(await getKycData(id).finally(() => setLoading(false)));
    setModal(true);
  }

  const changeKYCStatus = () => {
    setDisabledSwitch(true);

    let _profile = profile;

    setKycVerifiedService({
      userUUID: _profile.user.userUUid,
      value: _profile.user.kycVerified
    })
      .then((response: any) => {
        if (response && response.success) {
          openSuccessNotification('KYC Status updated');
          _profile.user.kycVerified = !_profile.user.kycVerified;
          setProfile(_profile);
        }
        else {
          openErrorNotification("Couldn't update");
        }
      })
      .finally(() => setDisabledSwitch(false));
  }

  return (
    <Spin className='spin_60' spinning={loading} indicator={loading_icon(40)}>
      <Card style={{ borderRadius: '14px' }}>
        <Row>
          <div style={{ marginRight: 32 }}>
            <div style={common_div_1_style}>
              <div style={common_margin_bottom}>KYC Result</div>
              <div
                style={{
                  border: common_border_style,
                  borderRadius: common_border_radius_style,
                  width: 413,
                  height: 52,
                  padding: 14,
                  color:
                    profile && profile.user && profile.user?.kycComplete
                      ? profile.user?.kycVerified
                        ? '#0BB07E'
                        : '#ff5252'
                      : undefined,
                }}
              >
                {profile && profile.user && profile.user?.kycComplete
                  ? profile.user?.kycVerified
                    ? 'Passed'
                    : 'Failed'
                  : 'Pending'}
              </div>
            </div>
            <div style={common_div_1_style}>
              <div style={common_margin_bottom}>KYC Provider</div>
              <div style={common_div}>
                {profile && profile.kycData && profile.kycData?.provider
                  ? profile.kycData?.provider
                  : '-'}
              </div>
            </div>
            <div style={common_div_1_style}>
              <div style={common_margin_bottom}>Email address</div>
              <div style={common_div}>
                {profile && profile.user.email ? profile.user?.email : '-'}
              </div>
            </div>
            <div style={common_div_1_style}>
              <div style={common_margin_bottom}>Expiry Date</div>
              <div style={common_div}>
                {profile && profile.user?.kycExpirationDate ? moment(profile.user?.kycExpirationDate).format('DD MMM, YYYY') : '-'}
              </div>
            </div>
          </div>
          <div style={{ marginRight: 32 }}>
            <div style={common_div_1_style}>
              <div style={common_margin_bottom}>First Name</div>
              <div style={common_div}>
                {profile && profile.user?.name ? profile.user?.name : '-'}
              </div>
            </div>
            <div style={common_div_1_style}>
              <div style={common_margin_bottom}>Last Name</div>
              <div style={common_div}>
                {profile && profile.user?.surname ? profile.user?.surname : '-'}
              </div>
            </div>
            <div style={common_div_1_style}>
              <div style={common_margin_bottom}>Date of birth</div>
              <div style={common_div}>
                {profile && profile.user?.dob
                  ? moment(profile.user?.dob).format('DD MMM, YYYY')
                  : '-'}
              </div>
            </div>
          </div>
        </Row>
        {validatePermission('SetKycStatus') && (
          <div style={{ ...common_div_1_style, marginTop: 10 }}>
            <Tooltip placement='top' arrowPointAtCenter title={profile && profile.user.kycVerified ? 'Switch KYC Status to Failed' : 'Switch KYC Status to Success'}> <QuestionCircleOutlined style={{ color: '#6D6D6D' }} /> </Tooltip>
            <label style={{ marginRight: 10 }} >KYC Status</label>
            <Switch disabled={disabled_switch || !profile} checked={profile && profile.user.kycVerified || false} onChange={() => changeKYCStatus()} />
          </div>
        )}
        {validatePermission('ViewKycHistory') && (
          <Button
            type="primary"
            style={show_kyc_button}
            onClick={() => openModal()}
          >
            <div style={button_style}>View KYC Data</div>
          </Button>
        )}
      </Card>
      <Modal
        title={undefined}
        visible={showModal}
        closable
        footer={false}
        width={'100%'}
        destroyOnClose
        onCancel={() => setModal(false)}
      >
        <div className={styles.modal_content}>
          <div
            className={styles._modal_title}
            style={{ marginBottom: '-15px' }}
          >
            {'KYC Data'}
          </div>
          <Spin
            className="spin_60"
            spinning={loading}
            indicator={loading_icon(40)}
          >
            <Table
              scroll={{ y: 600 }}
              className="transaction-table"
              dataSource={data}
              bordered
              size="middle"
              style={{ marginTop: '40px', borderRadius: '20px' }}
              expandable={{
                defaultExpandAllRows: true,
                expandedRowRender: (record) => (
                  <p style={{ margin: 0 }}>{JSON.stringify(record.data).replace(/[\\]/g, "")}</p>
                ),
                rowExpandable: (record) => true,
                showExpandColumn: false,
              }}
              pagination={{
                pageSize: pageSize,
                onChange: (e: number, b: number) => setPageSize(b),
                showSizeChanger: true,
                pageSizeOptions: _page_size_options,
                hideOnSinglePage: data.length < _hide_when_less_than
              }}
            >
              <Column
                title="Created Date"
                key="createdAt"
                width={150}
                render={(record: any) =>
                  moment(JSON.parse(record.data).createdAt).format('DD MMM YY')
                }
              />
              <Column
                title="Review Answer"
                key="reviewAnswer"
                width={150}
                render={(record: any) =>
                  JSON.parse(record.data).reviewResult &&
                    JSON.parse(record.data).reviewResult.reviewAnswer
                    ? JSON.parse(record.data).reviewResult.reviewAnswer
                    : '-'
                }
              />
              <Column
                title="Reject Type"
                key="rejectType"
                width={150}
                render={(record: any) =>
                  JSON.parse(record.data).reviewResult &&
                    JSON.parse(record.data).reviewResult.reviewRejectType
                    ? JSON.parse(record.data).reviewResult.reviewRejectType
                    : '-'
                }
              />
              <Column
                title="Moderation Comment"
                key="moderationComment"
                width={150}
                render={(record: any) =>
                  JSON.parse(record.data).reviewResult &&
                    JSON.parse(record.data).reviewResult.moderationComment
                    ? JSON.parse(record.data).reviewResult.moderationComment
                    : '-'
                }
              />
              <Column
                title="Client Comment"
                key="clientComment"
                width={150}
                render={(record: any) =>
                  JSON.parse(record.data).reviewResult &&
                    JSON.parse(record.data).reviewResult.clientComment
                    ? JSON.parse(record.data).reviewResult.clientComment
                    : '-'
                }
              />
            </Table>
          </Spin>
        </div>
      </Modal>
    </Spin>
  );
};
