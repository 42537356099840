import axios from '../api';

export const getUserDetails = (userUuid: string) =>
  axios
    .get('api/ClientApi/GetClientDetails', { params: { userUuid } })
    .then((response) => response.data)
    .catch((error) => {
      console.log('--------------- getClientDetails', error.message);
      return undefined;
    });

export const getUsersServices = (startRow: number, rows?: number, searchName?: string, searchSite?: string) =>
  axios
    .get(`api/ClientApi/Index`, { params: { searchName, startRow, rows, searchSite } })
    .then((response) => response.data)
    .catch((error) => {
      console.log('--------------- getUsersServices', error.message);

      return [];
    });

export const resetKyc = async (userId: string) =>
  await axios
      .get(`api/ClientApi/ResetKycAttempt`, { params: { userId } })
      .then((response) => response.data)
      .catch((error) => {
        console.log('--------------- resetKycServices', error.message);
        return [];
      });

export const getKycData = (userUuid: string) =>
  axios
    .get(`api/ClientApi/KycStatus/${userUuid}`)
    .then((response) => {
      console.log("RESP", response.data.history)
      return response.data.history;
    })
    .catch((error) => {
      console.log('--------------- getKycDataServices', error.message);

      return [];
    });

export const updateUserRiskLevel = (userUUID: string, value: number) =>
  axios
    .post(`api/ClientApi/UpdateUserRiskLevel?userUUID=${userUUID}&value=${value}`)
    .then(() => true)
    .catch((error) => {
      console.log('--------------- updateUserRiskLevel', error.message);

      return false;
    });

export interface IsetKycVerifiedService {
  userUUID: string;
  value: number;
}

export const setKycVerifiedService = (params: IsetKycVerifiedService) =>
  axios
    .get('api/ClientApi/SetKycVerified', { params })
    .then((response: any) => response.data)
    .catch((error) => {
      console.log('--------------- setKycVerifiedService', error.message);

      return false;
    });

