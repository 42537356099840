import axios from '../api';

const url = 'api/RoleApi';

export const getRolesService = () =>
  axios.get(`${url}/GetRoles`)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.log('--------------- getRolesServices', error.message);

      return [];
    });
export const toggleRolesService = (id: number, isActive: any) =>
  axios.get(`${url}/SetRoleActive/${id}?isActive=${isActive}`)
  
export const updateRolesService = (id: number, body: any) =>
  axios.put(`${url}/UpdateRole/${id}`, body)

export const createRolesService = ( body: any) =>
  axios.post(`${url}/CreateRole`, body)