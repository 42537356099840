import React from 'react';
import { Button } from 'antd';
import { CodeForm } from '../forms/CodeForm';
import { createAbsoluteUrl } from '../../../_heplers/UrlCreatorHelpres';
import { EmailForm } from '../forms/EmailForm';
import { Link } from 'react-router-dom';
import { LoginLogoComponent } from '../../../_common/components/login_logo/LoginLogoComponent';
import { navPath } from '../../../_common/NavPath';
import { SetNewPasswordForm } from '../forms/SetNewPasswordForm';

export type content_types = 'email_form' | 'code_form' | 'set_new_password_form'

export class ForgotPasswordContent extends React.Component<any>{
    _is_mounted = true;

    state = {
        content: 'email_form',
        code: undefined,
        email: undefined
    };

    componentWillUnmount = () => {
        this._is_mounted = false;
    }

    public render() {
        return (
            <>
                {this.displayContent()}
                <LoginLogoComponent />
            </>
        );
    }

    displayContent = () => {
        const { content, code, email } = this.state;
        switch (content) {
            case 'email_form': {
                return <EmailForm changeContent={this.changeContent} setEmail={this.setEmail} />
            }
            case 'code_form': {
                return <CodeForm changeContent={this.changeContent} email={email} setCode={this.setCode} />
            }
            case 'set_new_password_form': {
                return <SetNewPasswordForm code={code!} changeContent={this.changeContent} navigate={this.props.navigate} />
            }
            default: {
                return this.renderSuccess();
            }
        }
    }

    changeContent = (content: content_types) => {
        this._is_mounted && this.setState({ content });
    }

    setCode = (code: string) => {
        this._is_mounted && this.setState({ code });
    }

    renderSuccess = () => {
        return <div className='common_form_1' style={{ textAlign: 'center', paddingTop: 250 }}>
            <div style={{ fontSize: 30 }}>Success</div>
            <Link to={createAbsoluteUrl(navPath.login)}>
                <Button type="primary" key="console">
                    Login
                </Button>
            </Link>
        </div>
    }

    setEmail = (email: string) => {
        this._is_mounted && this.setState({ email });
    }
}