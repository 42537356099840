import { _default_page_size, _hide_when_less_than, _page_size_options } from '../../../../_heplers/GlobalValues';
import {
    Button,
    Switch,
    Table,
    Tooltip
    } from 'antd';
import { useState } from 'react';

export const ConfigurationCoinsAndTokensTable = (props: any) => {
    const [pageSize, setPageSize] = useState(_default_page_size);

    const {
        data,
        changeAccountStatus,
        updateItem
    } = props;

    const columns: any[] = [
        {
            title: 'Cryptocurrency',
            dataIndex: 'name',
            key: 'name',
            ellipsis: true,
        },
        {
            title: 'Ticker',
            dataIndex: 'ticker',
            key: 'ticker',
            ellipsis: true,
        },
        {
            title: 'Is Active',
            key: 'isAvailable',
            render: (record: any) => (
                <Switch checked={record.isAvailable} onChange={(e) => changeAccountStatus(record.id, e)} />
            )
        },
        /*{
            title: 'Actions',
            key: 'actions',
            fixed: 'right',
            width: 150,
            render: (record: any) => (
                <>
                    <Tooltip placement='rightTop' title='Edit'>
                        <Button
                            size='small'
                            type='link'
                            onClick={() => updateItem(record)}
                        >
                            Edit
                        </Button>
                    </Tooltip>
                </>
            )
        }*/
    ];

    return (
        <Table
            columns={columns}
            bordered
            dataSource={data}
            size='small'
            rowKey="id"
            pagination={{
                pageSize: pageSize,
                onChange: (e: number, b: number) => setPageSize(b),
                showSizeChanger: true,
                pageSizeOptions: _page_size_options,
                hideOnSinglePage: data.length < _hide_when_less_than
            }}
        />
    )
}
