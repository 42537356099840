import React from 'react';
import { PageTitleComponent } from '../../../_common/components/page_title/PageTitleComponent';
import { ResetPasswordForm } from '../forms/ResetPasswordForm';

export class ResetPasswordContent extends React.Component<any> {
    public render() {
        return (
            <>
                <PageTitleComponent page_title='Reset Password' />
                <ResetPasswordForm context={this.props.context} navigate={this.props.navigate} />
            </>
        );
    }
}
