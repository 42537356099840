import axios from '../api';

export const getProfileServices = () =>
  axios.get('api/UserApi/Profile')
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.log('--------------- getProfileServices', error.message);
      
      return {};
    });