import React from 'react';
import styles from '../styles/ConfigurationCoinsAndTokens.module.scss';
import { AddNewButtonComponent } from '../../../../_common/components/add_new_button/AddNewButtonComponent';
import { ClearBoth } from '../../../../_common/components/clearBoth/ClearBoth';
import { ConfigurationCoinsAndTokensForm } from '../forms/ConfigurationCoinsAndTokensForm';
import { ConfigurationCoinsAndTokensTable } from '../tables/ConfigurationCoinsAndTokensTable';
import { getAvailableTokensServices, createAvailableTokensServices, setActiveToken, getAvailableToken, updateAvailableTokensServices } from '../../../../_services/availableTokens/AvailableTokensServices';
import { Modal, Row, Spin } from 'antd';
import { Left } from '../../../../_common/components/left/Left';
import { loading_icon } from '../../../../_common/components/loading/LoadingIcon';
import { PageTitleComponent } from '../../../../_common/components/page_title/PageTitleComponent';
import { Right } from '../../../../_common/components/right/Right';
import { openSuccessNotification } from '../../../../_heplers/NotificationHelper';
import SearchElements from '../../../../_common/components/searchElements/SearchElements';

export class ConfigurationCoinsAndTokensContent extends React.Component<any> {
    _is_mounted = true;

    state = {
        loading: true,
        coins_and_tokens: [] as any[],
        // Modal
        show_modal: false,
        modal_data: {} as any,
        search: '',
    };

    componentDidMount = () => {
        this.getCoinsAndTokens();
    }

    componentWillUnmount = () => {
        this._is_mounted = false;
    }
    onGlobalSearch = (event: any) => {
        this.setState({ [event.target.name]: event.target.value.toLocaleLowerCase() });
    }

    public render() {
        const {
            loading,
            coins_and_tokens,
            search,
            // Modal
            show_modal,
            modal_data
        } = this.state;
        let filtered_coins = coins_and_tokens?.filter((coin: any) => coin.name?.toLowerCase().includes(search!) ||
            coin.ticker?.toLowerCase().includes(search!))
        return (
            <>
                <Row align='middle' justify='space-between'>
                    <Left>
                        <PageTitleComponent page_title='Coins & Tokens' />
                    </Left>
                    <Right>
                        {/*<AddNewButtonComponent*/}
                        {/*    title="Add New Cryptocurrency"*/}
                        {/*    onClick={() => this.setModalVisible(true)}*/}
                        {/*/>*/}
                    </Right>
                </Row>
                <ClearBoth />
                <SearchElements placeholder="Search by crypto or ticker" onSearch={this.onGlobalSearch} showIcon />
                <Spin className='spin_60' spinning={loading} indicator={loading_icon(40)}>
                    <ConfigurationCoinsAndTokensTable
                        data={filtered_coins}
                        changeAccountStatus={this.changeAccountStatus}
                        updateItem={this.updateItem}
                    />
                </Spin>
                <Modal
                    title={this.state.modal_data?.id ? 'Edit Cryptocurrency' : 'Add New Cryptocurrency'}
                    visible={show_modal}
                    closable={false}
                    footer={false}
                    width={500}
                    destroyOnClose
                >
                    <div className={styles.modal_content}>
                        <ConfigurationCoinsAndTokensForm
                            context={this.props.context}
                            data={modal_data}
                            setModalVisible={this.setModalVisible}
                            addOrUpdateObject={this.addOrUpdateObject}
                        />
                    </div>
                </Modal>
            </>
        );
    }
    addOrUpdateObject = async (cryptocurrency: any) => {
        const itemExists = !!cryptocurrency.id;
        let coins_and_tokens = [...this.state.coins_and_tokens];
        itemExists ?
            await updateAvailableTokensServices(cryptocurrency).then((response) => {
                const index = coins_and_tokens.findIndex((x: any) => x.id === cryptocurrency.id);
                coins_and_tokens[index] = response;
                openSuccessNotification(`${cryptocurrency.name} was succesfully updated!`)
            })
            : await createAvailableTokensServices(cryptocurrency).then((response) => {
                coins_and_tokens = [response, ...this.state.coins_and_tokens]
                openSuccessNotification(`${cryptocurrency.name} was succesfully created!`)
            })
        this._is_mounted && this.setState({
            coins_and_tokens,
            modal_data: {},
            show_modal: false
        })
    }
    updateItem = async (item: any) => {
        await getAvailableToken(item.id).then((response) => {
            this.setModalVisible(true)
            this.setState({ modal_data: response.data })
        })
    }

    setModalVisible = (show_modal: boolean) => {
        this.setState({ show_modal, modal_data: {} });
    }

    getCoinsAndTokens = async () => {
        this.setState({ loading: true });
        await getAvailableTokensServices()
            .then(response => {
                this.setState({
                    coins_and_tokens: response.data
                });
            })
            .finally(() => this._is_mounted && this.setState({ loading: false }));
    }

    addNewCryprocurrency = () => {
        this.setState({
            modal_data: {},
            show_modal: true
        });
    }

    changeAccountStatus = async (id_account: any, isActive: boolean) => {
        let { coins_and_tokens } = this.state;
        const index = coins_and_tokens.findIndex((x: any) => x.id === id_account);
        await setActiveToken({ id_account, isActive }).then(() => {
            coins_and_tokens[index].isAvailable = !coins_and_tokens[index].isAvailable;
            this.setState({ coins_and_tokens });
            openSuccessNotification(`${coins_and_tokens[index].name} status updated!`)
        }).catch(() => {
            coins_and_tokens[index].isAvailable = !isActive
            this.setState({ coins_and_tokens });
        })
    }

    updateObjectInArray = (role: any) => {
        let { coins_and_tokens } = this.state;

        const index = coins_and_tokens.findIndex((x: any) => x.id === role.id);

        coins_and_tokens[index].is_active = role.is_active;
        coins_and_tokens[index].name = role.name;

        this._is_mounted && this.setState({
            coins_and_tokens,
            modal_data: {},
            show_modal: false
        });
    }
}