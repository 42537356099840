import moment from 'moment';

const _request_date_format = 'YYYY/MM/DD HH:mm:ss';

export const convertNumberToDateTimeString = (
    props: {
        number: number;
        timezone?: number;
        request_date_format?: string;
    }
): string => {
    const { number, timezone, request_date_format } = props;

    if (number === null || number === 0 || number === undefined) {
        return '-';
    }
    else {
        return moment(new Date(number)).utcOffset(timezone ? timezone : 0).format(request_date_format ? request_date_format : _request_date_format);
    }
}