import { useContext } from 'react';
import { AppContext } from '../../../_common/app_context_provider/AppContextProvider';
import { ConfigurationRolesContent } from './content/ConfigurationRolesContent';

const ConfigurationRolesView = () => {
    const context = useContext(AppContext)
    return (
        <ConfigurationRolesContent context={context}/>
    )
}

export default ConfigurationRolesView;