import { Breadcrumb, Spin } from "antd"
import { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { loading_icon } from "../../../_common/components/loading/LoadingIcon"
import { PageTitleComponent } from "../../../_common/components/page_title/PageTitleComponent"
import { navPath } from "../../../_common/NavPath"
import { openErrorNotification, openSuccessNotification } from "../../../_heplers/NotificationHelper"
import { createAbsoluteUrl } from "../../../_heplers/UrlCreatorHelpres"
import { getFraudRules, toggleFraudRule } from "../../../_services/antifraud/AntiFraudService"
import AntiFraudTable from "../AntiFraudTable"

export interface FraudRuleModel {
  isActive: boolean;
  limitType: {
    id: number;
    name: string
  }
};
const AntiFraudContent = () => {
  const [loading, setLoading] = useState(false);
  const { id, name }: any = useParams();
  const [fraudRules, setFraudRules] = useState([] as FraudRuleModel[]);

  const changeStatus = (item: any) => {
    toggleFraudRule(id, item.limitType.id).then((response) => {
      if (!response.errors) {
        const data = [...fraudRules];
        const index = data.findIndex((x: any) => x.limitType.id === item.limitType.id) || 0;
        data[index].isActive = !data[index].isActive;
        setFraudRules(data);
        openSuccessNotification(`${item.limitType.name} status updated!`);
      }
    })
  }
  const getFraudData = () => {
    setLoading(true);
    getFraudRules(id).then((response) => {
    if(response.errors) {
      response.errors.map((error: string) => {
        return openErrorNotification(error)
      })
    } else setFraudRules(response)
  }).finally(() => setLoading(false))
  }
  useEffect(() => {
    getFraudData();
  }, [])
  
  return (
    <>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>
            <Link to={createAbsoluteUrl(`${navPath.mobilum_partners}`)}>Fraud Protection Filters</Link>
            </Breadcrumb.Item>
        <Breadcrumb.Item>
            {name}
        </Breadcrumb.Item>
      </Breadcrumb>
      <PageTitleComponent page_title={name} />
      <Spin className='spin_60' spinning={loading} indicator={loading_icon(40)}>
          <AntiFraudTable data={fraudRules} changeStatus={(item: any) => changeStatus(item)} redirectToDetails={(item: any) => createAbsoluteUrl(
               `${navPath.anti_fraud}/${id}/${name}/${item.limitType.id}`
              )} />
      </Spin>
    </>
  )
}
export default AntiFraudContent;