import moment from 'moment';
import { _default_page_size, _hide_when_less_than, _page_size_options } from '../../../_heplers/GlobalValues';
import { loading_icon } from '../../../_common/components/loading/LoadingIcon';
import { memo, useEffect, useState } from 'react';
import { Spin, Table } from 'antd';

export const WiredTableData = (props: any) => {
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pageSize, setPageSize] = useState(_default_page_size);

    useEffect(() => {
        setList(props.list);
        setLoading(props.loading);
    }, [props]);
    const displayValue = (value?: string | number | null) => value ? value.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '-';


    const columns: any[] = [
        {
            title: 'Settlement Date',
            dataIndex: 'settlementDate',
            key: 'settlementDate',
            width: 100,
            render: (record: any) => record ? moment(record).format('MM/DD/YYYY') : '-',
            sorter: (a: any, b: any, sortOrder: string) => {
                if(a.settlementDate && b.settlementDate) {
                    return a.settlementDate?.localeCompare(b.settlementDate);
                } else if(a && a.settlementDate) {
                    return sortOrder === 'ascend' ? -1 : 1;
                } else if(b && b.settlementDate) {
                    return sortOrder === 'ascend' ? 1 : -1;
                }
                return 0;
            }
        },
        {
            title: 'Transaction UUID',
            dataIndex: 'transactionUUID',
            key: 'transactionUUID',
            sorter: (a: any, b: any) => (a.transactionUUID ? a.transactionUUID : "").localeCompare(b.transactionUUID || ''),
            width: 200,
        },
        {
            title: 'Transaction',
            dataIndex: 'transactionType',
            key: 'transactionType',
            width: 120,
            sorter: (a: any, b: any) => (a.transactionType ? a.transactionType : "").localeCompare(b.transactionType || ''),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 120,
            sorter: (a: any, b: any) => (a.status ? a.status : "").localeCompare(b.status || ''),
        },
        {
            title: 'User Id',
            dataIndex: 'userUUID',
            key: 'userUUID',
            width: 200,
            sorter: (a: any, b: any) => (a.userUUID ? a.userUUID : "").localeCompare(b.userUUID || ''),
        },
        {
            title: 'From Account',
            dataIndex: 'fromAccount',
            key: 'fromAccount',
            width: 180,
            sorter: (a: any, b: any) => (a.fromAccount ? a.fromAccount : "").localeCompare(b.fromAccount || ''),
        },
        {
            title: 'To Account',
            dataIndex: 'toAccount',
            key: 'toAccount',
            width: 180,
            sorter: (a: any, b: any) => (a.toAccount ? a.toAccount : "").localeCompare(b.toAccount || ''),
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            width: 100,
            sorter: (a: any, b: any) => a.amount - b.amount,
            render: (amount: any) => amount ? displayValue(amount) : 0
        },
        {
            title: 'Currency',
            dataIndex: 'currency',
            key: 'currency',
            width: 100,
            sorter: (a: any, b: any) => a.currency - b.currency
        },
        {
            title: 'Date Created',
            dataIndex: 'dateCreated',
            key: 'dateCreated',
            render: (record: any) => record ? moment(record).format('MM/DD/YYYY') : '-',
            sorter: (a: any, b: any) => a.dateCreated?.localeCompare(b.dateCreated || ''),
            width: 100
        }
    ];
    return (
        <Spin className="spin_60" spinning={loading} indicator={loading_icon(40)}>
            <Table
                scroll={{ x: 'max-content' }}
                bordered
                rowKey="id"
                size='small'
                className="transaction-table"
                columns={columns}
                dataSource={list}
                pagination={{
                    pageSize: pageSize,
                    onChange: (e: number, b: number) => setPageSize(b),
                    showSizeChanger: true,
                    pageSizeOptions: _page_size_options,
                    hideOnSinglePage: list.length < _hide_when_less_than
                }}
            />
        </Spin>
    )
}
export default memo(WiredTableData)
