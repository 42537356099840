import Column from 'antd/lib/table/Column';
import moment from 'moment';
import { _hide_when_less_than, _page_size_options } from '../../../_heplers/GlobalValues';
import { AppContext } from '../../../_common/app_context_provider/AppContextProvider';
import {
  Space,
  Spin,
  Table
  } from 'antd';
import { createAbsoluteUrl } from '../../../_heplers/UrlCreatorHelpres';
import { Link } from 'react-router-dom';
import { loading_icon } from '../../../_common/components/loading/LoadingIcon';
import { navPath } from '../../../_common/NavPath';
import { useContext } from 'react';
import { formatOrderStatus } from '../../../_heplers/OrderStatusHelper';

export const TransactionTable = (props: any) => {
  const {loading, list, currentPage, total, pageSize, onChangePage} = props;
  const context = useContext(AppContext)
  const validatePermission = context.validatePermission;

  return (
    <>
      <Spin className="spin_60" spinning={loading} indicator={loading_icon(40)}>
        <Table
          scroll={{ y: 600 }}
          id='txId'
          className="transaction-table"
          dataSource={list}
          bordered
          size="middle"
          style={{ marginTop: '20px', borderRadius: '20px' }}
          onChange={onChangePage}
          pagination={{
            current: currentPage,
            total: total,
            pageSize: pageSize,
            pageSizeOptions: _page_size_options,
            hideOnSinglePage: total < _hide_when_less_than
          }}
        >
          <Column 
            title="Transaction ID"
            key="id"
            width={200}
            sorter={true}
            render={(record: any) => (
              <Link to={createAbsoluteUrl(`${navPath.order_details}/${record.id}`)}>
                <Space>{record.id}</Space>
              </Link>
            )}
          />
          <Column
            title="Partner"
            key="siteName"
            width={80}
            sorter={true}
            render={(record: any) =>
              validatePermission('Sites') ? (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`${context.dictionaries.widgetUrl}?apikey=${record.txSiteId}`}
                >
                  {record.siteName}
                </a>
              ) : (
                record.siteName
              )
            }
          />
          <Column
            title="Date"
            key="txDate"
            width={100}
            sorter={true}
            render={(record: any) => moment(record.txDate).format('DD MMM YY')}
          />
          <Column
            title="User's Name"
            key="name"
            width={100}
            sorter={true}
            render={(record: any) => record.name + ' ' + record.surname}
          />``
          <Column
            title="User's Email"
            key="email"
            width={180}
            sorter={true}
            render={(record: any) => (
              <Link
                to={createAbsoluteUrl(`${navPath.user_profile}/${record.userId}/Transaction History`)}
              >
                <Space>{record.email}</Space>
              </Link>
            )}
          />
          <Column
            title="Crypto Amount"
            key="orderAmount"
            width={140}
            sorter={true}
            render={(record: any) =>
              record.orderAmount > 0 ? record.orderAmount : '-'
            }
          />
          <Column
            title="Crypto Type"
            key="selectedCryptoCurrency"
            width={90}
            sorter={true}
            render={(record: any) =>
              record.selectedCryptoCurrency ? record.selectedCryptoCurrency : '-'
            }
          />
          <Column
            title="Value"
            key="price"
            width={90}
            sorter={true}
            render={(record: any) =>
              record.price ? parseFloat(record.price).toFixed(2) : '-'
            }
          />
          <Column
            title="Currency"
            dataIndex="currency"
            key="currency"
            width={90}
            sorter={true}
          />
          <Column
            title="Order status"
            key='orderStatus'
            dataIndex="orderStatus"
            width={100}
            render={(record: any) =>
              formatOrderStatus(record)
            }
            sorter={true}
          />
        </Table>
      </Spin>
    </>
  );
};
