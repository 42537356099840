import { AppContext } from '../../../_common/app_context_provider/AppContextProvider';
import { CoinsAndTokensTable } from '../tables/CoinsAndTokensTable';
import { listPurchasableTokens, setPurchasableTokenAvailable } from '../../../_services/availableTokens/AvailableTokensServices';
import { loading_icon } from '../../../_common/components/loading/LoadingIcon';
import { openSuccessNotification } from '../../../_heplers/NotificationHelper';
import { PageTitleComponent } from '../../../_common/components/page_title/PageTitleComponent';
import { Select, Spin } from 'antd';
import { useContext, useEffect, useState } from 'react';


const select_styles = {
    borderRadius: 8,
    backgroundColor: 'white',
    width: 300,
    marginBottom: 7,
    marginRight: 11,
};

export const CoinsAndTokensContent = () => {
    const context = useContext(AppContext);
    const [loading, setLoading] = useState(false)
    const [coins_and_tokens, setCoinsAndTokens] = useState<any[]>([])
    const [availableSites, setAvailableSites] = useState<any[]>(context.app_user_inf?.availableSites).filter((item: any) => !item.archived);
    const [selectedSite, setSelectedSite] = useState(context?.app_user_inf?.availableSites[0]?.siteUuid);
    const getCoinsAndTokens = (site: string = selectedSite) => {
        setLoading(true);

        listPurchasableTokens(site)
            .then((response: any) => setCoinsAndTokens(response.data))
            .finally(() => setLoading(false));
    }
    const changeAccountStatus = async (id_account: any, isAvailable: boolean) => {
        let data = [...coins_and_tokens]

        const index = data.findIndex((x: any) => x.id === id_account);
        const params = {
            siteUuid: selectedSite,
            id: id_account,
            isAvailable
        }

        await setPurchasableTokenAvailable(params)
            .then(() => {
                data[index].isAvailable = !data[index].isAvailable;

                setCoinsAndTokens(data);
                openSuccessNotification(`${data[index]?.name} status updated!`);
            })
            .catch(() => {
                data[index].isAvailable = !isAvailable;
                setCoinsAndTokens(data);
            });
    }

    const onChangeSelect = (value: any) => {
        setSelectedSite(value)
        getCoinsAndTokens(value);
    }

    useEffect(() => {
        getCoinsAndTokens();
    }, [])

    return (
        <>
            <PageTitleComponent page_title='Partner Tokens' />
            <div style={{ marginBottom: 5 }}>
                <Select
                  // @ts-ignore
                    value={selectedSite || availableSites[0]}
                    onChange={onChangeSelect}
                    placeholder='Sites'
                    className='filters'
                    style={select_styles}
                    bordered={false}
                    showSearch
                    filterOption={(input: any, option: any) => (option.props.children ? option.props.children : "").toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                    
                    {
                        // @ts-ignore
                        availableSites?.map(x => <Select.Option key={x.siteUuid} value={x.siteUuid}>{x.siteName}</Select.Option>)
                    }
                </Select>
            </div>
            <Spin className='spin_60' spinning={loading} indicator={loading_icon(40)}>
                <CoinsAndTokensTable
                    data={coins_and_tokens}
                    changeAccountStatus={changeAccountStatus}
                />
            </Spin>
        </>
    );


    //#endregion ____ Filters ____
}
