import axios from '../api';

const url = 'api/TransactionApi';

export interface ITransactionServiceParams {
  filterFrom?: string;
  filterTo?: string;
  country?: string;
  currency?: string;
  searchName?: string;
  filterBy?: string;
  filterByValue?: string;
  searchStatus?: string;
  startRow?: number;
  rows?: number;
  orderBy?: string;
  desc?: boolean;
};

export const listService = async (params: ITransactionServiceParams, data: string[] = []) => {
  return axios.post(`${url}/List`, data, { params })
    .then((response) => {
      return { data: response.data.results?.map((x: any) => {
        return { key: x.id, ...x };
        }),
          total: response.data.total
        }
    })
    .catch((error) => {
      console.log('--------------- transactionListService', error.message);

      return { data: [], total: 0 };
    });
};

export const exportData = async (params: ITransactionServiceParams, data: string[] = []) => {
  return axios.post(`${url}/TransactionReport`, data, { params })
  .then((response) => response.data)
};

export const pendingTransactionsService = async (params: ITransactionServiceParams, data: string[] = []) => {
  return axios.post(`${url}/PendingList`, data, { params })
    .then(response => response.data.transactions.map((x: any) => { return { key: x.id, ...x } }))
    .catch(error => {
      console.log('--------------- pendingListService', error.message);
      return [];
    });
};

export const completedTransactionsService = async (params: ITransactionServiceParams, data: string[] = []) => {
  return axios.post(`${url}/CompletedTransactions`, data, { params })
    .then((response) => response.data.results.map((x: any) => {
      return { key: x.id, ...x };
    }))
    .catch((error) => {
      console.log('--------------- completedListService', error.message);
      return [];
    });
};

export const settlementTransactionsService = async (params: ITransactionServiceParams, data: string[] = []) => {
  return axios
    .post(`${url}/SettlementList`, data, { params })
    .then((response) =>
      response.data.results.map((x: any) => {
        return { key: x.id, ...x };
      }),
    )
    .catch((error) => {
      console.log('--------------- settlementListService', error.message);
      return [];
    });
};

export const wireTransactionsService = async (
  filterFrom?: string,
  filterTo?: string,
  country?: string,
  currency?: string,
  filterByValue?: number,
  searchName?: string,
  siteUuid?: string
) => {
  return axios
    .get(`${url}/GetWireTransferList`, {
      params: {
        filterFrom,
        filterTo,
        country,
        currency,
        searchName,
        filterBy: 'TransactionType',
        filterByValue,
        siteUuid
      },
    })
    .then((response) =>
      response.data.map((x: any) => {
        return { key: x.id, ...x };
      }),
    )
    .catch((error) => {
      console.log('--------------- wiredListService', error.message);
      return [];
    });
};

export const getOrderDetails = async (requestUuid: string) =>
  await axios.get(`${url}/ExploreTransaction`, { params: { requestUuid } })
    .then((response) => response.data)
    .catch((error) => {
      console.log('--------------- exploreTransaction', error.message);
      return [];
    });

export const getUserHistory = (userId: string) =>
  axios
    .post(`${url}/UserTransactions`, [], {params: {userId}})
    .then((response) => {
      return response.data.results;
    })
    .catch((error) => {
      console.log('--------------- userTransactions', error.message);
      return undefined;
    });

export const approvePendingService = async (txId?: string) =>
  axios
    .post(`${url}/Approve`, {
      txId: txId
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log('--------------- ApproveService', error.message);
      return [];
    });

export const markSettlementService = async (markedTx?: Array<string>) =>
  axios
    .post(`${url}/MarkSettlement`, {
      markedTx: markedTx,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log('--------------- MarkSettlementService', error.message);
      return [];
    });

export const authorizeRefundService = async (orderId?: string) =>
  axios
    .get(`${url}/AuthorizeRefund`, {
      params: {
        requestUuid: orderId,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log('--------------- AuthorizeRefundService', error.message);
      return [];
    });

export const refundOrderService = async (orderId?: string, refundComment?: string) =>
  axios
    .post(`${url}/Refund`, {
      txId: orderId,
      refundComment: refundComment
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log('--------------- RefundOrderService', error.message);
      return [];
    });