import MemoMenuBlockedCards from '../../../images/components/MenuBlockedCards';
import MemoMenuBlockedTerritories from '../../../images/components/MenuBlockedTerritories';
import MemoMenuCoinsTokens from '../../../images/components/MenuCoinsTokens';
import MemoMenuFailedTransactions from '../../../images/components/MenuFailedTransactions';
import MemoMenuRoles from '../../../images/components/MenuRoles';
import MenuTransactionHistory from '../../../images/components/MenuTransactionHistory';
import React, { useContext } from 'react';
import styles from './styles/MainMenu.module.scss';
import { AppContext } from '../../app_context_provider/AppContextProvider';
import { Layout, Menu } from 'antd';
import { Link } from 'react-router-dom';
import { navPath } from '../../NavPath';

const { SubMenu } = Menu;
const { Sider } = Layout;

const MainMenu = () => {
  const validatePermission = useContext(AppContext).validatePermission;
  const [openKeys, setOpenKeys] = React.useState(['']);
  const listOfPermissions = useContext(AppContext).app_user_inf.permissions;

  const displayConfigurationSubmenu = () => {
    const subMenuPermissions = ['ManageTerritories', 'ReadFraudCards', 'ManageTokens', 'CanEditRoles', 'ManageFees', 'ManageRiskConfigs']
    return listOfPermissions.some((item: any) => subMenuPermissions.includes(item))
  }

  const onOpenChange = (key: string[]) => {
        if (key.length > 2) {
            const k = key[2];
            setOpenKeys(['', k])
        }
        else {
            setOpenKeys(key)
        }
    };
    const clearOpen = () => {
        setOpenKeys([''])
    }

    return (
      <Sider
        width={256}
        className={`site-layout-background ${styles.main_menu}`}
      >
        <Menu
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          mode="inline"
          style={{ height: '100%', borderRight: 0 }}
        >
          <SubMenu key="sub_1" title="Transactions">
            {validatePermission('ReadTransactionList') && (
              <Menu.Item
                key={navPath.transactions_transaction_history}
                icon={<MenuTransactionHistory />}
              >
                <Link to={navPath.transactions_transaction_history}>
                  Transaction History
                </Link>
              </Menu.Item>
            )}
            {/* {validatePermission('ReadTransactionList') && (
              <Menu.Item
                key={navPath.transactions_delivered_transactions}
                icon={<MemoMenuDeliveredTransactions />}
              >
                <Link to={navPath.transactions_delivered_transactions}>
                  Delivered Transactions
                </Link>
              </Menu.Item>
            )} */}
            {/* {validatePermission('ReadTransactionList') && (
              <Menu.Item
                key={navPath.transactions_pending_transactions}
                icon={<MemoMenuPendingTransactions />}
              >
                <Link to={navPath.transactions_pending_transactions}>
                  Pending Transactions
                </Link>
              </Menu.Item>
            )} */}
            {/* {validatePermission('ReadTransactionList') && (
              <Menu.Item
                key={navPath.transactions_failed_transactions}
                icon={<MemoMenuFailedTransactions />}
              >
                <Link to={navPath.transactions_failed_transactions}>
                  Failed Transactions
                </Link>
              </Menu.Item>
            )} */}
            {/* {validatePermission('TransactionExplorer') && (
            <Menu.Item
              key={navPath.transactions_transaction_explorer}
              icon={<MemoMenuFailedTransactions />}
            >
              <Link to={navPath.transactions_transaction_explorer}>
                Transaction Explorer
              </Link>
            </Menu.Item>
            )} */}
            {/* {validatePermission('Settlement') && (
              <Menu.Item
                key={navPath.transactions_settlement_transactions}
                icon={<MemoMenuFailedTransactions />}
              >
                <Link to={navPath.transactions_settlement_transactions}>
                  Settlement Transactions
                </Link>
              </Menu.Item>
            )} */}
            {validatePermission('CanReadWireTransferTransactions') && (
              <Menu.Item
                key={navPath.transactions_wire_transactions}
                icon={<MemoMenuFailedTransactions />}
              >
                <Link to={navPath.transactions_wire_transactions}>
                  Wire Transactions
                </Link>
              </Menu.Item>
            )}
            {validatePermission('ReadClientList') && (
              <Menu.Item
                key={navPath.anti_fraud_blocked_users}
                icon={<MemoMenuFailedTransactions />}
              > 
                <Link to={navPath.anti_fraud_blocked_users}>
                  Anti Fraud Blocked Users
                </Link>
              </Menu.Item>
            )}
            {/* {validatePermission('CanReadWireWallets') && (
              <Menu.Item key={navPath.wallets} icon={<MemoMenuBlockedCards />}>
                <Link to={navPath.wallets}>Wallets</Link>
              </Menu.Item>
            )} */}
          </SubMenu>
          {validatePermission('Sites') && (
            <Menu.Item key={navPath.mobilum_partners}>
              <Link to={navPath.mobilum_partners} onClick={clearOpen}>
                Mobilum Partners
              </Link>
            </Menu.Item>
          )}
          {validatePermission('ReadUserList') && (
            <Menu.Item key={navPath.partner_admins}>
              <Link to={navPath.partner_admins} onClick={clearOpen}>
                Partner Admins
              </Link>
            </Menu.Item>
          )}
          {validatePermission('ReadClientList') && (
            <Menu.Item key={navPath.registered_users}>
              <Link to={navPath.registered_users} onClick={clearOpen}>
                Registered Users
              </Link>
            </Menu.Item>
          )}
          {validatePermission('ManageEmailTemplates') && (
            <Menu.Item key={navPath.notifications}>
              <Link to={navPath.notifications} onClick={clearOpen}>
                Notifications
              </Link>
            </Menu.Item>
          )}
          {validatePermission('GeneratedReports') && (
          <Menu.Item key={navPath.reports}>
            <Link to={navPath.reports} onClick={clearOpen}>
              Generated Reports
            </Link>
          </Menu.Item>
          )}
          {!listOfPermissions?.includes('SuperAdmin') &&
            listOfPermissions?.includes('PurchasableTokens') && (
              <Menu.Item key={navPath.coins_and_tokens}>
                <Link to={navPath.coins_and_tokens} onClick={clearOpen}>
                  Coins & Tokens
                </Link>
              </Menu.Item>
            )}
          {displayConfigurationSubmenu() && (
            <SubMenu key="sub_2" title="Configuration">
              {validatePermission('ManageTerritories') && (
                <Menu.Item
                  key={navPath.configuration_blocked_territories}
                  icon={<MemoMenuBlockedTerritories />}
                >
                  <Link to={navPath.configuration_blocked_territories}>
                    Blocked Territories
                  </Link>
                </Menu.Item>
              )}
              {validatePermission('ReadFraudCards') && (
                <Menu.Item
                  key={navPath.configuration_blocked_cards}
                  icon={<MemoMenuBlockedCards />}
                >
                  <Link to={navPath.configuration_blocked_cards}>
                    Blocked Cards
                  </Link>
                </Menu.Item>
              )}
              {listOfPermissions?.includes('SuperAdmin') &&
                listOfPermissions?.includes('ManageTokens') && (
                  <Menu.Item
                    key={`${navPath.coins_and_tokens}1`}
                    icon={<MemoMenuCoinsTokens />}
                  >
                    <Link to={navPath.configuration_coins_and_tokens}>Coins & Tokens</Link>
                  </Menu.Item>
                )}
              {listOfPermissions?.includes('SuperAdmin') &&
                listOfPermissions?.includes('ManageTokens') && (
                <Menu.Item
                  key={navPath.coins_and_tokens}
                  icon={<MemoMenuCoinsTokens />}
                >
                  <Link to={navPath.coins_and_tokens}>
                    Partner Tokens
                  </Link>
                </Menu.Item>
              )}
              {validatePermission('CanEditRoles') && (
                <Menu.Item
                  key={navPath.configuration_roles}
                  icon={<MemoMenuRoles />}
                >
                  <Link to={navPath.configuration_roles}>Roles</Link>
                </Menu.Item>
              )}
              {/* {validatePermission('ManageFees') && (
                <Menu.Item
                  key={navPath.configuration_fee_config}
                  icon={<MemoMenuRoles />}
                >
                  <Link to={navPath.configuration_fee_config}>Fee Configs</Link>
                </Menu.Item>
              )} */}
              {validatePermission('ManageRiskConfigs') && (
                <Menu.Item
                  key={navPath.configuration_user_risk_level}
                  icon={<MemoMenuRoles />}
                >
                  <Link to={navPath.configuration_user_risk_level}>
                    Risk Level Config
                  </Link>
                </Menu.Item>
              )}
            </SubMenu>
          )}
        </Menu>
      </Sider>
    );
}
export default MainMenu;
