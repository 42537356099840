import InputForm from '../../../../_common/components/forms/InputForm';
import React from 'react';
import {
    Button,
    Col,
    Form,
    Row,
    Select,
    Spin,
    Switch
    } from 'antd';
import { createRolesService, updateRolesService } from '../../../../_services/roles/RolesServices';
import { FormInstance } from 'antd/lib/form';
import { getFirstPagesOptions, getListOfAllowedNavPaths } from '../../../../_heplers/DisplayViewHelper';
import { IConfigurationRoleFormProps } from '../interfaces/IConfigurationRoleFormProps';
import { IRole } from '../interfaces/IRole';
import { loading_icon } from '../../../../_common/components/loading/LoadingIcon';
import { openSuccessNotification } from '../../../../_heplers/NotificationHelper';

export class ConfigurationRoleForm extends React.Component<IConfigurationRoleFormProps>  {
    _is_mounted = true;
    _form_ref = React.createRef<FormInstance>();

    state = {
        loading: false
    };

    componentWillUnmount = () => this._is_mounted = false;

    public render() {
        const { loading } = this.state;

        const { setModalVisible, data, context } = this.props;

        return (
            <Spin spinning={loading} indicator={loading_icon(60)}>
                <Form
                    layout='vertical'
                    name='control-ref'
                    className='modal_content'
                    onFinish={this.onFinish}
                    ref={this._form_ref}
                    initialValues={{ ...data }}
                >
                    <Row gutter={[32, 0]}>
                        <Col span={12}>
                            <Form.Item
                                name='name'
                                rules={[{ required: true }]}
                            >
                                <InputForm
                                    label='Role Name'
                                    placeholder='enter role name'
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name='priority'
                                rules={[{ required: true }]}
                            >
                                <InputForm
                                    type='number'
                                    label='Priority'
                                    placeholder='enter priority'
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label='Permissions'
                                name='permissions'
                            >
                                <Select
                                    showSearch
                                    className='multi_select'
                                    style={{ width: '100%' }} mode="multiple"
                                    filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {context.app_user_inf.permissions && context.app_user_inf.permissions.map((permission: any, index: number) => (
                                        <Select.Option key={permission} value={index}>{permission}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="Is Active"
                                name="isActive"
                                valuePropName='checked'
                                className='switch_style'
                                style={{ flexDirection: 'row', marginTop: 35, marginLeft: 'auto', marginRight: 'auto',paddingLeft:170 }}
                            >
                                <Switch />
                            </Form.Item>
                        </Col>  
                        <Col span={24}>
                            <Form.Item
                                label='First page'
                                name='firstPage'
                            >
                                <Select
                                    allowClear
                                    showSearch
                                    placeholder='Select'
                                    className='width_100_percent'
                                    options={getFirstPagesOptions()}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className='grid_col_2'>
                        <Button
                            className='common_cancel_button common_previous_button'
                            onClick={() => setModalVisible(false)}
                        >
                            Cancel
                        </Button>
                        <Form.Item shouldUpdate={true}>
                            {() => (
                                <Button
                                    className='common_submit_button common_next_button'
                                    htmlType='submit'
                                    type='primary'
                                    style={{ width: '100%' }}
                                    disabled={this.showSuimtuBtton()}
                                >
                                    Save
                                </Button>
                            )}
                        </Form.Item>
                    </div>
                </Form>
            </Spin>
        );
    }

    showSuimtuBtton = () => {
        if (this._form_ref.current) {
            const errors = this._form_ref.current
                .getFieldsError()
                .filter(({ errors }) => errors.length).length;

            // Check if error
            if (errors) {
                return true;
            }
            else {
                return false;
            }
        }

        return true;
    };

    onFinish = async (values: any) => {
        this.setState({ loading: true });

        const {
            data,
            updateObjectInArray,
            addObjectToArray
        } = this.props;

        let role: IRole = {
            ...data,
            ...values,
            firstPage: values.firstPage ? values.firstPage : null,
            priority: parseInt(values.priority),
            isActive: values.isActive || false
        };

        if (this.props.data.id) {
            // Update role
            await updateRolesService(role.id, role)
                .then(() => {
                    updateObjectInArray!(role);
                    openSuccessNotification(`Role ${role.name} updated`);
                })
                .finally(() => this._is_mounted && this.setState({ loading: false }));
        }
        else {
            // Add new role
            await createRolesService(role)
                .then(response => {
                    role.id = Number(response.data);
                    role.key = Number(response.data);
                    addObjectToArray!(role);
                    openSuccessNotification(`Role ${role.name} created`);
                })
                .finally(() => this._is_mounted && this.setState({ loading: false }));
        }
    };
}
