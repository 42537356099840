import axios from '../api';

const url = 'api/Report';

export const getReportsServices = () =>
  axios
    .get(`${url}/Index`)
    .then((response) => response.data.results)
    .catch((error) => {
      console.log('--------------- getReportsServices', error.message);
      return [];
    });

export const getRefreshLinkReportsServices = (id: number) =>
  axios
    .get(`${url}/RefreshPresignedUrl`, {
      params: {
        reportTrackingId: id,
      },
    })
    .then((response) => response.data.fileUrl)
    .catch((error) => {
      console.log(
        '--------------- getRefreshLinkReportsServices',
        error.message,
      );
      return [];
    });
