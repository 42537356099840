import WiredTableData from './WiredTableData';
import { AppContext } from '../../../_common/app_context_provider/AppContextProvider';
import { Filters } from '../transaction_history/Filters';
import { PageTitleComponent } from '../../../_common/components/page_title/PageTitleComponent';
import { useContext, useEffect, useState } from 'react';
import {  wireTransactionsService } from '../../../_services/transactions/TransactionServices';
import { loading_icon } from '../../../_common/components/loading/LoadingIcon';
import { Button, Col, Modal, Row, Spin } from 'antd';
import { GetMainBalance, sendWithdraw } from '../../../_services/wire/WireServices';
import { IAccountBalance } from './interfaces/IAccountBalance';
import WiredWithdrawModal from './WiredWithdrawModal';
import { openErrorNotification, openSuccessNotification } from '../../../_heplers/NotificationHelper';

export const WiredTransactions = () => {
  const apply_filters_button = {
    width: 144,
    height: 48,
    borderRadius: 8,
    marginBottom: 16,
  };

  const context = useContext(AppContext);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [balanceLoading, setBalanceLoading] = useState(true);
  const [show_modal, setShowModal] = useState(false);
  const [account_balance, setAccountBalance] = useState({ balance: undefined, currency: undefined, availableBalance: undefined } as IAccountBalance);
  const siteUuid = context.wired_filters.siteUuid || context.wired_filters.siteUuid === undefined ? context.wired_filters.siteUuid : context.app_user_inf?.availableSites[0]?.siteUuid;
  
  useEffect(() => {
    if(siteUuid) {
      getAccountBalance(siteUuid);
    } else setAccountBalance({ balance: 0, currency: undefined, availableBalance: 0 });
  }, [siteUuid])

  const getAccountBalance = async (siteUuid: string) => {
    setBalanceLoading(true);
    await GetMainBalance({ apiKey: siteUuid!, siteUuid: siteUuid! })
      .then((account_balance: IAccountBalance) => {
        setAccountBalance(account_balance);
      })
      .catch(error => console.log('----- error', error.message))
      .finally(() => setBalanceLoading(false));
  }
  
  useEffect(() => {
      getList(
        context.wired_filters.firstDate.format('DD/MM/YYYY'),
        context.wired_filters.lastDate.format('DD/MM/YYYY'),
        context.wired_filters.country,
        context.wired_filters.currency,
        context.wired_filters.wireType,
        context.wired_filters.search,
        siteUuid,
      );
  }, [context.wired_filters, siteUuid]);

  const getList = async (
    from?: any,
    to?: any,
    country?: string,
    currency?: string,
    wireType?: number,
    search?: string,
    siteUuid?: string
  ) => {
    setLoading(true);
    setList(
      await wireTransactionsService(
        from,
        to,
        country,
        currency,
        wireType,
        search,
        siteUuid
      ).finally(() => setLoading(false))
    );
  };

  const onFinish = async (data: any) => {
    console.log(data)
    const amount = data.amount;
    delete data.amount;
    await sendWithdraw(context?.app_user_inf?.login, siteUuid!, amount, data)
    .then((response: any) => {
      if(response.success) {
        openSuccessNotification('Withdrawal has been initiated')
      } else if(response.errors) {
        response.errors.map((error: string) => {
          return openErrorNotification(error)
        })
      }
    })
    .finally(() => {
      getAccountBalance(siteUuid)
      setShowModal(false)
    })
  }

  const displayValue = (value?: string | number | null) => value ? value.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '-';

  return (
    <>
      <PageTitleComponent page_title="Wire Transaction History Report" />
      <Filters
        id='transationUuid'
        transactionType="wired_filters"
        list={list}
      />
       <Row>
       <Col span={8}>
      <h2 style={{ paddingTop: 8 }}>Account Balance: {balanceLoading ? <Spin className='spin_60' spinning={balanceLoading} indicator={loading_icon(16)} /> : `${displayValue(account_balance ? account_balance.balance : null)}  ${displayValue(account_balance ? account_balance.currency : null)}`} </h2>
      </Col>
      {context.app_user_inf.permissions?.includes('CanWithdraw') && <Button
                    htmlType="submit"
                    type="primary"
                    style={apply_filters_button}
                    onClick={() => setShowModal(true)}
                  >
                    Withdraw
                  </Button>}
      </Row>
      <WiredTableData list={list} loading={loading} />
      <Modal
                    title='Withdrawal data'
                    visible={show_modal}
                    closable={false}
                    footer={false}
                    width={500}
                    destroyOnClose
                >
                  <WiredWithdrawModal
                  setModalVisible={(value: boolean) => setShowModal(value)}
                  onFinish={onFinish}
                  />
                </Modal>
    </>
  );
};
