import { _default_page_size, _hide_when_less_than, _page_size_options } from '../../../_heplers/GlobalValues';
import { memo, useState } from 'react';
import { Table } from 'antd';

export const TransactionsWalletTable = (props: any) => {
    const [pageSize, setPageSize] = useState(_default_page_size);

    const {
        data,
    } = props;

    const columns: any[] = [
        {
            title: 'Transaction Type',
            dataIndex: 'transactionType',
            key: 'transactionType',
            width: 150,
        },
        {
            title: 'Transation Uuid',
            dataIndex: 'transationUuid',
            key: 'transationUuid',
            width: 300
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            width: 100
        },
        {
            title: 'Sender Name',
            dataIndex: 'senderName',
            key: 'senderName',
            width: 150
        },
        {
            title: 'Sender Address',
            dataIndex: 'senderAddress',
            key: 'senderAddress',
            width: 300,
        },
        {
            title: 'Sender Country',
            dataIndex: 'senderCountry',
            key: 'senderCountry',
            width: 130
        },
        {
            title: 'Currency',
            dataIndex: 'currency',
            key: 'currency',
            width: 100
        },
        {
            title: 'Third Party Iban',
            dataIndex: 'thirdPartyIban',
            key: 'thirdPartyIban',
            width: 200
        },
        {
            title: 'Iban',
            dataIndex: 'iban',
            key: 'iban',
            width: 200
        },
        
    ];
    return (
        <Table
            bordered
            size='small'
            columns={columns}
            dataSource={data}
            scroll={{ y: 600 }}
            pagination={{
                pageSize: pageSize,
                onChange: (e: number, b: number) => setPageSize(b),
                showSizeChanger: true,
                pageSizeOptions: _page_size_options,
                hideOnSinglePage: data.length < _hide_when_less_than
            }}
        />
    )
}
export default memo(TransactionsWalletTable)
