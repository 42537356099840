import { _default_page_size, _hide_when_less_than, _page_size_options } from '../../../../_heplers/GlobalValues';
import { Button, Form, Input, Modal, Table } from 'antd';
import { convertNumberToDateTimeString } from '../../../../_heplers/DateHelpers';
import { hideCardNumber } from '../../../../_heplers/CardHelpers';
import { IBlockedCard } from '../interfaces/IBlockedCard';
import { useState } from 'react';
import styles from '../../../notifications/styles/Notifications.module.scss';

export const ConfigurationBlockedCardsTable = (props: any) => {
    const [pageSize, setPageSize] = useState(_default_page_size);
    const [showModal, setModal] = useState(false);
    const [reason, setReason] = useState('');
    const [id, setId] = useState(null);
    const {
        data,
        unblockCard,
    } = props;

  const onSubmit = async () => {
    setModal(false);
     unblockCard(id, reason);
  };
  const openModal = (id: any) => {
    setId(id)
      setModal(true)
  }

    const columns: any[] = [
        {
            title: 'Email',
            dataIndex: 'userEmail',
            key: 'userEmail',
            ellipsis: true,
            render: (text: string) => <a>{text}</a>,
        },
        {
            title: 'User UUID',
            dataIndex: 'userUuid',
            key: 'userUuid',
            ellipsis: true,
            render: (text: string) => <a>{text}</a>,
        },
        {
            title: 'TransactionDate',
            key: 'created_at',
            width: 200,
            render: (record: IBlockedCard) => (
                convertNumberToDateTimeString({ number: record.txDate, request_date_format: 'DD MMM YY' })
            )
        },
        {
            title: 'Declined Code',
            dataIndex: 'declineCode',
            key: 'declineCode',
            ellipsis: true
        },
        {
            title: 'Credit Card',
            key: 'credit_card',
            ellipsis: true,
            render: (record: IBlockedCard) => (
                <div>{hideCardNumber(record.cardNumber)}</div>
            )
        },
        {
            title: 'Action',
            key: 'actions',
            render: (record: IBlockedCard) => (
                <Button
                    type='primary'
                    className='common_unlock_button'
                    onClick={() => openModal(record.id)}
                >
                    Unblock
                </Button>
            )
        }
    ];

    return (
        <>
        <Table
            bordered
            size='small'
            columns={columns}
            dataSource={data}
            pagination={{
                pageSize: pageSize,
                onChange: (e: number, b: number) => setPageSize(b),
                showSizeChanger: true,
                pageSizeOptions: _page_size_options,
                hideOnSinglePage: data.length < _hide_when_less_than
            }}
        />
        <Modal
          title={undefined}
          visible={showModal}
          closable={false}
          footer={false}
          width={400}
          destroyOnClose
        >
          <Form className={styles.modal_content}>
            <div
              className={styles._modal_title}
              style={{ marginBottom: '30px' }}
            >
              Are you sure you want to Unblock?
            </div>
              <Form.Item rules={[{ required: true }]} style={{ marginBottom: 20 }}>
                <Input
                  placeholder="Unblocking reason"
                  onChange={(input: any) =>  setReason(input.target.value)}
                />
              </Form.Item>
            <div className="grid_col_2">
              <Button
                className="common_cancel_button"
                onClick={() => setModal(false)}
              >
                Cancel
              </Button>
              <Form.Item shouldUpdate={true}>
                {() => (
                  <Button
                    className="common_submit_button"
                    htmlType="submit"
                    type="primary"
                    onClick={() => onSubmit()}
                    disabled={!reason}
                  >
                    Confirm
                  </Button>
                )}
              </Form.Item>
            </div>
          </Form>
        </Modal>
        </>
    )
}
