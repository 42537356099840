import { _page_size_options } from '../../../_heplers/GlobalValues';
import {
    Button,
    Select,
    Table,
    Tooltip
    } from 'antd';
import { createAbsoluteUrl } from '../../../_heplers/UrlCreatorHelpres';
import { Link } from 'react-router-dom';
import { memo } from 'react';
import { navPath } from '../../../_common/NavPath';

export const WalletsTable = (props: any) => {
    const {
        data
    } = props;

    const select_styles = {
        borderRadius: 10,
        border: '1px solid #d9d9d9',
        backgroundColor: 'white',
        minWidth: 138,
        width: 'auto',
    };

    const columns: any[] = [
        {
            title: 'Virtual Account No',
            dataIndex: 'VirtualAccountNumber',
            key: 'VirtualAccountNumber',
            width: 200,
            render: (record: any) => (
                <>
                    <Tooltip arrowPointAtCenter title='Go to details'>
                        <Link
                            to={createAbsoluteUrl(`${navPath.transactions_wallet}/${props.siteUuid}/${record}`)}> {record} </Link>
                    </Tooltip>
                </>
            )
        },
        {
            title: 'Name',
            dataIndex: 'Name',
            key: 'Name',
            ellipsis: true
        },
        {
            title: 'Surname',
            dataIndex: 'Surname',
            key: 'Surname',
            ellipsis: true
        },
        {
            title: 'Account Type',
            dataIndex: 'AccountType',
            key: 'AccountType',
            ellipsis: true
        },
        {
            title: 'Country',
            dataIndex: 'Country',
            key: 'Country',
            ellipsis: true
        },
        {
            title: 'Currency',
            dataIndex: 'Currency',
            key: 'Currency',
            ellipsis: true
        },
        {
            title: 'Balance',
            dataIndex: 'Balance',
            key: 'Balance',
            ellipsis: true
        },
    ];
    return (
        <>
            <Table
                bordered
                rowKey="VirtualAccountNumber"
                size='small'
                columns={columns}
                dataSource={data}
                pagination={false}
            />
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: 16 }}>
                <Button type="link" disabled={props.page === 1} onClick={() => props.changePage('-')}> {'<'} </Button>
                {props.page}
                <Button type="link" onClick={() => props.changePage('+')}> {'>'} </Button>
                <Select
                    placeholder='10 / page'
                    defaultValue={10}
                    onChange={props.setPageSize}
                    bordered={false}
                    style={select_styles}
                    optionFilterProp="children"
                    showSearch
                >
                    {_page_size_options?.map((x: any) => (
                        <Select.Option key={x} value={x}>
                            {x} / page
                        </Select.Option>
                    ))}
                </Select>

            </div>

        </>
    )
}
export default memo(WalletsTable)
