import { Button, Col, Form, Row, Select } from 'antd';
import InputForm from '../../../_common/components/forms/InputForm';
import { createRef, useContext, useState } from 'react';
import { FormInstance } from 'antd/es/form';
import { AppContext } from '../../../_common/app_context_provider/AppContextProvider';

const WiredWithdrawModal = (props: any) => {
	const form_ref = createRef<FormInstance>();
	const context = useContext(AppContext);
	const [type, setType] = useState(3 as number);

	const onBeneficaryTypeSelect = (selectedType: number) => {
		setType(selectedType);
	};
  
	return (
		<Form
			layout='vertical'
			name='control-ref'
			className='modal_content'
			onFinish={props.onFinish}
			ref={form_ref}
		>
			<Row gutter={[32, 0]}>
				<Col span={12}>
					<Form.Item
						name='amount'
						rules={[{ required: true }]}
					>
						<InputForm
							type='number'
							label='Amount'
							placeholder='Enter amount'
						/>
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item
						name='beneficiaryType'
						label='Beneficiary Type'
						rules={[{ required: true }]}
					>
						<Select
							className='width_100_percent'
							onChange={onBeneficaryTypeSelect}
						>
							<Select.Option
								key={0}
								value={0}
							>
								BUSINESS
							</Select.Option>
							<Select.Option
								key={1}
								value={1}
							>
								INDIVIDUAL
							</Select.Option>
						</Select>
					</Form.Item>
				</Col>
				{type === 1 ? (
					<>
						<Col span={12}>
							<Form.Item
								name='firstName'
								rules={[{ required: true }]}
							>
								<InputForm
									label='Firstname'
									placeholder='Enter firstname'
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								name='lastName'
								rules={[{ required: true }]}
							>
								<InputForm
									label='Lastname'
									placeholder='Enter lastname'
								/>
							</Form.Item>
						</Col>
					</>
				) : type === 0 && (
					<Col span={12}>
						<Form.Item
							name='companyName'
							rules={[{ required: true }]}
						>
							<InputForm
								label='Company Name'
								placeholder='Enter companyName'
							/>
						</Form.Item>
					</Col>
				)}
				<Col span={24}>
					<Form.Item
						name='accountNumber'
						rules={[{ required: true }]}
					>
						<InputForm
							label='Account Number'
							placeholder='Enter account number'
						/>
					</Form.Item>
				</Col>
				<Col span={24}>
					<Form.Item
						name='bic'
						rules={[{ required: true }]}
					>
						<InputForm
							label='BIC'
							placeholder='Enter bic'
						/>
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item
						name='city'
						rules={[{ required: true }]}
					>
						<InputForm
							label='City'
							placeholder='Enter city'
						/>
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item
						name='country'
						label='Country'
						rules={[{ required: true }]}
					>
						<Select
							className='width_100_percent'
							placeholder='Select'
							allowClear
							showSearch
							loading={context.loading_dictionaries}
							filterOption={(input: any, option: any) =>
								(option.props.children ? option.props.children : '')
									.toLowerCase()
									.indexOf(input.toLowerCase()) >= 0
							}
						>
							{context.dictionaries.countries?.map((x: any) => (
								<Select.Option
									key={x.value}
									value={x.value}
								>
									{x.name}
								</Select.Option>
							))}
						</Select>
					</Form.Item>
				</Col>
				<Col span={24}>
					<Form.Item
						name='postalCode'
						rules={[{ required: true }]}
					>
						<InputForm
							label='Postal Code'
							placeholder='Enter postal code'
						/>
					</Form.Item>
				</Col>
				<Col span={24}>
					<Form.Item
						name='addressLine1'
						rules={[{ required: true }]}
					>
						<InputForm
							label='Address Line'
							placeholder='Enter address line'
						/>
					</Form.Item>
				</Col>
			</Row>
			<div className='grid_col_2'>
				<Button
					className='common_cancel_button common_previous_button'
					onClick={() => props.setModalVisible(false)}
				>
					Cancel
				</Button>
				<Form.Item shouldUpdate={true}>
					{() => (
						<Button
							className='common_submit_button common_next_button'
							htmlType='submit'
							type='primary'
							style={{ width: '100%' }}
						>
							Save
						</Button>
					)}
				</Form.Item>
			</div>
		</Form>
	);
};

export default WiredWithdrawModal;
