import Back_icon from '../../../images/Back_Icon.png';
import InputForm from '../../../_common/components/forms/InputForm';
import React from 'react';
import {
    Button,
    Form,
    Image,
    } from 'antd';
import { createAbsoluteUrl } from '../../../_heplers/UrlCreatorHelpres';
import { forgotPasswordService } from '../../../_services/auth/AuthenticationServices';
import { FormInstance } from 'antd/lib/form';
import { IForgotPasswordFormProps } from '../interfaces/IForgotPasswordFormProps';
import { IForgotPasswordService } from '../../../_common/interfaces/services/IForgotPasswordService';
import { Link } from 'react-router-dom';
import { navPath } from '../../../_common/NavPath';
import { SpinComponent } from '../../../_common/components/spin/SpinComponent';
import { validateMessages } from '../../../_common/ValidationCominications';

export class EmailForm extends React.Component<IForgotPasswordFormProps> {
    _is_mounted = true;
    _form_ref = React.createRef<FormInstance>();

    state = {
        loading: false,
        message: undefined
    };

    componentWillUnmount = () => {
        this._is_mounted = false;
    };

    public render() {
        const { loading } = this.state;

        return (
            <div className='common_form_1'>
                {loading ? <SpinComponent /> : this.displayForm()}
            </div>
        );
    }

    //#region ____ Common ____

    showSuimtuBtton = () => {
        if (this._form_ref.current) {
            const fields = this._form_ref.current.getFieldsValue();
            const errors = this._form_ref.current
                .getFieldsError()
                .filter(({ errors }) => errors.length).length;

            // Check if error
            if (errors) {
                return true;
            }
            // Check if email exist
            if (fields.email === undefined) {
                return true;
            } else {
                return false;
            }
        }

        return true;
    };

    onFinish = (value: any) => {
        this.setState({
            loading: true,
            message: undefined,
        });
        const data: IForgotPasswordService = {
            email: value.email,
        };

        forgotPasswordService(data)
            .then((response: any) => {
                if (response.status === 200) {
                    this.props.setEmail?.(value.email);
                    this.props.changeContent('code_form');
                }
            })
            .finally(() => this._is_mounted && this.setState({ loading: false }));
    };

    //#endregion ____ Common ____

    //#region ____ Contents ____

    displayForm = () => {
        return (
            <div className='_form_content'>
                <div className='__back_button'>
                    <Link to={createAbsoluteUrl(navPath.login)}>
                        <Image
                            preview={false}
                            src={Back_icon}
                        />
                    </Link>
                </div>
                <div className='__form_title'>
                    Enter Email Address
                </div>
                <div className='__form_subtitle'>
                    We will send you a reset password link to your email address.
                </div>
                <Form
                    layout='vertical'
                    name='control-ref'
                    onFinish={this.onFinish}
                    ref={this._form_ref}
                    validateMessages={validateMessages()}
                >
                    <Form.Item
                        name='email'
                        rules={[{ type: 'email' }]}
                    >
                        <InputForm
                            label='Email address'
                            placeholder='enter email address'
                        />
                    </Form.Item>
                    <Form.Item shouldUpdate={true}>
                        {() => (
                            <Button
                                className='__submit_button'
                                style={{ marginTop: 16 }}
                                htmlType='submit'
                                type='primary'
                                disabled={this.showSuimtuBtton()}
                            >
                                Reset Password
                            </Button>
                        )}
                    </Form.Item>
                </Form>
            </div>
        );
    }

    //#endregion ____ Contents ____
}