import axios from '../api';

const url = 'api/FraudApi';

export const getBlockedUsersService = (id: number, reason?: string) =>
  axios
    .get(`${url}/BlockUser`, { params: { id, reason } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log('--------------- blockUserService', error.message);

      return [];
    });

export const unblockBlockedUsersService = (id: number, reason?: string) =>
  axios
    .get(`${url}/UnBlockUser`, { params: { id, reason } })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log('--------------- unblockBlockedUserService', error.message);

      return false;
    });
