import { Table } from "antd";
import { memo } from "react";
export const AntiFraudItemErrorsTable = (props: any) => {
  const {
      data,
  } = props;

  const columns: any[] = [
    {
      title: '#',
      key: 'id',
      dataIndex: 'id',
    },
    {
      title: 'Code',
      key: 'errorCode',
      dataIndex: 'errorCode',
    },
    {
      title: 'Description',
      key: 'errorMessage',
      dataIndex: 'errorMessage',
    }
  ];

  return (
      <Table
          bordered
          className="antifraud-table"
          columns={columns}
          dataSource={data}
          rowKey={record => record.id}
          scroll={{ x: 500 }}
          pagination={false}
      />
  )
}

export default memo(AntiFraudItemErrorsTable)