import axios from '../api';

const url = 'api/FraudApi';

export const getBlockedCardsService = () =>
  axios.get(`${url}/BlockedCards`)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.log('--------------- getBlockedCardsService', error.message);
      
      return [];
    });

export const unblockBlockedCardsService = (id: number, reason: string) =>
  axios.delete(`${url}/BlockedCards/${id}?reason=${reason}`)
    .then(() => {
      return true;
    })
    .catch(error => {
      console.log('--------------- unblockBlockedCardsService', error.message);
      
      return false;
    });