import { _default_page_size, _hide_when_less_than, _page_size_options } from '../../../_heplers/GlobalValues';
import {
    Button,
    Switch,
    Table,
    Tooltip
    } from 'antd';
import { Component } from 'react';
import { DeleteFilled, EditFilled } from '@ant-design/icons';
import { findInDictionaryAndReturnValueWithSpin } from '../../../_heplers/DictionariesHelper';
import { INotification } from '../interfaces/INotification';
import { INotificationsTableProps } from '../interfaces/INotificationsTableProps';

export class NotificationsTable extends Component<INotificationsTableProps> {
    state = { pageSize: _default_page_size }

    render() {
        const {
            data,
            available_sites,
            dictionaries,
            loading_dictionaries,
            changeNotificationStatus,
            updateObjectInArray,
            deleteObjectFromArray
        } = this.props;

        const { pageSize } = this.state;

        const columns: any[] = [
            {
                title: 'Partner',
                key: 'siteUuid',
                width: 200,
                ellipsis: true,
                render: (record: INotification) => findInDictionaryAndReturnValueWithSpin(record.siteUuid!, false, available_sites)
            },
            {
                title: 'Type',
                key: 'type',
                render: (record: INotification) => findInDictionaryAndReturnValueWithSpin(record.type, loading_dictionaries, dictionaries.notification_types)
            },
            {
                title: 'Language',
                key: 'language',
                dataIndex: 'language',
                width: 100,
                ellipsis: true
            },
            {
                title: 'Disabled',
                key: 'disabled',
                width: 100,
                render: (record: INotification) => <Switch checked={record.disabled ? true : false} onClick={() => changeNotificationStatus(record)} />
            },
            {
                title: 'Actions',
                key: 'actions',
                width: 88,
                render: (record: INotification) => (
                    <>
                        <Tooltip placement='rightTop' title='Edit Notification'>
                            <Button
                                size='small'
                                type='text'
                                onClick={() => updateObjectInArray!(record)}
                                icon={<EditFilled />}
                            />
                        </Tooltip>
                        <Tooltip placement='rightTop' title='Delete Notification'>
                            <Button
                                size='small'
                                type='text'
                                onClick={() => deleteObjectFromArray!(record)}
                                icon={<DeleteFilled />}
                            />
                        </Tooltip>
                    </>
                )
            }
        ];

        return (
            <Table
                columns={columns}
                bordered
                dataSource={data}
                size='small'
                pagination={{
                    pageSize: pageSize,
                    onChange: (e: number, b: number) => this.setState({ pageSize: b }),
                    showSizeChanger: true,
                    pageSizeOptions: _page_size_options,
                    hideOnSinglePage: data.length < _hide_when_less_than
                }}
            />
        )
    }
}
