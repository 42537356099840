import MenuIco from '../../../images/components/MenuIco';
import MenuUserProfileIcon from '../../../images/components/MenuUserProfile';
import MobilumLogo from '../../../images/components/MobilumLogo';
import styles from './styles/HeaderComponent.module.scss';
import { AppContext } from '../../app_context_provider/AppContextProvider';
import {
  Button,
  Drawer,
  Layout,
  Row
  } from 'antd';
import { createAbsoluteUrl } from '../../../_heplers/UrlCreatorHelpres';
import { getNavPath } from '../../../_heplers/DisplayViewHelper';
import { Link } from 'react-router-dom';
import { ModalMenuContent } from './ModalMenuContent';
import { useContext, useState } from 'react';

const { Header } = Layout;

export const HeaderComponent = () => {
  const [visibleModal, setVisible] = useState(false);
  const firstPage = useContext(AppContext).app_user_inf.firstPage;
  const email = useContext(AppContext).app_user_inf.email;

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const navPath = getNavPath(firstPage);

  return (
    <Header className={styles.header}>
      <Row className={styles.header_container}>
        <div className={styles.left_menu}>
          <Link to={createAbsoluteUrl(navPath)}>
            <MobilumLogo className={styles.logo} />
          </Link>
        </div>
        <div className={styles.right_menu}>
        <span className='flex'><MenuUserProfileIcon/></span>
           <span className={styles.user_email}>{email}</span> 
          <Button className={styles.btn_menu} onClick={showDrawer}><MenuIco /></Button>
        </div>
      </Row>
      <Drawer title="Menu" placement="right" onClose={onClose} visible={visibleModal} className={styles.modal_menu}>
        <ModalMenuContent setVisible={setVisible} />
      </Drawer>
    </Header>
  );
};

