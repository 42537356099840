import * as React from 'react';

function MenuLogout(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3 2a1 1 0 00-1 1v14a1 1 0 001 1h4a1 1 0 110 2H3a3 3 0 01-3-3V3a3 3 0 013-3h4a1 1 0 010 2H3zm10.293 2.293a1 1 0 011.414 0l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414-1.414L16.586 11H7a1 1 0 110-2h9.586l-3.293-3.293a1 1 0 010-1.414z"
                fill="#000"
            />
        </svg>
    );
}

const MemoMenuLogout = React.memo(MenuLogout);
export default MemoMenuLogout;
