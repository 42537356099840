import React from 'react';
import { Button, Form, Input } from 'antd';
import { createAbsoluteUrl } from '../../../_heplers/UrlCreatorHelpres';
import { FormInstance } from 'antd/lib/form';
import { navPath } from '../../../_common/NavPath';
import { openSuccessNotification } from '../../../_heplers/NotificationHelper';
import { resetPasswordByCodeService } from '../../../_services/auth/AuthenticationServices';
import { useNavigate } from 'react-router-dom';
import { validateMessages } from '../../../_common/ValidationCominications';
import { passwordValidator } from '../../../_heplers/RegexHelper';

const SetPasswordForm = (props: any) => {
    const _form_ref = React.createRef<FormInstance>();
    const navigate = useNavigate();

    const onSubmit = (values: any) => {
        let payload = {
            code: props.code,
            newPassword: values.newPassword
        }

        resetPasswordByCodeService(payload)
            .then(async (response: any) => {
                if (response.status === 200) {
                    openSuccessNotification('Password reset successfully, please login to continue')
                    navigate(createAbsoluteUrl(navPath.home))
                }
            });
    }

    const disableButton = () => {
        if (_form_ref.current) {
            const fields = _form_ref.current?.getFieldsValue();
            const errors = _form_ref.current!.getFieldsError().filter(({ errors }) => errors.length).length;
            // Check if error or if fields are empty
            if (errors || !fields.newPassword || !fields.confirm_new_password)
                return true;
            else
                return false;
        }

        return true;
    }
    return (
        <Form
            layout='vertical'
            name='control-ref'
            onFinish={onSubmit}
            ref={_form_ref}
            validateMessages={validateMessages()}
        >
            <>
                <Form.Item
                    label='New Password'
                    name='newPassword'
                    rules={[{ required: true }, { pattern: passwordValidator() }]}
                >
                    <Input.Password allowClear />
                </Form.Item>
                <Form.Item
                    label="Confirm New Password"
                    name="confirm_new_password"
                    dependencies={['newPassword']}
                    rules={[
                        { required: true },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('newPassword') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('The passwords do not match!'));
                            }
                        })
                    ]}
                >
                    <Input.Password allowClear />
                </Form.Item>
            </>

            <Form.Item shouldUpdate={true}>
                {() => (
                    <Button
                        type='primary'
                        htmlType='submit'
                        className='__submit_button'
                        disabled={disableButton()}
                    >
                        Save
                    </Button>

                )}
            </Form.Item>
        </Form>
    );
}
export default SetPasswordForm
