import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    ExclamationCircleOutlined,
    InfoCircleOutlined
    } from '@ant-design/icons';
import { notification } from 'antd';
import 'antd/dist/antd.css';

export const openSuccessNotification = (note: string) => {
    const args = {
        style: { backgroundColor: '#d9f2d9', opacity: 0.95, borderRadius: 4.5, top: -18 },
        message: 'Success!',
        description: note,
        icon: <CheckCircleOutlined style={{ color: 'green' }} />
    };
    notification.open(args);
};

export const openAlertNotification = (note: string) => {
    const args = {
        style: { backgroundColor: '#ffff99', opacity: 0.95, borderRadius: 4.5, top: -18 },
        message: 'Alert!',
        description: note,
        duration: 3,
        icon: <ExclamationCircleOutlined style={{ color: '#ff9900' }} />
    };
    notification.open(args);
};

export const openErrorNotification = (note: string) => {
    const args = {
        style: { backgroundColor: '#ffcccc', opacity: 0.95, borderRadius: 4.5, top: -18 },
        message: 'Error!',
        description: note,
        duration: 3,
        icon: <CloseCircleOutlined style={{ color: 'red' }} />
    };
    notification.open(args);
};

export const openInfoNotification = (note: string) => {
    const args = {
        style: { backgroundColor: '#597fe3', opacity: 0.95, color: 'black', borderRadius: 4.5, top: -18 },
        message: 'Information',
        description: note,
        duration: 3,
        icon: <InfoCircleOutlined style={{ color: '#0e4cee' }} />
    };
    notification.open(args);
};