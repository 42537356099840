import { _default_page_size, _hide_when_less_than, _page_size_options } from '../../../_heplers/GlobalValues';
import { Switch, Table } from 'antd';
import { useState } from 'react';

export const CoinsAndTokensTable = (props: any) => {
    const [pageSize, setPageSize] = useState(_default_page_size);

    const {
        data,
        changeAccountStatus,
    } = props;

    const columns: any[] = [
        {
            title: 'Cryptocurrency',
            dataIndex: 'name',
            key: 'name',
            ellipsis: true
        },
        {
            title: 'Ticker',
            dataIndex: 'ticker',
            key: 'ticker',
            ellipsis: true
        },
        {
            title: 'Activate Y/N',
            key: 'isAvailable',
            render: (record: any) => <Switch checked={record.isAvailable} onChange={(e) => changeAccountStatus(record.id, e)} />
        }
    ];

    return (
        <Table
            bordered
            rowKey="id"
            size='small'
            columns={columns}
            dataSource={data}
            pagination={{
                pageSize: pageSize,
                onChange: (e: number, b: number) => setPageSize(b),
                showSizeChanger: true,
                pageSizeOptions: _page_size_options,
                hideOnSinglePage: data.length < _hide_when_less_than
            }}
        />
    )
}
