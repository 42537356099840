import CoinsAndTokensView from '../views/coins_and_tokens/CoinsAndTokensView';
import ConfigurationBlockedCardsView from '../views/configuration/blocked_cards/ConfigurationBlockedCardsView';
import ConfigurationBlockedTerritoriesView from '../views/configuration/blocked_territories/ConfigurationBlockedTerritoriesView';
import ConfigurationFeeConfigView from '../views/configuration/fee_config/ConfigurationFeeConfigView';
import ConfigurationRolesView from '../views/configuration/roles/ConfigurationRolesView';
import HomeView from '../views/home/HomeView';
import MobilumPartnersView from '../views/mobilum_partners/list/MobilumPartnersView';
import NotificationsView from '../views/notifications/NotificationsView';
import PartnerAdminsView from '../views/partner_admins/list/PartnerAdminsView';
import ResetPasswordView from '../views/reset_password/ResetPasswordView';
import TransactionsWalletView from '../views/wallets/TransactionsWalletView';
import WalletsView from '../views/wallets/WalletsView';
import { navPath } from '../_common/NavPath';
import { TransactionHistory } from '../views/transactions/transaction_history/TransactionHistory';
import { UserRiskContent } from '../views/configuration/risk_level/UserRiskContent';
import ConfigurationCoinsAndTokensView from '../views/configuration/coins_&_tokens/ConfigurationCoinsAndTokensView';
import { WiredTransactions } from '../views/transactions/wired_transactions/WiredTransactions';
import AntiFraudDetailsView from '../views/antifraud/AntiFraudDetailsView';
import AntiFraudDetailsItem from '../views/antifraud/details/AntiFraudDetailsItem';

export const displayContent = (path_key: string) => {
    switch (path_key) {
        case 'coins_and_tokens': {
            return <CoinsAndTokensView />;
        }
        case 'registered_users': {
            return <CoinsAndTokensView />;
        }
        case 'reset_password': {
            return <ResetPasswordView />;
        }
        case 'partner_admins': {
            return <PartnerAdminsView />;
        }
        case 'mobilum_partners': {
            return <MobilumPartnersView />;
        }
        case 'configuration_roles': {
            return <ConfigurationRolesView />;
        }
        case 'configuration_coins_and_tokens': {
            return <ConfigurationCoinsAndTokensView />;
        }
        case 'configuration_blocked_cards': {
            return <ConfigurationBlockedCardsView />;
        }
        case 'configuration_blocked_territories': {
            return <ConfigurationBlockedTerritoriesView />;
        }
        case 'configuration_fee_config': {
            return <ConfigurationFeeConfigView />;
        }
        case 'transactions_transaction_history': {
            return <TransactionHistory />;
        }
        case 'transactions_wire_transactions': {
            return <WiredTransactions />;
        }
        case 'wallets': {
            return <WalletsView />;
        }
        case 'transactions_wallet': {
            return <TransactionsWalletView />;
        }
        case 'notifications': {
            return <NotificationsView />;
        }
        case 'configuration_user_risk_level': {
            return <UserRiskContent />;
        }
        case 'anti_fraud_view': {
            return <AntiFraudDetailsView />;
        }
        case 'anti_fraud_item': {
            return <AntiFraudDetailsItem />;
        }
        default: {
            return <HomeView />
        }
    }
}

export const getListOfAllowedNavPaths = () => {
    const blocked_paths = [
        'mobilum_partners_id',
        'partner_admins_id',
        'configuration_role_id',
        'order_details_id',
        'user_profile_id',
        'user_history_id',
        'set_password',
        'wallets_id',
        'transactions_wallet_params'
    ];

    const list: Array<string> = [];

    {
        (Object.keys(navPath) as (keyof typeof navPath)[]).map(key => {
            if (!blocked_paths.includes(key))
                list.push(key);
        });
    }

    return list;
}

export const getFirstPagesOptions = () => {
    let options: Array<any> = [];

    getListOfAllowedNavPaths().map(x => {
        let option = { key: x, value: x, label: 'Coins & Tokens View' };

        switch (x) {
            case 'coins_and_tokens': {
                option.label = 'Coins & Tokens View';
                break;
            }
            case 'registered_users': {
                option.label = 'Registered Users View';
                break;
            }
            case 'reset_password': {
                option.label = 'Reset Password View';
                break;
            }
            case 'partner_admins': {
                option.label = 'Partner Admins View';
                break;
            }
            case 'mobilum_partners': {
                option.label = 'Mobilum Partners View';
                break;
            }
            case 'configuration_roles': {
                option.label = 'Configuration - Roles View';
                break;
            }
            case 'configuration_coins_and_tokens': {
                option.label = 'Configuration - Coins & Tokens View';
                break;
            }
            case 'configuration_blocked_cards': {
                option.label = 'Configuration - Blocked Cards View';
                break;
            }
            case 'configuration_blocked_territories': {
                option.label = 'Configuration - Blocked Territories View';
                break;
            }
            case 'configuration_fee_config': {
                option.label = 'Configuration - Fee Config View';
                break;
            }
            case 'transactions_transaction_history': {
                option.label = 'Transactions - Transaction History View';
                break;
            }
            case 'transactions_pending_transactions': {
                option.label = 'Transactions - Pending Transactions View';
                break;
            }
            case 'transactions_delivered_transactions': {
                option.label = 'Transactions - Delivered Transactions View';
                break;
            }
            case 'transactions_failed_transactions': {
                option.label = 'Transactions - Failed Transactions View';
                break;
            }
            case 'transactions_settlement_transactions': {
                option.label = 'Transactions - Settement Transactions View';
                break;
            }
            case 'transactions_wire_transactions': {
                option.label = 'Transactions - Wire Transactions View';
                break;
            }
            case 'wallets': {
                option.label = 'Wallets View';
                break;
            }
            case 'transactions_wallet': {
                option.label = 'Transactions - Wallet View';
                break;
            }
            case 'notifications': {
                option.label = 'Notifications View';
                break;
            }
            case 'configuration_user_risk_level': {
                option.label = 'Configuration - User Risk Level View';
                break;
            }
            default: {
                return;
            }
        }

        option.label && options.push(option);
    });

    return options;
}

export const getNavPath = (first_page?: string) => {
    let path = navPath.home;

    if (first_page)
        (Object.keys(navPath) as (keyof typeof navPath)[]).map(key => {
            if (first_page === key) {
                path = navPath[key];
            }
        });

    return path;
}
