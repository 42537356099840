import { Button, Switch, Table, Tooltip } from "antd";
import { memo, useState } from "react";
import { Link } from "react-router-dom";
import { _default_page_size, _hide_when_less_than, _page_size_options } from "../../_heplers/GlobalValues";
import { SettingOutlined } from '@ant-design/icons';
export const AntiFraudTable = (props: any) => {
  const {
      data,
      changeStatus,
      redirectToDetails,
  } = props;

  const [pageSize, setPageSize] = useState(_default_page_size);


  const columns: any[] = [
    {
      title: 'Status',
      key: 'isActive',
      render: (record: any) => (
        <Switch
          checked={record.isActive}
          onChange={() => changeStatus(record)}
        />
      ),
    },
    {
      title: 'Filter Name',
      key: 'limitType',
      render: (record: any) => record.limitType?.name
    },
    {
      title: 'Configure',
      key: 'configure',
      render: (record: any) => (
        <>
          <Tooltip placement="rightTop" title="Configure">
            <Link
              to={redirectToDetails(record)}
            >
              <Button
                  size='small'
                  type='text'
                  icon={<SettingOutlined style={{ fontSize: '22px', color: 'black' }} />}
              />
            </Link>
            </Tooltip>
        </>
      ),
    },
  ];

  return (
      <Table
          bordered
          size='small'
          columns={columns}
          dataSource={data}
          rowKey={record => record.limitType.id}
          scroll={{ x: 500 }}
          pagination={{
              pageSize: pageSize,
              onChange: (e: number, b: number) => setPageSize(b),
              showSizeChanger: true,
              pageSizeOptions: _page_size_options,
              hideOnSinglePage: data.length < _hide_when_less_than
          }}
      />
  )
}

export default memo(AntiFraudTable)