import React from 'react';
import { Button, Form, Input } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { openErrorNotification, openSuccessNotification } from '../../../_heplers/NotificationHelper';
import { validateMessages } from '../../../_common/ValidationCominications';
import { resetPassword } from '../../../_services/auth/AuthenticationServices';
import { passwordValidator } from '../../../_heplers/RegexHelper';
export class ResetPasswordForm extends React.Component<any> {
    _is_mounted = true;
    _form_ref = React.createRef<FormInstance>();
    state = {
        loading: false
    }

    public render() {
        return (
            <div className="content-wrapper">
                <Form
                    layout='vertical'
                    name='control-ref'
                    onFinish={this.onSubmit}
                    ref={this._form_ref}
                    validateMessages={validateMessages()}
                >
                    <>
                        <Form.Item
                            label='Old Password'
                            name='oldPassword'
                            rules={[{ required: true }]}
                        >
                            <Input.Password allowClear />
                        </Form.Item>
                        <Form.Item
                            label='New Password'
                            name='newPassword'
                            rules={[{ required: true }, { pattern: passwordValidator() }]}
                        >
                            <Input.Password allowClear />
                        </Form.Item>
                        <Form.Item
                            label="Confirm New Password"
                            name="confirm_new_password"
                            dependencies={['newPassword']}
                            rules={[
                                { required: true },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('newPassword') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('The passwords do not match!'));
                                    }
                                })
                            ]}
                        >
                            <Input.Password allowClear />
                        </Form.Item>
                    </>
                    <div className='grid_col_2 action-buttons'>
                        <Button
                            className='cancel_button common_previous_button'
                            htmlType='button'
                            onClick={() => this.props.navigate(-1)}
                        >
                            Cancel
                        </Button>
                        <Form.Item shouldUpdate={true}>
                            {() => (
                                <Button
                                    htmlType='submit'
                                    disabled={this.showSuimtuBtton() || this.state.loading}
                                    className="ant-btn ant-btn-primary common_next_button"
                                >
                                    Save
                                </Button>
                            )}
                        </Form.Item>
                    </div>
                </Form>
            </div>
        );
    };

    onSubmit = (values: any) => {
        this.setState({loading: true})
        let payload = {
            newPassword: values.newPassword,
            oldPassword: values.oldPassword,
            login: this.props.context?.app_user_inf?.login
        }
        resetPassword(payload).then((response) => {
            if(response.status === 200 && !response.data?.errors) {
                openSuccessNotification('Password was successfully updated')
                this.props.navigate(-1)
            } else openErrorNotification('An error occurred')
        }).finally(() => this.setState({loading: false}))
    }

    showSuimtuBtton = () => {
        if (this._form_ref.current) {
            const errors = this._form_ref.current!.getFieldsError().filter(({ errors }) => errors.length).length;
            return !!errors;
        }
        return true;
    }
}
