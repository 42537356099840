import React from 'react';
import { IFirstPage } from '../../../_common/interfaces/IFirstPage';
import { INavigate } from '../../../_common/interfaces/INavigate';
import { ISetBlockUi, ISetIsAuthenticated } from '../../../_common/app_context_provider/AppContextProvider';
import { LoginForm } from '../forms/LoginForm';
import { LoginLogoComponent } from '../../../_common/components/login_logo/LoginLogoComponent';

export class LoginContent extends React.Component<ISetIsAuthenticated & ISetBlockUi & INavigate & IFirstPage> {
    public render() {
        return (
            <>
                <LoginForm
                    setBlockUi={this.props.setBlockUi}
                    setIsAuthenticated={this.props.setIsAuthenticated}
                    navigate={this.props.navigate}
                    first_page={this.props.first_page}
                />
                <LoginLogoComponent />
            </>
        );
    }
}
