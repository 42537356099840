import { Button } from 'antd';
import { createAbsoluteUrl } from '../../../_heplers/UrlCreatorHelpres';
import { navPath } from '../../../_common/NavPath';
import { useNavigate } from 'react-router-dom';

const CodeNotFoundForm = () => {
    const navigate = useNavigate();
    
    return (
        <Button
            type='primary'
            htmlType='submit'
            className='__submit_button'
            onClick={() => navigate(createAbsoluteUrl(navPath.home))}
            >
                Go to Login
        </Button>
    )
}
export default CodeNotFoundForm;

 