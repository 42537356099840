import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../_common/app_context_provider/AppContextProvider';
import { ResetPasswordContent } from './content/ResetPasswordContent';
const ResetPasswordView = () => {
    const context = useContext(AppContext)
    const navigate = useNavigate()
    return (
        <ResetPasswordContent context={context} navigate={navigate}/>
    )
}
export default ResetPasswordView;
