import CoinsAndTokensView from './coins_and_tokens/CoinsAndTokensView';
import ConfigurationBlockedCardsView from './configuration/blocked_cards/ConfigurationBlockedCardsView';
import ConfigurationBlockedTerritoriesView from './configuration/blocked_territories/ConfigurationBlockedTerritoriesView';
import ConfigurationFeeConfigView from './configuration/fee_config/ConfigurationFeeConfigView';
import ConfigurationRolesView from './configuration/roles/ConfigurationRolesView';
import ForgotPasswordView from './forgot_password/ForgotPasswordView';
import LoginView from './login/LoginView';
import MainMenu from '../_common/components/menu/MainMenu';
import MobilumPartnerDetailsView from './mobilum_partners/details/MobilumPartnerDetailsView';
import MobilumPartnersView from './mobilum_partners/list/MobilumPartnersView';
import NotFoundView from './not_found_view/NotFoundView';
import NotificationsView from './notifications/NotificationsView';
import PartnerAdminDetailsView from './partner_admins/details/PartnerAdminDetailsView';
import PartnerAdminsView from './partner_admins/list/PartnerAdminsView';
import RegisteredUsersView from './registered_users/RegisteredUsersView';
import ReportsView from './reports/ReportsView';
import ResetPasswordView from './reset_password/ResetPasswordView';
import SetPassword from './set_password/SetPasswordView';
import TransactionsWalletView from './wallets/TransactionsWalletView';
import WalletsView from './wallets/WalletsView';
import { AppContext } from '../_common/app_context_provider/AppContextProvider';
import { AuthenticatedView, UnAuthenticatedView } from '../_heplers/AuthenticatedHelper';
import { displayContent } from '../_heplers/DisplayViewHelper';
import { HeaderComponent } from '../_common/components/header/HeaderComponent';
import { Layout } from 'antd';
import { navPath } from '../_common/NavPath';
import { OrderDetails } from './transactions/order_details/OrderDetails';
import { Route, Routes } from 'react-router-dom';
import { SpinPage } from '../_common/components/spinPage/SpinPage';
import { TransactionHistory } from './transactions/transaction_history/TransactionHistory';
import { useContext } from 'react';
import { UserHistory } from './transactions/order_details/UserHistory';
import { UserProfile } from './transactions/order_details/UserProfile';
import { UserRiskContent } from './configuration/risk_level/UserRiskContent';
import { WiredTransactions } from './transactions/wired_transactions/WiredTransactions';
import ConfigurationCoinsAndTokensView from './configuration/coins_&_tokens/ConfigurationCoinsAndTokensView';
import AntiFraudDetailsView from './antifraud/AntiFraudDetailsView';
import AntiFraudDetailsItem from './antifraud/details/AntiFraudDetailsItem';
import AntifraudBlockedUsersView from './antifraudBlockedUsers/AntifraudBlockedUsersView';

const { Content } = Layout;

const MainView = () => {
  const validatePermission = useContext(AppContext).validatePermission;
  const firstPage = useContext(AppContext).app_user_inf.firstPage;
  const listOfPermissions = useContext(AppContext).app_user_inf.permissions;
  return (
    <Layout>
      <SpinPage>
        <AuthenticatedView shouldLogoutPath={navPath.set_password}>
          <HeaderComponent />
          <Layout>
            <MainMenu />
            <Layout>
              <Content className="content">
                <Routes>
                  <Route path="/" element={displayContent(firstPage)} />
                  <Route path={navPath.home} element={displayContent(firstPage)} />
                  {validatePermission('PurchasableTokens') && <Route path={navPath.coins_and_tokens} element={<CoinsAndTokensView />} />}
                  {validatePermission('ReadClientList') && <Route path={navPath.registered_users} element={<RegisteredUsersView />} />}
                  {validatePermission('CanChangePassword') && <Route path={navPath.reset_password} element={<ResetPasswordView />} />}
                  {validatePermission('ReadUserList') && <Route path={navPath.partner_admins} element={<PartnerAdminsView />} />}
                  <Route path={navPath.partner_admins_id} element={<PartnerAdminDetailsView />} />
                  {validatePermission('ReadClientList') && <Route path={navPath.anti_fraud_blocked_users} element={<AntifraudBlockedUsersView />} />}
                  {validatePermission('Sites') && <Route path={navPath.mobilum_partners} element={<MobilumPartnersView />} />}
                  {validatePermission('Sites') && listOfPermissions?.includes('SuperAdmin') && <Route path={navPath.anti_fraud_id} element={<AntiFraudDetailsView />} />}
                  {validatePermission('Sites') && listOfPermissions?.includes('SuperAdmin') && <Route path={navPath.anti_fraud_details} element={<AntiFraudDetailsItem />} />}
                  <Route path={navPath.mobilum_partners_id} element={<MobilumPartnerDetailsView />} />
                  {validatePermission('CanEditRoles') && <Route path={navPath.configuration_roles} element={<ConfigurationRolesView />} />}
                  {validatePermission('ManageTokens') && <Route path={navPath.configuration_coins_and_tokens} element={<ConfigurationCoinsAndTokensView />} />}
                  {validatePermission('ReadFraudCards') && <Route path={navPath.configuration_blocked_cards} element={<ConfigurationBlockedCardsView />} />}
                  {validatePermission('ManageTerritories') && <Route path={navPath.configuration_blocked_territories} element={<ConfigurationBlockedTerritoriesView />} />}
                  {validatePermission('ManageFees') && <Route path={navPath.configuration_fee_config} element={<ConfigurationFeeConfigView />} />}
                  {validatePermission('ReadTransactionList') && <Route path={navPath.transactions_transaction_history} element={<TransactionHistory />} />}
                  {validatePermission('CanReadWireTransferTransactions') && <Route path={navPath.transactions_wire_transactions} element={<WiredTransactions />} />}
                  {validatePermission('CanReadWireTransferTransactions') && <Route path={navPath.wallets} element={<WalletsView />} />}
                  {validatePermission('CanReadWireTransferTransactions') && <Route path={navPath.wallets_id} element={<WalletsView />} />}
                  {validatePermission('CanReadWireTransferTransactions') && <Route path={navPath.transactions_wallet} element={<TransactionsWalletView />} />}
                  {validatePermission('CanReadWireTransferTransactions') && <Route path={navPath.transactions_wallet_params} element={<TransactionsWalletView />} />}
                  <Route path={navPath.user_profile_id} element={<UserProfile />} />
                  <Route path={navPath.user_history_id} element={<UserHistory />} />
                  <Route path={navPath.order_details_id} element={<OrderDetails />} />
                  {validatePermission('ManageEmailTemplates') && <Route path={navPath.notifications} element={<NotificationsView />} />}
                  <Route path={navPath.reports} element={<ReportsView />} />
                  {validatePermission('ManageRiskConfigs') && <Route path={navPath.configuration_user_risk_level} element={<UserRiskContent />} />}
                  <Route path="*" element={<NotFoundView />} />
                </Routes>
              </Content>
            </Layout>
          </Layout>
        </AuthenticatedView>
        <UnAuthenticatedView>
          <Layout>
            <Content className="content_1">
              <Routes>
                <Route path={navPath.login} element={<LoginView />} />
                <Route
                  path={navPath.forgot_password}
                  element={<ForgotPasswordView />}
                />
                <Route path={navPath.set_password} element={<SetPassword />} />
                <Route path="*" element={<LoginView />} />
              </Routes>
            </Content>
          </Layout>
        </UnAuthenticatedView>
      </SpinPage>
    </Layout>
  );
}
export default MainView;