import { AppContext } from '../../../_common/app_context_provider/AppContextProvider';
import { Breadcrumb, Tabs } from 'antd';
import { KycDetails } from './KycDetails';
import { PageTitleComponent } from '../../../_common/components/page_title/PageTitleComponent';
import { RiskManagement } from './RiskManagement';
import { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UserHistory } from './UserHistory';
import { UserTab } from './UserTab';

const { TabPane } = Tabs;

export const UserProfile = () => {
  const { id, type }: any = useParams();
  const validatePermission = useContext(AppContext).validatePermission;

  const navigate = useNavigate();

  return (
    <>
      {type == 'order' ? (
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <a onClick={() => navigate(-2)}>Transaction History</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <a onClick={() => navigate(-1)}>Order Details</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>User Profile</Breadcrumb.Item>
        </Breadcrumb>
      ) : (
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <a onClick={() => navigate(-1)}>{type}</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>User Profile</Breadcrumb.Item>
        </Breadcrumb>
      )}
      <PageTitleComponent page_title="User Profile" />
      <Tabs defaultActiveKey="1" size={'large'}>
        <TabPane tab="User Details" key="1">
          <UserTab id={id} />
        </TabPane>
        {validatePermission('ViewRiskLevels') && (
          <TabPane tab="Risk Management" key="2">
            <RiskManagement id={id} />
          </TabPane>
        )}
        <TabPane tab="KYC Details" key="3">
          <KycDetails id={id} />
        </TabPane>
        {validatePermission('ReadTransactionList') && (
        <TabPane tab="User Transaction History" key="4">
          <UserHistory id={id} />
        </TabPane>
        )}
      </Tabs>
    </>
  );
};
