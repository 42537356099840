import axios from "../api";

const url = 'api/BlockedRegion';

export const getBlockedRegionsServices = () =>
  axios.get(url)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.log('--------------- getBlockedRegionsServices', error.message);

      return [];
    });

export const createBlockedRegionsServices = (body: any) =>
  axios.post(url, body)
    .then(response => {
      return response;
    })
    .catch(error => {
      console.log('--------------- createBlockedRegionsServices', error.message);

      return undefined;
    });

export const deleteBlockedRegionsServices = (id: number) =>
  axios.delete(`${url}/${id}`)
    .then(() => true)
    .catch(error => {
      console.log('--------------- deleteBlockedRegionsServices', error.message);

      return false;
    });