import React from 'react';
import styles from '../styles/ConfigurationFeeConfig.module.scss';
import { AddNewButtonComponent } from '../../../../_common/components/add_new_button/AddNewButtonComponent';
import { ClearBoth } from '../../../../_common/components/clearBoth/ClearBoth';
import { ConfigurationFeeConfigForm } from '../forms/ConfigurationFeeConfigForm';
import { ConfigurationFeeConfigTable } from '../tables/ConfigurationFeeConfigTable';
import { deleteFeeConfigService, getFeeConfigsService } from '../../../../_services/fee_config/FeeConfigServices';
import { IConfigurationFeeConfigContentProps } from '../interfaces/ConfigurationFeeConfigContentProps';
import { IFeeConfig } from '../interfaces/IFeeConfig';
import { Left } from '../../../../_common/components/left/Left';
import { loading_icon } from '../../../../_common/components/loading/LoadingIcon';
import { Modal, Row, Spin } from 'antd';
import { openErrorNotification, openSuccessNotification } from '../../../../_heplers/NotificationHelper';
import { PageTitleComponent } from '../../../../_common/components/page_title/PageTitleComponent';
import { Right } from '../../../../_common/components/right/Right';

export class ConfigurationFeeConfigContent extends React.Component<IConfigurationFeeConfigContentProps> {
    _is_mounted = true;

    state = {
        loading: true,
        fee_configs: Array<IFeeConfig>(),
        // Modal
        show_modal: false,
        loading_modal: false,
        modal_data: {} as IFeeConfig
    };

    componentDidMount = () => {
        this.getFeeConfigs();
    }

    componentWillUnmount = () => {
        this._is_mounted = false;
    }

    public render() {
        const {
            loading,
            fee_configs,
            // Modal
            show_modal,
            modal_data
        } = this.state;

        const {
            dictionaries,
            loading_dictionaries,
            available_sites
        } = this.props;

        return (
            <>
                <Row align='middle' justify='space-between'>
                    <Left>
                        <PageTitleComponent page_title='Fee Configs' />
                    </Left>
                    <Right>
                        <AddNewButtonComponent
                            title="Add New"
                            onClick={() => this.setModalVisible(true)}
                        />
                    </Right>
                </Row>
                <ClearBoth />
                <Spin className='spin_60' spinning={loading} indicator={loading_icon(40)}>
                    <ConfigurationFeeConfigTable
                        data={fee_configs}
                        dictionaries={dictionaries}
                        loading_dictionaries={loading_dictionaries}
                        updateObjectInArray={this.editFeeConfig}
                        deleteObjectFromArray={this.deleteFeeConfig}
                    />
                </Spin>
                <Modal
                    title={undefined}
                    visible={show_modal}
                    closable={false}
                    footer={false}
                    width={700}
                    destroyOnClose
                >
                    <div className={styles.modal_content}>
                        <div className={styles._modal_title}>
                            {modal_data?.id ? 'Edit' : 'Add New'}
                        </div>
                        <ConfigurationFeeConfigForm
                            data={modal_data}
                            available_sites={available_sites}
                            dictionaries={dictionaries}
                            loading_dictionaries={loading_dictionaries}
                            setModalVisible={this.setModalVisible}
                            updateObjectInArray={this.updateObjectInArray}
                            addObjectToArray={this.addObjectToArray}
                        />
                    </div>
                </Modal>
            </>
        );
    }

    setModalVisible = (show_modal: boolean) => {
        if (!show_modal) {
            this.setState({ modal_data: undefined });
        }

        this.setState({ show_modal });
    }

    getFeeConfigs = async () => {
        this.setState({ loading: true });

        await getFeeConfigsService()
            .then(response => {
                const fee_configs = response.map((x: any) => {
                    return {
                        key: x.id,
                        ...x
                    }
                });

                this._is_mounted && this.setState({ fee_configs });
            })
            .finally(() => this._is_mounted && this.setState({ loading: false }));
    }

    editFeeConfig = (modal_data: any) => {
        this.setState({
            modal_data,
            show_modal: true
        });
    }

    addNewRole = () => {
        this.setState({
            modal_data: undefined,
            show_modal: true
        });
    }

    deleteFeeConfig = async (fee_config: IFeeConfig) => {
        await deleteFeeConfigService(fee_config.id)
            .then(response => {
                if (response) {
                    const fee_configs = this.state.fee_configs.filter((x: any) => x.id !== fee_config.id);

                    this.setState({ fee_configs });

                    openSuccessNotification('Fee config removed');
                }
                else{
                    openErrorNotification(`Service doesn't work`);
                }
            });
    }

    updateObjectInArray = (fee_config: IFeeConfig) => {
        const fee_configs: Array<IFeeConfig> = this.state.fee_configs.map((x: IFeeConfig) => {
            if (x.id == fee_config.id) {
                return fee_config;
            }
            else {
                return x;
            }
        });

        this._is_mounted && this.setState({
            fee_configs,
            modal_data: undefined,
            show_modal: false
        });
    }

    addObjectToArray = (fee_config: IFeeConfig) => {
        const fee_configs = [fee_config, ...this.state.fee_configs];

        this._is_mounted && this.setState({
            fee_configs,
            modal_data: undefined,
            show_modal: false
        });
    }
}
