import Column from 'antd/lib/table/Column';
import moment from 'moment';
import { _default_page_size, _hide_when_less_than, _page_size_options } from '../../../_heplers/GlobalValues';
import { createAbsoluteUrl } from '../../../_heplers/UrlCreatorHelpres';
import { getUserHistory } from '../../../_services/transactions/TransactionServices';
import { Link } from 'react-router-dom';
import { navPath } from '../../../_common/NavPath';
import { Space, Table } from 'antd';
import { useEffect, useState } from 'react';

export const UserHistory = (props: any) => {
  const [list, setList] = useState([]);
  const [pageSize, setPageSize] = useState(_default_page_size);
  const { id }: any = props;
  const common_link_style = { fontSize: '10px', color: 'black' };

  useEffect(() => {
    getUserHistory(id)
      .then((response: any) => {
        setList(response);
      })
      .catch(e => { console.log('---- error ', e.message) });
  }, []);

  return (
    <>
      <Table
        scroll={{ y: 460 }}
        dataSource={list}
        bordered
        size="middle"
        style={{ marginTop: '20px', borderRadius: '20px' }}
        pagination={{
          pageSize: pageSize,
          onChange: (e: number, b: number) => setPageSize(b),
          showSizeChanger: true,
          pageSizeOptions: _page_size_options,
          hideOnSinglePage: list && list.length < _hide_when_less_than
        }}
      >

        <Column
          title={() => <a style={common_link_style}>{'Transaction ID'}</a>}
          dataIndex="id"
          key="id"
          width={300}
          sorter={{
            compare: (a: any, b: any) => a.id.localeCompare(b.id),
          }}
          render={(text, record: any) => (
            <Link
              to={createAbsoluteUrl(`${navPath.order_details}/${record.id}`)}
            >
              <Space>
                <a>{record.id}</a>
              </Space>
            </Link>
          )}
        />
        <Column
          title={() => (
            <a style={common_link_style}>{'Status'}</a>
          )}
          dataIndex="orderStatus"
          key="orderStatus"
          sorter={{
            compare: (a: any, b: any) =>
              a.orderStatus.localeCompare(b.orderStatus),
          }}
          render={(text, record: any) => (
            <a style={common_link_style}>
              {record.orderStatus}
            </a>
          )}
        />
        <Column
          title={() => (
            <a style={common_link_style}>
              {'Transaction Date'}
            </a>
          )}
          dataIndex="txDate"
          key="txDate"
          sorter={{
            compare: (a: any, b: any) => a.txDate.localeCompare(b.txDate),
          }}
          render={(text, record: any) => (
            <a style={common_link_style}>
              {moment(record.txDate).format('DD MMM YY')}
            </a>
          )}
        />
        <Column
          title={() => (
            <a style={common_link_style}>
              {'Amount Purchased'}
            </a>
          )}
          dataIndex="orderAmount"
          key="orderAmount"
          sorter={{
            compare: (a: any, b: any) =>
              a.orderAmount.localeCompare(b.orderAmount),
          }}
          render={(text, record: any) => (
            <a style={common_link_style}>
              {record.orderAmount + ' ' + record.cryptoCurrency}
            </a>
          )}
        />
        <Column
          title={() => (
            <a style={common_link_style}>{'Price'}</a>
          )}
          dataIndex="price"
          key="price"
          sorter={{
            compare: (a: any, b: any) => a.price.localeCompare(b.price),
          }}
          render={(text, record: any) => (
            <a style={common_link_style}>
              {(parseInt(record.price) - parseInt(record.commission)).toFixed(
                2,
              ) +
                ' ' +
                record.currency}
            </a>
          )}
        />
        <Column
          title={() => (
            <a style={common_link_style}>
              {'Processing Fees'}
            </a>
          )}
          dataIndex="comission"
          key="comission"
          width={120}
          sorter={{
            compare: (a: any, b: any) => a.comission.localeCompare(b.comission),
          }}
          render={(text, record: any) => (
            <a style={common_link_style}>
              {parseFloat(record.commission).toFixed(2) + ' ' + record.currency}
            </a>
          )}
        />
        <Column
          title={() => (
            <a style={common_link_style}>{'Total Cost'}</a>
          )}
          dataIndex="cost"
          key="cost"
          sorter={{
            compare: (a: any, b: any) => a.cost.localeCompare(b.cost),
          }}
          render={(text, record: any) => (
            <a style={common_link_style}>
              {parseInt(record.price) + ' ' + record.currency}
            </a>
          )}
        />
        <Column
          title={() => (
            <a style={common_link_style}>
              {'Wallet Address'}
            </a>
          )}
          dataIndex="userWallet"
          key="userWallet"
          sorter={{
            compare: (a: any, b: any) =>
              a.userWallet.localeCompare(b.userWallet),
          }}
          render={(text, record: any) => (
            <a style={common_link_style}>
              {record.userWallet}
            </a>
          )}
        />
        <Column
          title={() => (
            <a style={common_link_style}>
              {'Transaction Hash'}
            </a>
          )}
          dataIndex="userWallet"
          key="userWallet"
          sorter={{
            compare: (a: any, b: any) =>
              a.userWallet.localeCompare(b.userWallet),
          }}
          render={(text, record: any) => (
            <a style={common_link_style}>
              -
            </a>
          )}
        />
      </Table>
    </>
  );
};
