import { useNavigate } from 'react-router-dom';
import { ForgotPasswordContent } from './content/ForgotPasswordContent';
import {useContext} from "react";
import {AppContext} from "../../_common/app_context_provider/AppContextProvider";

const ForgotPasswordView = () => {
    const context = useContext(AppContext);
    const navigate = useNavigate();
    return (
        <ForgotPasswordContent context={context} navigate={navigate}/>
    )
}

export default ForgotPasswordView;