import InputForm from '../../../../_common/components/forms/InputForm';
import React from 'react';
import {
    Button,
    Col,
    Form,
    Row,
    Select,
    Spin,
    Switch
} from 'antd';
import { FormInstance } from 'antd/lib/form';
import { loading_icon } from '../../../../_common/components/loading/LoadingIcon';
import { validateMessages } from '../../../../_common/ValidationCominications';

export class ConfigurationCoinsAndTokensForm extends React.Component<any> {
    _is_mounted = true;
    _form_ref = React.createRef<FormInstance>();

    state = {
        loading: false,
        message: undefined
    };

    componentWillUnmount = () => {
        this._is_mounted = false;
    };

    public render() {
        const { loading } = this.state;
        const { setModalVisible, context } = this.props;
        const tokenTypes = context?.dictionaries?.token_types;
        const crypto_sources = context?.dictionaries?.crypto_source;

        return (
            <Spin spinning={loading} indicator={loading_icon(60)}>
                <Form
                    layout='vertical'
                    name='control-ref'
                    onFinish={this.onFinish}
                    ref={this._form_ref}
                    validateMessages={validateMessages()}
                    className='modal_content'
                    initialValues={{ ...this.props.data }}
                >
                    <Row gutter={[32, 0]}>

                        <Col span={12}>
                            <Form.Item
                                name='name'
                                rules={[{ required: true }]}
                            >
                                <InputForm
                                    label='Cryptocurrency'
                                    placeholder='enter cryptocurrency'
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name='displayTicker'
                                rules={[{ required: true }]}
                            >
                                <InputForm
                                    label='Display Ticker'
                                    placeholder='enter display ticker'
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name='ticker'
                                rules={[{ required: true }]}
                            >
                                <InputForm
                                    label='Ticker'
                                    placeholder='enter ticker'
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name='validator'
                            >
                                <InputForm
                                    label='Validator'
                                    placeholder='enter validator'
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name='validatorRegex'
                            >
                                <InputForm
                                    label='Validator Regex'
                                    placeholder='enter validator regex'
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Token Type"
                                name='type'
                                rules={[{ required: true }]}
                            >

                                <Select
                                    showSearch
                                    filterOption={(input: any, option: any) => (option.props.children ? option.props.children : "").toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {tokenTypes && tokenTypes.map((token: any) => (
                                        <Select.Option key={token.value} value={token.value}>{token.name}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Crypto Source"
                                name='cryptoSource'
                            >
                                <Select
                                    allowClear
                                    placeholder="Crypto source"
                                    onChange={(value: any) => this.clearValue(value)}
                                    showSearch
                                >
                                    {crypto_sources && crypto_sources.map((crypto: any) => (
                                        <Select.Option key={crypto.value} value={crypto.name}>{crypto.name}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="buyUrl"
                                rules={[{ type: 'url' }]}
                                label="Buy Url"
                            >
                                <InputForm placeholder="enter buy url" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name='minimumOrder'
                                rules={[{ required: true }]}
                            >
                                <InputForm
                                    label='Minimum Order'
                                    type='number'
                                    placeholder='enter minimum order'
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name='defaultDisplayAmount'
                            >
                                <InputForm
                                    label='Default Display Amount'
                                    type='number'
                                    placeholder='enter default display amount'
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name='precision'
                            >
                                <InputForm
                                    label='Precision'
                                    type='number'
                                    placeholder='enter precision'
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[32, 0]}>
                        <Col span={12}>
                            <Form.Item
                                label="Is Active"
                                name="isAvailable"
                                valuePropName='checked'
                                className='switch_style'
                                style={{ flexDirection: 'row' }}
                            >
                                <Switch />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="ErC20Token"
                                name="erC20Token"
                                valuePropName='checked'
                                className='switch_style'
                                style={{ flexDirection: 'row' }}
                            >
                                <Switch />
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className='grid_col_2'>
                        <Button
                            className='common_cancel_button common_previous_button'
                            onClick={() => setModalVisible(false)}
                        >
                            Cancel
                        </Button>
                        <Form.Item shouldUpdate={true}>
                            {() => (
                                <Button
                                    className='common_submit_button common_next_button'
                                    htmlType='submit'
                                    type='primary'
                                    style={{ width: '100%' }}
                                    disabled={this.showSuimtuBtton()}
                                >
                                    Save
                                </Button>
                            )}
                        </Form.Item>
                    </div>
                </Form>
            </Spin>
        );
    }
    clearValue = (value: any) => {
        // use to clear the dropdown as it's value doesn't clear automatically 
        if (!value) {
            this._form_ref.current?.setFieldsValue({
                'cryptoSource': null
            });
        }
    }

    showSuimtuBtton = () => {
        if (this._form_ref.current) {
            const errors = this._form_ref.current
                .getFieldsError()
                .filter(({ errors }) => errors.length).length;

            // Check if error
            if (errors) {
                return true;
            }
            else {
                return false;
            }
        }

        return true;
    };

    onFinish = (values: any) => {
        this.setState({ loading: true });
        const {
            addOrUpdateObject
        } = this.props;
        let role = {
            ...values,
            id: this.props.data.id || 0,
            isAvailable: values.isAvailable || false,
            erC20Token: values.erC20Token || false,
            minimumOrder: parseFloat(values.minimumOrder),
            defaultDisplayAmount: parseFloat(values.defaultDisplayAmount),
            precision: parseInt(values.precision),
            isCrypto: true
        };

        setTimeout(() => {
            addOrUpdateObject(role);
            this.setState({ loading: false });
        }, 1000);
    };
}
