import CodeNotFoundForm from '../forms/CodeNotFoundForm';
import SetPasswordForm from '../forms/SetPasswordForm';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { verifyOtpCode } from '../../../_services/auth/AuthenticationServices';

const SetPasswordContent = () => {
    const [validCode, setValidCode] = useState(false);
    const [loading, setLoading] = useState(true);
    // get email and code which comes from email link
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const email = searchParams.get('email');
    const code = searchParams.get('code');
    useEffect(() => {
        verifyOtpCode(code, email)
            .then((response) => (response.status === 200 && !response.data.errors?.length) && setValidCode(true))
            .finally(() => setLoading(false));
    }, [code, email]);

    return (
        <div className='common_form_1'>
            <div className='_form_content'>
                <div className='__form_title'>
                    Set New Password
                </div>
                <div className='__form_subtitle'>
                    {validCode ? 'We’ll ask for this password whenever you sign in' : 'Wrong confirmation code or confirmation code is expired'}
                </div>
                {validCode && !loading ? <SetPasswordForm code={code} /> : <CodeNotFoundForm />}
            </div>
        </div>
    );
}
export default SetPasswordContent
