import React from 'react';
import { Breadcrumb, Button, Spin } from 'antd';
import { createAbsoluteUrl } from '../../../../_heplers/UrlCreatorHelpres';
import { getUser } from '../../../../_services/users/UsersServices';
import { IId } from '../../../../_common/interfaces/IId';
import { Link } from 'react-router-dom';
import { loading_icon } from '../../../../_common/components/loading/LoadingIcon';
import { navPath } from '../../../../_common/NavPath';
import { PageTitleComponent } from '../../../../_common/components/page_title/PageTitleComponent';
import { PartnerAdminForm } from '../forms/PartnerAdminForm';

export class PartnerAdminDetailsContent extends React.Component<IId & any> {
    _is_mounted = true;

    state = {
        loading: false,
        edited: false,
        partner_admin_details: {
            first_name: undefined,
            last_name: undefined,
            email: undefined,
            access_level: undefined,
            client_account: undefined
        }
    }

    componentDidMount = () => {
        this.props.id !== 'new' && this.getPartnerAdminInf();
        this.setState({ edited: this.props.id !== 'new' });
    }

    componentWillUnmount = () => {
        this._is_mounted = false;
    }

    public render() {
        const { id, context, navigate } = this.props;
        const { partner_admin_details, loading, edited } = this.state;

        return (
            <>
                <Breadcrumb separator=">">
                    <Breadcrumb.Item>
                        <Link to='#' onClick={() => navigate(-1)}>Users</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {id === 'new' ? 'Add New User' : 'Edit User'}
                    </Breadcrumb.Item>
                </Breadcrumb>
                <PageTitleComponent page_title={id === 'new' ? 'Add New Partner Admin' : 'Edit Partner Admin'} />
                <Spin className='spin_60' spinning={loading} indicator={loading_icon(40)}>
                    {!loading && <PartnerAdminForm data={partner_admin_details} edited={edited} context={context} navigate={navigate} />}
                </Spin>
            </>
        );
    }

    getPartnerAdminInf = async () => {
        this.setState({ loading: true });

        await getUser(this.props.id)
            .then((response) => this.setState({ partner_admin_details: response.data }))
            .finally(() => this.setState({ loading: false }))
    }
}
