import React from 'react';
import SelectForm from '../../../../_common/components/form_components/select/SelectForm';
import styles from '../styles/MobilumPartnerDetails.module.scss';
import { Button, Form, InputNumber } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { IMobilumPartnerDetailsCommercialTermsFormProps } from '../interfaces/IMobilumPartnerDetailsCommercialTermsFormProps';
import { validateMessages } from '../../../../_common/ValidationCominications';

export class MobilumPartnerDetailsCommercialTermsForm extends React.Component<IMobilumPartnerDetailsCommercialTermsFormProps> {
	_form_ref = React.createRef<FormInstance>();
	state = {
		regionFees: [
			{ name: 'EU', value: null, action: null },
			{ name: 'ROW', value: null, action: null },
		],
	};

	componentDidMount = () => {
		const { regionFees } = this.props.mobilum_partner_details;
		const updatedRegionFees = this.state.regionFees.map((fee) => {
			const updatedFee = regionFees?.find((r) => r.name === fee.name);
			return updatedFee || fee;
		});
		this.setState({ regionFees: updatedRegionFees });
	};

	public render() {
		const { dictionaries, loading_dictionaries, mobilum_partner_details } =
			this.props;

		const filtered_dictionaries_currencies = dictionaries.currencies?.filter(
			(x: any) => x.name !== 'NULL'
		);

		return (
			<Form
				layout='horizontal'
				name='control-ref'
				ref={this._form_ref}
				onFinish={this.onFinish}
				validateMessages={validateMessages()}
				initialValues={{ ...mobilum_partner_details }}
				className='flex'
				style={{ flexDirection: 'column', height: '100%' }}
			>
				<div className='column_description'>Commercial Terms</div>
				<div
					className='flex'
					style={{ marginBottom: 53 }}
				>
					<div
						className={styles.bordered_content}
						style={{ minWidth: 278 }}
					>
						<div
							className='section_description'
							style={{ marginBottom: 24 }}
						>
							Spread
						</div>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'flex-end',
							}}
						>
							<div />
							<Form.Item
								label='Main (margin)'
								name={['Spread', 'main']}
								rules={[{ required: true }]}
								colon={false}
							>
								<InputNumber
									min={0}
									precision={2}
									style={{
										width: '88px',
										height: '32px',
										borderColor: '#A3B0BF',
									}}
									formatter={(value) => `${value}%`}
									parser={(value: any) => value.replace('%', '')}
								/>
							</Form.Item>
							<Form.Item
								label='Stable Coin (margin)'
								name={['Spread', 'stableCoin']}
								rules={[{ required: true }]}
								colon={false}
							>
								<InputNumber
									min={0}
									precision={2}
									style={{
										width: '88px',
										height: '32px',
										borderColor: '#A3B0BF',
									}}
									formatter={(value) => `${value}%`}
									parser={(value: any) => value.replace('%', '')}
								/>
							</Form.Item>
							<Form.Item
								label='Altcoin (margin)'
								name={['Spread', 'rest']}
								rules={[{ required: true }]}
								colon={false}
							>
								<InputNumber
									min={0}
									precision={2}
									style={{
										width: '88px',
										height: '32px',
										borderColor: '#A3B0BF',
									}}
									formatter={(value) => `${value}%`}
									parser={(value: any) => value.replace('%', '')}
								/>
							</Form.Item>
							<Form.Item
								label='Base Currency'
								name={['Fees', 'baseCurrency']}
								rules={[{ required: true }]}
								colon={false}
							>
								<SelectForm
									placeholder='select'
									loading={loading_dictionaries}
									data={filtered_dictionaries_currencies}
									filterOption={(input: any, option: any) =>
										(option.props.children ? option.props.children : '')
											.toLowerCase()
											.indexOf(input.toLowerCase()) >= 0
									}
								/>
							</Form.Item>
							<hr style={{ width: '100%' }} />
							<div className='section_description'
								style={{
									textAlign: 'left',
									width: '100%',
									marginBottom: 24,
								}}
							>
								Processing Fees
							</div>
							{this.state.regionFees.map((region: any) => (
								<Form.Item
									label={region.name === 'EU' ? 'EU' : 'Rest of the world'}
									key={region.name}
									rules={[{ required: false }]}
									colon={false}
								>
									<InputNumber
										min={0}
										max={100}
										precision={2}
										value={region.value}
										onChange={(value) => this.onRegionFeeUpdate(region, value)}
										style={{
											width: '88px',
											height: '32px',
											borderColor: '#A3B0BF',
										}}
										formatter={(value) => `${value}%`}
										parser={(value: any) => value.replace('%', '')}
									/>
								</Form.Item>
							))}
							<Form.Item
								label='Processing Fee'
								name={['Fees', 'feePercentage']}
								rules={[{ required: true }]}
								colon={false}
							>
								<InputNumber
									min={0}
									max={100}
									precision={2}
									style={{
										width: '88px',
										height: '32px',
										borderColor: '#A3B0BF',
									}}
									formatter={(value) => `${value}%`}
									parser={(value: any) => value.replace('%', '')}
								/>
							</Form.Item>
							<Form.Item
								label='Minimum Fee (USD)'
								name={['Fees', 'fixed']}
								rules={[{ required: true }]}
								colon={false}
							>
								<InputNumber
									min={0}
									precision={2}
									style={{
										width: '88px',
										height: '32px',
										borderColor: '#A3B0BF',
									}}
									formatter={(value: any) =>
										`$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
									}
									parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
								/>
							</Form.Item>
						</div>
					</div>
					<div style={{ marginLeft: 15 }}>
						<div
							className={styles.bordered_content}
							style={{ marginBottom: 10 }}
						>
							<div
								className='section_description'
								style={{ marginBottom: 24 }}
							>
								Chargebacks Fees ($USD)
							</div>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'flex-end',
								}}
							>
								<Form.Item
									label='Chargeback Handling'
									name='siteHandlingFee'
									style={{ alignItems: 'center' }}
									rules={[{ required: true }]}
									colon={false}
								>
									<InputNumber
										precision={2}
										style={{
											width: '88px',
											height: '32px',
											borderColor: '#A3B0BF',
										}}
										formatter={(value: any) =>
											`$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
										}
										parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
									/>
								</Form.Item>
								<Form.Item
									label='Approved Chargeback'
									name='siteAcceptanceFee'
									style={{ alignItems: 'center' }}
									rules={[{ required: true }]}
									colon={false}
								>
									<InputNumber
										precision={2}
										style={{
											width: '88px',
											height: '32px',
											borderColor: '#A3B0BF',
										}}
										formatter={(value: any) =>
											`$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
										}
										parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
									/>
								</Form.Item>
							</div>
						</div>
						<div className={styles.bordered_content}>
							<div
								className='section_description'
								style={{ marginBottom: 24 }}
							>
								Service Level Agreement ($USD)
							</div>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'flex-end',
								}}
							>
								<Form.Item
									label='Monthly Service Fee'
									name='siteMonthlyFee'
									style={{ alignItems: 'center' }}
									rules={[{ required: true }]}
									colon={false}
								>
									<InputNumber
										precision={2}
										style={{
											width: '88px',
											height: '32px',
											borderColor: '#A3B0BF',
										}}
										formatter={(value: any) =>
											`$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
										}
										parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
									/>
								</Form.Item>
								<Form.Item
									label='Min Month Volume'
									name='siteMinMonthlyVolume'
									style={{ alignItems: 'center' }}
									rules={[{ required: true }]}
									colon={false}
								>
									<InputNumber
										precision={2}
										style={{
											width: '88px',
											height: '32px',
											borderColor: '#A3B0BF',
										}}
										formatter={(value: any) =>
											`$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
										}
										parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
									/>
								</Form.Item>
							</div>
						</div>
					</div>
				</div>

				<div
					className='flex grid_col_2'
					style={{ marginTop: 'auto' }}
				>
					<Button
						className='common_previous_button'
						onClick={() => this.props.setCurrentStep(1)}
					>
						Previous
					</Button>
					<Form.Item shouldUpdate={true}>
						{() => (
							<Button
								type='primary'
								htmlType='submit'
								className='common_next_button'
								disabled={this.showSuimtuBtton()}
							>
								Next
							</Button>
						)}
					</Form.Item>
				</div>
			</Form>
		);
	}

	showSuimtuBtton = () => {
		if (this._form_ref.current) {
			const errors = this._form_ref.current
				.getFieldsError()
				.filter(({ errors }) => errors.length).length;

			// Check if error
			if (errors) {
				return true;
			} else {
				return false;
			}
		}

		return true;
	};

	onRegionFeeUpdate = (region: any, updatedValue: number) => {
		this.setState((prevState: any) => {
			const updatedRegionFees = prevState.regionFees?.map((item: any) => {
				if (item.name === region.name) {
					return {
						...item,
						value: updatedValue,
						action: !item.regionFeeUUID
							? 'new'
							: updatedValue
							? 'edit'
							: 'delete',
					};
				}
				return item;
			});
        const { id } = this.props;

			return { regionFees: updatedRegionFees };
		});
	};

	onFinish = (values: any) => {
		let { setCurrentStep, updateState, mobilum_partner_details } = this.props;
        const { id } = this.props;

		mobilum_partner_details = {
			...mobilum_partner_details,
			...values,
			regionFees: [
				...this.state.regionFees.filter(
					(region) =>
						region.value || (!region.value && region.action === 'delete')
				),
			],
		};
		updateState(mobilum_partner_details);
		let setCurrentStepVal = (id === 'new' ? 4 : 3);
		setCurrentStep(setCurrentStepVal);
	};
}
