import axios from '../api';

export const getAvailableTokensServices = async () =>
  await axios.get('api/SiteApi/ListAvailableTokens')
export const getAvailableToken = (id: number) =>
  axios.get(`api/SiteApi/AvailableToken/${id}`)
export const createAvailableTokensServices = (payload: any) =>
  axios.put('api/SiteApi/AvailableToken', payload)
    .then(response => {
      return response.data;
    })
    .catch(() => {
      return {};
    });
export const updateAvailableTokensServices = (payload: any) =>
  axios.patch('api/SiteApi/AvailableToken', payload)
    .then(response => {
      return response.data;
    })
    .catch(() => {
      return {};
    });
export const setActiveToken = (data: any) =>
  axios.patch(`api/SiteApi/AvailableToken/${data.id_account}?isActive=${data.isActive}`)
    .then(response => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });

//APIs for nonAdmin
export const listPurchasableTokens = (siteId: string) => {
  const params = { siteId }
  return axios.get('api/SiteApi/ListPurchasableTokens', { params })
};
export const setPurchasableTokenAvailable = (data: any) => {
  const params = { siteUuid: data.siteUuid, id: data.id, isAvailable: data.isAvailable }
  return axios.post('api/SiteApi/SetPurchasableTokenAvailable', null, { params })
};