import React from 'react';
import { MobilumPartnerDetailsWiretransferFeesFormProps } from '../interfaces/MobilumPartnerDetailsWiretransferFeesFormProps';
import { Button, Checkbox, Col, Form, FormInstance, Input, InputNumber, Modal, Row, Space, Table, TableProps, Tag } from 'antd';

import { getAll, create, deleteSiteTier, update } from '../../../../_services/site_tiers/SiteTiers'
import { ISiteTier } from '../interfaces/ISiteTier';
import { validateMessages } from '../../../../_common/ValidationCominications';
import { ICreateSiteTierModel } from '../interfaces/ICreateSiteTierModel';
import { IUpdateSiteTierModel } from '../interfaces/IUpdateSiteTierModel';
import { openSuccessNotification } from '../../../../_heplers/NotificationHelper';

interface DataType {
    id: number;
    key: string;
    name: string;
    transfersNumberFrom: number;
    transfersNumberTo: number;
    sepaIncomingPercentage: number;
    sepaIncomingMinimumFee: number;
    sepaOutgoingPercentage: number;
    sepaOutgoingMinimumFee: number;
    swiftIncomingPercentage: number;
    swiftIncomingMinimumFee: number;
    swiftOutgoingPercentage: number;
    swiftOutgoingMinimumFee: number;
    activeIbanValuePerIban: number;
}

export class MobilumPartnerDetailsWiretransferFeesForm extends React.Component<MobilumPartnerDetailsWiretransferFeesFormProps> {
    _form_ref = React.createRef<FormInstance>();
    _create_site_form_tier = React.createRef<FormInstance>();
    _edit_site_form_tier = React.createRef<FormInstance<IUpdateSiteTierModel>>();

    state = {
        site_tiers: [] as ISiteTier[],
        isCreateModalOpen: false,
        isEditModalOpen: false,
        editingSiteTier: {} as IUpdateSiteTierModel,
        isDeleteModalVisible: false,
        deletingItemId: -1 as number
    };

    componentDidMount = () => {
        this.getSiteTiers();
    }

    getSiteTiers = async () => {
        const { siteUuid } = this.props;
        await getAll(siteUuid)
            .then(site_tiers => {
                this.setState({ site_tiers })
            })
            .finally(() => { });
    }

    setIsCreateModalOpen = (isCreateModalOpen: number) => {
        this.setState({ isCreateModalOpen });
    }



    onEditSiteTierFinish = async (values: IUpdateSiteTierModel) => {
        // Logic to update the site tier
        const { editingSiteTier } = this.state;
        const updatedSiteTier = { ...editingSiteTier, ...values };

        // Call the update function from the server
        await update(updatedSiteTier)
            .then(() => {
                openSuccessNotification('Site Tier Updated');
                // After successful update, fetch site tiers again
                this.getSiteTiers();
                this.setState({ isEditModalOpen: false, editingSiteTier: {} as IUpdateSiteTierModel });
            })
            .catch(error => {
                // Handle error
                console.error('Error updating site tier:', error);
                // Optionally, notify the user about the error
            });
    };

    handleEditSiteTier = (updateSiteTierModel: IUpdateSiteTierModel) => {
        this.setState({ isEditModalOpen: true, editingSiteTier: updateSiteTierModel });
    };

    handleEditModalOk = () => {
        this._edit_site_form_tier.current?.submit();
    };

    handleEditModalCancel = () => {
        this._edit_site_form_tier.current?.resetFields();
        this.setState({ isEditModalOpen: false, editingSiteTier: {} as IUpdateSiteTierModel });
    };

    // handleDeleteSiteTier = async (siteTierId: number) => {
    //     // Call deleteSiteTier function from the server
    //     await deleteSiteTier(siteTierId)
    //         .then(() => {
    //             openSuccessNotification('Site Tier Deleted');
    //             // After successful deletion, fetch site tiers again
    //             this.getSiteTiers();
    //         })
    //         .catch(error => {
    //             // Handle error
    //             console.error('Error deleting site tier:', error);
    //             // Optionally, notify the user about the error
    //         });
    // };

    handleDeleteSiteTier = (id: number) => {
        this.setState({ isDeleteModalVisible: true, deletingItemId: id });
    };

    handleConfirmDelete = async () => {
        // Perform deletion here using this.state.deletingItemId
        await deleteSiteTier(this.state.deletingItemId)
            .then(() => {
                openSuccessNotification('Site Tier Deleted');
                // After successful deletion, fetch site tiers again
                this.getSiteTiers();
            })
            .catch(error => {
                // Handle error
                console.error('Error deleting site tier:', error);
                // Optionally, notify the user about the error
            });

        // Then close the modal and reset the state
        this.setState({ isDeleteModalVisible: false, deletingItemId: -1 });
    };

    handleCancelDelete = () => {
        // Cancel deletion, close the modal, and reset the state
        this.setState({ isDeleteModalVisible: false, deletingItemId: -1 });
    };

    public render() {
        const { site_tiers, isCreateModalOpen, isEditModalOpen, editingSiteTier, isDeleteModalVisible } = this.state;

        const { mobilum_partner_details, siteUuid } = this.props;

        const columns: TableProps<DataType>['columns'] = [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                width: 150,
                fixed: 'left',
                render: (text) => <p>{text}</p>,
            },
            {
                title: 'Transfers Number From',
                dataIndex: 'transfersNumberFrom',
                key: 'transfersNumberFrom',
                width: 180,
            },
            {
                title: 'Transfers Number To',
                dataIndex: 'transfersNumberTo',
                key: 'transfersNumberTo',
                width: 180,
            },
            {
                title: 'Sepa Incoming Minimum Fee',
                dataIndex: 'sepaIncomingMinimumFee',
                key: 'sepaIncomingMinimumFee',
                width: 230,
            },
            {
                title: 'Sepa Outgoing Percentage',
                dataIndex: 'sepaOutgoingPercentage',
                key: 'sepaOutgoingPercentage',
                width: 230,
            },
            {
                title: 'Sepa Outgoing Minimum Fee',
                dataIndex: 'sepaOutgoingMinimumFee',
                key: 'sepaOutgoingMinimumFee',
                width: 230,
            },
            {
                title: 'Swift Incoming Percentage',
                dataIndex: 'swiftIncomingPercentage',
                key: 'swiftIncomingPercentage',
                width: 230,
            },
            {
                title: 'Swift Incoming Minimum Fee',
                dataIndex: 'swiftIncomingMinimumFee',
                key: 'swiftIncomingMinimumFee',
                width: 230,
            },
            {
                title: 'Swift Outgoing Percentage',
                dataIndex: 'swiftOutgoingPercentage',
                key: 'swiftOutgoingPercentage',
                width: 230,
            },
            {
                title: 'Swift Outgoing Minimum Fee',
                dataIndex: 'swiftOutgoingMinimumFee',
                key: 'swiftOutgoingMinimumFee',
                width: 230,
            },
            {
                title: 'Value Per Iban',
                dataIndex: 'activeIbanValuePerIban',
                key: 'activeIbanValuePerIban',
                width: 140,
            },
            {
                title: 'Action',
                dataIndex: 'action',
                key: 'action',
                width: 180,
                fixed: 'right',
                render: (_, record: IUpdateSiteTierModel) => (
                    <Space size="middle">
                        <Button onClick={() => this.handleEditSiteTier(record)}>Edit</Button>
                        <Button danger onClick={() => this.handleDeleteSiteTier(record.id)}>Delete</Button>
                        <Modal
                            title="Confirm Delete"
                            visible={isDeleteModalVisible}
                            onOk={this.handleConfirmDelete}
                            onCancel={this.handleCancelDelete}
                        >
                            <p>Are you sure you want to delete this item?</p>
                        </Modal>
                    </Space>
                ),
            },
        ];

        const data: DataType[] = [

        ];

        const transformSiteTier = (siteTier: ISiteTier): DataType => {
            return {
                key: siteTier.siteTierUuid,
                id: siteTier.id,
                name: siteTier.name,
                transfersNumberFrom: siteTier.transfersNumberFrom,
                transfersNumberTo: siteTier.transfersNumberTo,
                sepaIncomingPercentage: siteTier.sepaIncomingPercentage,
                sepaIncomingMinimumFee: siteTier.sepaIncomingMinimumFee,
                sepaOutgoingPercentage: siteTier.sepaOutgoingPercentage,
                sepaOutgoingMinimumFee: siteTier.sepaOutgoingMinimumFee,
                swiftIncomingPercentage: siteTier.swiftIncomingPercentage,
                swiftIncomingMinimumFee: siteTier.swiftIncomingMinimumFee,
                swiftOutgoingPercentage: siteTier.swiftOutgoingPercentage,
                swiftOutgoingMinimumFee: siteTier.swiftOutgoingMinimumFee,
                activeIbanValuePerIban: siteTier.activeIbanValuePerIban
            };
        };


        const showModal = () => {
            this.setState({ isCreateModalOpen: true })
        };

        const handleOk = () => {
            this._create_site_form_tier.current?.submit();
        };

        const handleCancel = () => {
            this._create_site_form_tier.current?.resetFields();
            this.setState({ isCreateModalOpen: false })
        };

        const transformedData = site_tiers.map(transformSiteTier);
        const tableHeight = transformedData.length * 55 + 55;
        const tableHeightVal = tableHeight > 350 ? 350 : undefined;

        return (
            <Form
                layout='horizontal'
                name='control-ref'
                ref={this._form_ref}
                onFinish={this.onFinish}
                validateMessages={validateMessages()}
                initialValues={{ ...mobilum_partner_details }}
                className='flex'
                style={{ flexDirection: 'column', height: '100%' }}
            >
                <div>
                    <div className='column_description'>{this.props.title}</div>
                    <Button type="primary" style={{ marginBottom: '8px', float: 'right' }} onClick={showModal}>Add Site Tier</Button>
                    <Modal
                        width={700}
                        title="Add Site Tier"
                        visible={isCreateModalOpen}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        footer={[
                            <Button key="back" onClick={handleCancel}>
                                Cancel
                            </Button>,
                            <Button key="submit" type="primary" onClick={handleOk}>
                                Create
                            </Button>
                        ]}>
                        <Form<ICreateSiteTierModel>
                            name="create-site-tier"
                            ref={this._create_site_form_tier}
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            initialValues={{ siteUuid }}

                            onFinish={this.onCreateSiteTierFinish}
                            onFinishFailed={this.onCreateSiteTierFinishFailed}
                            validateMessages={validateMessages()}
                            autoComplete="off"
                            layout="vertical"
                        >
                            <Form.Item
                                label="siteUuid"
                                name="siteUuid"
                                hidden={true}
                            >
                                <Input />
                            </Form.Item>
                            <Row gutter={16}>
                                <Col className="gutter-row" span={12}>
                                    <Form.Item
                                        label="Name"
                                        name="name"
                                        rules={[{ required: true }]}
                                        labelCol={{ span: 24 }}
                                    >
                                        <Input style={{ width: '200px', height: '32px', borderRadius: '8px', borderColor: '#A3B0BF' }} />
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row" span={12}>
                                    <Form.Item
                                        label="Value Per Iban"
                                        name="activeIbanValuePerIban"
                                        labelCol={{ span: 24 }}
                                    >
                                        <InputNumber
                                            precision={2}
                                            style={{
                                                width: '140px',
                                                height: '32px',
                                                borderColor: '#A3B0BF',
                                            }}
                                            formatter={(value: any) =>
                                                `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                            }
                                            parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row >
                            <Row gutter={16}>
                                <Col className="gutter-row" span={12}>
                                    <Form.Item
                                        label="Transfers Number From"
                                        name="transfersNumberFrom"
                                        rules={[{ required: true }]}
                                        labelCol={{ span: 24 }}
                                    >
                                        <InputNumber style={{ width: '200px', height: '32px', borderRadius: '8px', borderColor: '#A3B0BF' }} />

                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row" span={12}>
                                    <Form.Item
                                        label="Transfers Number To"
                                        name="transfersNumberTo"
                                        rules={[{ required: true }]}
                                        labelCol={{ span: 24 }}
                                    >
                                        <InputNumber type='number' style={{ width: '200px', height: '32px', borderRadius: '8px', borderColor: '#A3B0BF' }} />
                                    </Form.Item>
                                </Col>
                            </Row >
                            <Row gutter={16}>
                                <Col className="gutter-row" span={12}>
                                    <Form.Item
                                        label="Sepa Incoming Percentage"
                                        name="sepaIncomingPercentage"
                                        rules={[{ required: true }]}
                                        labelCol={{ span: 24 }}
                                    >
                                        <InputNumber
                                            min={0}
                                            precision={2}
                                            style={{
                                                width: '140px',
                                                height: '32px',
                                                borderColor: '#A3B0BF',
                                            }}
                                            formatter={(value) => `${value}%`}
                                            parser={(value: any) => value.replace('%', '')}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row" span={12}>
                                    <Form.Item
                                        label="Sep Incoming Minimum Fee"
                                        name="sepaIncomingMinimumFee"
                                        rules={[{ required: true }]}
                                        labelCol={{ span: 24 }}
                                    >
                                        <InputNumber
                                            precision={2}
                                            style={{
                                                width: '140px',
                                                height: '32px',
                                                borderColor: '#A3B0BF',
                                            }}
                                            formatter={(value: any) =>
                                                `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                            }
                                            parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row >
                            <Row gutter={16}>
                                <Col className="gutter-row" span={12}>
                                    <Form.Item
                                        label="Sepa Outgoing Percentage"
                                        name="sepaOutgoingPercentage"
                                        rules={[{ required: true }]}
                                        labelCol={{ span: 24 }}
                                    >
                                        <InputNumber
                                            min={0}
                                            precision={2}
                                            style={{
                                                width: '140px',
                                                height: '32px',
                                                borderColor: '#A3B0BF',
                                            }}
                                            formatter={(value) => `${value}%`}
                                            parser={(value: any) => value.replace('%', '')}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row" span={12}>
                                    <Form.Item
                                        label="Sepa Outgoing Minimum Fee"
                                        name="sepaOutgoingMinimumFee"
                                        rules={[{ required: true }]}
                                        labelCol={{ span: 24 }}
                                    >
                                        <InputNumber
                                            precision={2}
                                            style={{
                                                width: '140px',
                                                height: '32px',
                                                borderColor: '#A3B0BF',
                                            }}
                                            formatter={(value: any) =>
                                                `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                            }
                                            parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row >
                            <Row gutter={16}>
                                <Col className="gutter-row" span={12}>
                                    <Form.Item
                                        label="Swift Incoming Percentage"
                                        name="swiftIncomingPercentage"
                                        rules={[{ required: true }]}
                                        labelCol={{ span: 24 }}
                                    >
                                        <InputNumber
                                            min={0}
                                            precision={2}
                                            style={{
                                                width: '140px',
                                                height: '32px',
                                                borderColor: '#A3B0BF',
                                            }}
                                            formatter={(value) => `${value}%`}
                                            parser={(value: any) => value.replace('%', '')}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row" span={12}>
                                    <Form.Item
                                        label="Swift Incoming Minimum Fee"
                                        name="swiftIncomingMinimumFee"
                                        rules={[{ required: true }]}
                                        labelCol={{ span: 24 }}
                                    >
                                        <InputNumber
                                            precision={2}
                                            style={{
                                                width: '140px',
                                                height: '32px',
                                                borderColor: '#A3B0BF',
                                            }}
                                            formatter={(value: any) =>
                                                `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                            }
                                            parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row >
                            <Row gutter={16}>
                                <Col className="gutter-row" span={12}>
                                    <Form.Item
                                        label="Swift Outgoing Percentage"
                                        name="swiftOutgoingPercentage"
                                        rules={[{ required: true }]}
                                        style={{ width: '100%' }}
                                        labelCol={{ span: 24 }}
                                    >
                                        <InputNumber
                                            min={0}
                                            precision={2}
                                            style={{
                                                width: '140px',
                                                height: '32px',
                                                borderColor: '#A3B0BF',
                                            }}
                                            formatter={(value) => `${value}%`}
                                            parser={(value: any) => value.replace('%', '')}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row" span={12}>
                                    <Form.Item
                                        label="Swift Outgoing Minimum Fee"
                                        name="swiftOutgoingMinimumFee"
                                        rules={[{ required: true }]}
                                        labelCol={{ span: 24 }}
                                    >
                                        <InputNumber
                                            precision={2}
                                            style={{
                                                width: '140px',
                                                height: '32px',
                                                borderColor: '#A3B0BF',
                                            }}
                                            formatter={(value: any) =>
                                                `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                            }
                                            parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row >
                        </Form>
                    </Modal>
                    {isEditModalOpen ?
                        <Modal
                            width={700}
                            title="Edit Site Tier"
                            visible={isEditModalOpen}
                            onOk={this.handleEditModalOk}
                            onCancel={this.handleEditModalCancel}
                            maskClosable={true}
                            footer={[
                                <Button key="back" onClick={this.handleEditModalCancel}>
                                    Cancel
                                </Button>,
                                <Button key="submit" type="primary" onClick={this.handleEditModalOk}>
                                    Save
                                </Button>
                            ]}
                        >
                            <Form<IUpdateSiteTierModel>
                                name="edit-site-tier"
                                ref={this._edit_site_form_tier}
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                                initialValues={editingSiteTier}

                                onFinish={this.onEditSiteTierFinish}
                                validateMessages={validateMessages()}
                                autoComplete="off"
                                layout="vertical"
                            >
                                <Form.Item
                                    label="id"
                                    name="id"
                                    hidden={true}
                                >
                                    <Input />
                                </Form.Item>
                                <Row gutter={16}>
                                    <Col className="gutter-row" span={12}>
                                        <Form.Item
                                            label="Name"
                                            name="name"
                                            rules={[{ required: true }]}
                                            labelCol={{ span: 24 }}
                                        >
                                            <Input style={{ width: '200px', height: '32px', borderRadius: '8px', borderColor: '#A3B0BF' }} />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" span={12}>
                                        <Form.Item
                                            label="Value Per Iban"
                                            name="activeIbanValuePerIban"
                                            labelCol={{ span: 24 }}
                                        >
                                            <InputNumber
                                                precision={2}
                                                style={{
                                                    width: '140px',
                                                    height: '32px',
                                                    borderColor: '#A3B0BF',
                                                }}
                                                formatter={(value: any) =>
                                                    `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                                }
                                                parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row >
                                <Row gutter={16}>
                                    <Col className="gutter-row" span={12}>
                                        <Form.Item
                                            label="Transfers Number From"
                                            name="transfersNumberFrom"
                                            rules={[{ required: true }]}
                                            labelCol={{ span: 24 }}
                                        >
                                            <InputNumber style={{ width: '200px', height: '32px', borderRadius: '8px', borderColor: '#A3B0BF' }} />

                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" span={12}>
                                        <Form.Item
                                            label="Transfers Number To"
                                            name="transfersNumberTo"
                                            rules={[{ required: true }]}
                                            labelCol={{ span: 24 }}
                                        >
                                            <InputNumber type='number' style={{ width: '200px', height: '32px', borderRadius: '8px', borderColor: '#A3B0BF' }} />
                                        </Form.Item>
                                    </Col>
                                </Row >
                                <Row gutter={16}>
                                    <Col className="gutter-row" span={12}>
                                        <Form.Item
                                            label="Sepa Incoming Percentage"
                                            name="sepaIncomingPercentage"
                                            rules={[{ required: true }]}
                                            labelCol={{ span: 24 }}
                                        >
                                            <InputNumber
                                                min={0}
                                                precision={2}
                                                style={{
                                                    width: '140px',
                                                    height: '32px',
                                                    borderColor: '#A3B0BF',
                                                }}
                                                formatter={(value) => `${value}%`}
                                                parser={(value: any) => value.replace('%', '')}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" span={12}>
                                        <Form.Item
                                            label="Sep Incoming Minimum Fee"
                                            name="sepaIncomingMinimumFee"
                                            rules={[{ required: true }]}
                                            labelCol={{ span: 24 }}
                                        >
                                            <InputNumber
                                                precision={2}
                                                style={{
                                                    width: '140px',
                                                    height: '32px',
                                                    borderColor: '#A3B0BF',
                                                }}
                                                formatter={(value: any) =>
                                                    `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                                }
                                                parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row >
                                <Row gutter={16}>
                                    <Col className="gutter-row" span={12}>
                                        <Form.Item
                                            label="Sepa Outgoing Percentage"
                                            name="sepaOutgoingPercentage"
                                            rules={[{ required: true }]}
                                            labelCol={{ span: 24 }}
                                        >
                                            <InputNumber
                                                min={0}
                                                precision={2}
                                                style={{
                                                    width: '140px',
                                                    height: '32px',
                                                    borderColor: '#A3B0BF',
                                                }}
                                                formatter={(value) => `${value}%`}
                                                parser={(value: any) => value.replace('%', '')}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" span={12}>
                                        <Form.Item
                                            label="Sepa Outgoing Minimum Fee"
                                            name="sepaOutgoingMinimumFee"
                                            rules={[{ required: true }]}
                                            labelCol={{ span: 24 }}
                                        >
                                            <InputNumber
                                                precision={2}
                                                style={{
                                                    width: '140px',
                                                    height: '32px',
                                                    borderColor: '#A3B0BF',
                                                }}
                                                formatter={(value: any) =>
                                                    `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                                }
                                                parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row >
                                <Row gutter={16}>
                                    <Col className="gutter-row" span={12}>
                                        <Form.Item
                                            label="Swift Incoming Percentage"
                                            name="swiftIncomingPercentage"
                                            rules={[{ required: true }]}
                                            labelCol={{ span: 24 }}
                                        >
                                            <InputNumber
                                                min={0}
                                                precision={2}
                                                style={{
                                                    width: '140px',
                                                    height: '32px',
                                                    borderColor: '#A3B0BF',
                                                }}
                                                formatter={(value) => `${value}%`}
                                                parser={(value: any) => value.replace('%', '')}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" span={12}>
                                        <Form.Item
                                            label="Swift Incoming Minimum Fee"
                                            name="swiftIncomingMinimumFee"
                                            rules={[{ required: true }]}
                                            labelCol={{ span: 24 }}
                                        >
                                            <InputNumber
                                                precision={2}
                                                style={{
                                                    width: '140px',
                                                    height: '32px',
                                                    borderColor: '#A3B0BF',
                                                }}
                                                formatter={(value: any) =>
                                                    `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                                }
                                                parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row >
                                <Row gutter={16}>
                                    <Col className="gutter-row" span={12}>
                                        <Form.Item
                                            label="Swift Outgoing Percentage"
                                            name="swiftOutgoingPercentage"
                                            rules={[{ required: true }]}
                                            style={{ width: '100%' }}
                                            labelCol={{ span: 24 }}
                                        >
                                            <InputNumber
                                                min={0}
                                                precision={2}
                                                style={{
                                                    width: '140px',
                                                    height: '32px',
                                                    borderColor: '#A3B0BF',
                                                }}
                                                formatter={(value) => `${value}%`}
                                                parser={(value: any) => value.replace('%', '')}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" span={12}>
                                        <Form.Item
                                            label="Swift Outgoing Minimum Fee"
                                            name="swiftOutgoingMinimumFee"
                                            rules={[{ required: true }]}
                                            labelCol={{ span: 24 }}
                                        >
                                            <InputNumber
                                                precision={2}
                                                style={{
                                                    width: '140px',
                                                    height: '32px',
                                                    borderColor: '#A3B0BF',
                                                }}
                                                formatter={(value: any) =>
                                                    `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                                }
                                                parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row >
                            </Form>
                        </Modal>
                        : null}
                    <Table
                        columns={columns}
                        dataSource={transformedData}
                        pagination={false}
                        scroll={{ y: tableHeightVal, x: 2410 }}
                    />
                </div>

                <div
                    className='flex grid_col_2'
                    style={{ marginTop: 'auto' }}
                >
                    <Button
                        className='common_previous_button'
                        onClick={() => this.props.setCurrentStep(2)}
                    >
                        Previous
                    </Button>
                    <Form.Item shouldUpdate={true}>
                        {() => (
                            <Button
                                type='primary'
                                htmlType='submit'
                                className='common_next_button'
                                disabled={this.showSubmitButton()}
                            >
                                Next
                            </Button>
                        )}
                    </Form.Item>
                </div>
            </Form>
        );
    }

    showSubmitSiteTierButton = () => {
        if (this._create_site_form_tier.current) {
            const errors = this._create_site_form_tier.current
                .getFieldsError()
                .filter(({ errors }) => errors.length).length;

            // Check if error
            if (errors) {
                return true;
            } else {
                return false;
            }
        }

        return true;
    }

    showSubmitButton = () => {
        if (this._form_ref.current) {
            const errors = this._form_ref.current
                .getFieldsError()
                .filter(({ errors }) => errors.length).length;

            // Check if error
            if (errors) {
                return true;
            } else {
                return false;
            }
        }

        return true;
    };

    onFinish = (values: any) => {
        let { setCurrentStep } = this.props;
        setCurrentStep(4);
    };

    onCreateSiteTierFinish = async (values: ICreateSiteTierModel) => {
        this._create_site_form_tier.current?.validateFields();

        const errors = this._create_site_form_tier.current!
            .getFieldsError()
            .filter(({ errors }) => errors.length).length;

        if (errors)
            return;
        let res;

        var result = await create(values)
            .then((result) => {
                res = result;
                openSuccessNotification('Site Tier Created');
            })
            .catch((err) => {
                // show error
            });
        //logic to add a tier

        //if success fetch site_tiers again
        await this.getSiteTiers()
        //if error notify the user about the error

        this._create_site_form_tier.current?.resetFields();
        this.setState({ isCreateModalOpen: false })
    };

    onCreateSiteTierFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };
}
