import axios from '../api';
import { IWireServiceGetAccountBalance } from './interfaces/IWireServiceGetAccountBalance';

const url = 'api/WireApi/GetMainBalance';

// Get account balance
export const GetMainBalance = (params: IWireServiceGetAccountBalance) =>
    axios.get(url, { params })
        .then(response => response.data.result)
        .catch(error => {
            console.log("--------------- getWitreServices", error.message);

            return { balance: undefined, availableBalance: undefined, currency: undefined }
        });
export const sendWithdraw = async (email: string, apiKey: string, amount: string, data: any) => {
    const payload = {
        amount: parseInt(amount),
        email,
        receiver: {
        ...data,
        }
    };
    return axios
    .post('api/WireApi/Withdraw', payload, {params: {apiKey}} )
    .then((response) => {
        return response.data;
    })
    .catch((error) => {
        console.log('--------------- userWithdraw', error.message);
        return undefined;
    });
    }