import InputForm from '../../../_common/components/forms/InputForm';
import React from 'react';
import SelectForm from '../../../_common/components/form_components/select/SelectForm';
import TextArea from 'antd/lib/input/TextArea';
import {
    Button,
    Form,
    Select,
    Spin
    } from 'antd';
import { createNotificationsService, updateNotificationsService } from '../../../_services/notifications/NotificationsServices';
import { FormInstance } from 'antd/lib/form';
import { INotification } from '../interfaces/INotification';
import { INotificationFormProps } from '../interfaces/INotificationFormProps';
import { loading_icon } from '../../../_common/components/loading/LoadingIcon';
import { openSuccessNotification } from '../../../_heplers/NotificationHelper';
import { validateMessages } from '../../../_common/ValidationCominications';

export class NotificationForm extends React.Component<INotificationFormProps> {
    _is_mounted = true;
    _form_ref = React.createRef<FormInstance>();

    state = {
        loading: false
    };

    componentWillUnmount = () => this._is_mounted = false;

    public render() {
        const { loading } = this.state;
        const {
            data,
            dictionaries,
            loading_dictionaries,
            available_sites,
            setModalVisible
        } = this.props;

        return (
            <Spin spinning={loading} indicator={loading_icon(60)}>
                <Form
                    layout='vertical'
                    name='control-ref'
                    onFinish={this.onFinish}
                    ref={this._form_ref}
                    initialValues={{ ...data }}
                    style={{ textAlign: 'left' }}
                    validateMessages={validateMessages()}
                >
                    <div className='grid_col_2' style={{ marginTop: 30 }}>
                        <Form.Item
                            label="Notification Type"
                            name='type'
                            rules={[{ required: true }]}
                        >
                            <SelectForm
                                placeholder='select'
                                loading={loading_dictionaries}
                                data={dictionaries.notification_types}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Partner"
                            name='siteUuid'
                        >
                            <SelectForm
                                placeholder='select'
                                data={available_sites}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Sender Name"
                            name='mailFromName'
                        >
                            <InputForm
                                placeholder='enter sender name'
                            />
                        </Form.Item>
                        <Form.Item
                            label="Sender Support Address"
                            name='defaultSupportAddress'
                        >
                            <InputForm
                                placeholder='enter sender support address'
                            />
                        </Form.Item>
                        <Form.Item
                            label="Sender No-Reply Address"
                            name='mailFrom'
                        >
                            <InputForm
                                placeholder='enter sender no-reply address'
                            />
                        </Form.Item>
                        <Form.Item
                            label="Bcc Copy Address"
                            name='bcc'
                        >
                            <InputForm
                                placeholder='enter bcc copy address'
                            />
                        </Form.Item>
                        <Form.Item
                            label="Language"
                            name='language'
                            rules={[{ required: true }]}
                        >
                            <InputForm
                                placeholder='enter language'
                            />
                        </Form.Item>
                        <Form.Item
                            name='subject'
                            label='Subject'
                            rules={[{ required: true }]}
                        >
                            <InputForm
                                placeholder='enter subject'
                            />
                        </Form.Item>
                    </div>

                    <Form.Item
                        label='Plain Text Body'
                        name='plainTextBody'
                        rules={[{ required: true }]}
                    >
                        <TextArea rows={4} />
                    </Form.Item>
                    <Form.Item
                        label='HTML Body'
                        name='htmlBody'
                        rules={[{ required: true }]}
                    >
                        <TextArea rows={4} />
                    </Form.Item>
                    <Form.Item
                        label="Disabled"
                        name='disabled'
                        rules={[{ required: true }]}
                    >
                        <Select
                            style={{ textAlign: 'left' }}
                            placeholder='select'
                            showSearch
                        >
                            <Select.Option key='yes' value={true}>Yes</Select.Option>
                            <Select.Option key='no' value={false}>No</Select.Option>
                        </Select>
                    </Form.Item>
                    <div className='grid_col_2'>
                        <Button
                            className='common_cancel_button'
                            onClick={() => setModalVisible(false)}
                        >
                            Cancel
                        </Button>
                        <Form.Item shouldUpdate={true}>
                            {() => (
                                <Button
                                    className='common_submit_button'
                                    htmlType='submit'
                                    type='primary'
                                    style={{ width: '100%' }}
                                    disabled={this.showSuimtuBtton()}
                                >
                                    Save
                                </Button>
                            )}
                        </Form.Item>
                    </div>
                </Form>
            </Spin>
        );
    }

    showSuimtuBtton = () => {
        if (this._form_ref.current) {
            const errors = this._form_ref.current
                .getFieldsError()
                .filter(({ errors }) => errors.length).length;

            // Check if error
            if (errors) {
                return true;
            }
            else {
                return false;
            }
        }

        return true;
    };

    onFinish = async (values: any) => {
        this.setState({ loading: true });

        const {
            data,
            updateObjectInArray,
            addObjectToArray,
            setModalVisible
        } = this.props;

        let notification: INotification = {
            ...data,
            disabled: values.disabled,
            htmlBody: values.htmlBody,
            language: values.language,
            plainTextBody: values.plainTextBody,
            siteUuid: values.siteUuid,
            subject: values.subject,
            type: values.type,
            mailFromName: values.mailFromName,
            defaultSupportAddress: values.defaultSupportAddress,
            mailFrom: values.mailFrom,
            bcc: values.bcc
        };

        if (this.props.data.id) {
            // Update notification
            await updateNotificationsService(notification)
                .then(response => {
                    if (response) {
                        updateObjectInArray!(notification);
                        openSuccessNotification(`notification ${notification.subject} updated`);
                        setModalVisible(false);
                    }
                })
                .finally(() => this._is_mounted && this.setState({ loading: false }));
        }
        else {
            // Add new notification
            await createNotificationsService(notification)
                .then(response => {
                    if (response) {
                        notification.id = Number(response);
                        notification.key = Number(response);
                        addObjectToArray!(notification);
                        openSuccessNotification(`notification ${notification.subject} created`);
                        setModalVisible(false);
                    }
                })
                .finally(() => this._is_mounted && this.setState({ loading: false }));
        }
    };
}
