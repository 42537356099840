import * as React from 'react';

function MenuFailedTransactions(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23 12.5C23 18.299 18.299 23 12.5 23S2 18.299 2 12.5 6.701 2 12.5 2 23 6.701 23 12.5zm-7.073 5.073l1.663-1.663-3.41-3.41 3.41-3.41-1.663-1.663-3.41 3.41-3.412-3.412L7.44 9.088l3.413 3.412-3.412 3.412 1.663 1.663 3.412-3.412 3.41 3.41z"
                fill="#A3B0BF"
            />
        </svg>
    );
}

const MemoMenuFailedTransactions = React.memo(MenuFailedTransactions);
export default MemoMenuFailedTransactions;
