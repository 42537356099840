import MenuLogout from '../../../images/components/MenuLogout';
import MenuResetPassword from '../../../images/components/MenuResetPassword';
import styles from './styles/HeaderComponent.module.scss';
import { AppContext } from '../../app_context_provider/AppContextProvider';
import { clearLocalStorage } from '../../../_heplers/LocalStorageHelpers';
import { Link } from 'react-router-dom';
import { navPath } from '../../NavPath';
import { useContext } from 'react';
import {
    Menu,
} from 'antd';
import { revokeToken } from '../../../_services/auth/AuthenticationServices';

export const ModalMenuContent = (props: any) => {
    const context = useContext(AppContext);

    return (
        <Menu>
            <Menu.Item key={navPath.reset_password} onClick={() => props.setVisible(false)}>
                <Link to={navPath.reset_password}><MenuResetPassword className={`${styles.menu_ico} ${styles.menu_ico_reset_password}`} />Reset Password</Link>
            </Menu.Item>
            <Menu.Item key={navPath.home} onClick={() => props.setVisible(false)}>
                <Link to={navPath.login}  onClick={() =>
                        logout(context.setBlockUi, context.setIsAuthenticated)
                    }>
                    <MenuLogout className={styles.menu_ico} />Logout
                </Link>
            </Menu.Item>
        </Menu>
    );
};

const logout = (
    setBlockUi: (block_ui: boolean) => void,
    setIsAuthenticated: (is_authenticated: boolean) => void
) => {
    setBlockUi(true);
    // TODO: Integrate with endpoint

    // Clear localstorage
    clearLocalStorage();
    revokeToken();

    setTimeout(() => {
        setBlockUi(false);
        setIsAuthenticated(false);
    }, 2000);
};
