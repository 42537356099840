import React from 'react';
import { Button, Form, Input } from 'antd';
import { createAbsoluteUrl } from '../../../_heplers/UrlCreatorHelpres';
import { FormInstance } from 'antd/lib/form';
import { getNavPath } from '../../../_heplers/DisplayViewHelper';
import { IFirstPage } from '../../../_common/interfaces/IFirstPage';
import { ILoginService } from '../../../_common/interfaces/services/ILoginService';
import { INavigate } from '../../../_common/interfaces/INavigate';
import { ISetBlockUi, ISetIsAuthenticated } from '../../../_common/app_context_provider/AppContextProvider';
import { Link } from 'react-router-dom';
import { loginService } from '../../../_services/auth/AuthenticationServices';
import { navPath } from '../../../_common/NavPath';
import { openErrorNotification } from '../../../_heplers/NotificationHelper';
import { SpinComponent } from '../../../_common/components/spin/SpinComponent';
import { validateMessages } from '../../../_common/ValidationCominications';

export class LoginForm extends React.Component<ISetIsAuthenticated & ISetBlockUi & INavigate & IFirstPage> {
    _is_mounted = true;
    _form_ref = React.createRef<FormInstance>();

    state = {
        loading: false,
        message: undefined
    };

    componentWillUnmount = () => {
        this._is_mounted = false;
    };

    public render() {
        const { loading } = this.state;

        return (
            <div className='common_form_1'>
                {loading ? <SpinComponent /> : this.displayForm()}
            </div>
        );
    }

    onFinish = (value: any) => {
        this.setState({
            loading: true,
            message: undefined
        });

        const data: ILoginService = {
            login: value.login,
            password: value.password,
            passwordRepeat: value.password
        };

        loginService(data)
            .then((response: any) => {
                if (response.status === 200) {
                    this.props.setBlockUi(false);
                    this.props.setIsAuthenticated(true);

                    const navPath = getNavPath(this.props.first_page);

                    this.props.navigate(createAbsoluteUrl(navPath), { replace: true })
                }
                else {
                    openErrorNotification(response.message);
                }
            })
            .finally(() => this._is_mounted && this.setState({ loading: false }));
    };

    //#endregion ____ Common ____

    //#region ____ Contents ____

    displayForm = () => {
        return (
            <div className='_form_content'>
                <div className='__form_title'>
                    Welcome to Mobilum
                </div>
                <div className='__form_subtitle'>
                    Enter your email address and password to access your account.
                </div>
                <Form
                    layout='vertical'
                    name='control-ref'
                    ref={this._form_ref}
                    onFinish={this.onFinish}
                    validateMessages={validateMessages()}
                >
                    <Form.Item
                        name='login'
                        label='Email address'
                        rules={[{ required: true }]}
                    >
                        <Input
                            size='large'
                            className='__custom_input'
                            placeholder='enter email address'
                        />
                    </Form.Item>
                    <Form.Item
                        name='password'
                        label='Password'
                        rules={[{ required: true }]}
                    >
                        <Input.Password
                            size='large'
                            placeholder='enter password'
                        />
                    </Form.Item>
                    <Form.Item shouldUpdate={true}>
                        {() => (
                            <Button
                                type='primary'
                                htmlType='submit'
                                className='__submit_button'
                            >
                                Sign in
                            </Button>
                        )}
                    </Form.Item>
                    <div className='__form_subtitle_1'>
                        If you want to reset your password, <Link to={createAbsoluteUrl(navPath.forgot_password)}>Click here</Link>
                    </div>
                </Form>
            </div>
        )
    }

    //#endregion ____ Contents ____
}
