import { _default_page_size, _hide_when_less_than, _page_size_options } from '../../../../_heplers/GlobalValues';
import { Button, Table, Tooltip } from 'antd';
import { IConfigurationFeeConfigTableProps } from '../interfaces/IConfigurationFeeConfigTableProps';
import { useState } from 'react';
import {
    EditFilled,
    DeleteFilled,
} from '@ant-design/icons';

export const ConfigurationFeeConfigTable = (props: IConfigurationFeeConfigTableProps) => {
    const [pageSize, setPageSize] = useState(_default_page_size);

    const {
        data,
        updateObjectInArray,
        deleteObjectFromArray
    } = props;

    const columns: any[] = [
        {
            title: 'Currency',
            dataIndex: 'baseCurrency',
            key: 'baseCurrency',
            width: 100,
            ellipsis: true,
        },
        {
            title: 'Fee %',
            dataIndex: 'feePercentage',
            key: 'feePercentage',
            width: '80',
            ellipsis: true
        },
        {
            title: 'Fee Type',
            dataIndex: 'feeType',
            key: 'feeType',
            width: '160',
            ellipsis: true
        },
        {
            title: 'Fixed',
            dataIndex: 'fixed',
            key: 'fixed',
            width: '80',
            ellipsis: true
        },
        {
            title: 'Formula Type',
            dataIndex: 'formulaType',
            key: 'formulaType',
            width: 'auto',
            ellipsis: true
        },
        {
            title: 'Site UUID',
            dataIndex: 'siteUuid',
            key: 'siteUuid',
            width: 'auto',
            ellipsis: true
        },
        {
            title: 'Threshold',
            dataIndex: 'threshold',
            key: 'threshold',
            width: 'auto',
            ellipsis: true
        },
        {
            title: 'Trigger',
            dataIndex: 'trigger',
            key: 'trigger',
            width: 'auto',
            ellipsis: true
        },
        {
            title: 'Value',
            dataIndex: 'value',
            key: 'value',
            width: 'auto',
            ellipsis: true
        },
        {
            title: 'Order',
            dataIndex: 'order',
            key: 'order',
            width: 'auto',
            ellipsis: true
        },
        {
            title: 'Actions',
            key: 'actions',
            width: 90,
            render: (record: any) => (
                <>
                    <Tooltip placement='rightTop' title='Edit'>
                        <Button
                            size='small'
                            type='text'
                            onClick={() => updateObjectInArray!(record)}
                            icon={<EditFilled />}
                        />
                    </Tooltip>
                    <Tooltip placement='rightTop' title='Delete'>
                        <Button
                            size='small'
                            type='text'
                            onClick={() => deleteObjectFromArray!(record)}
                            icon={<DeleteFilled />}
                        />
                    </Tooltip>
                </>
            )
        }
    ];

    return (
        <Table
            bordered
            size='small'
            columns={columns}
            dataSource={data}
            pagination={{
                pageSize: pageSize,
                onChange: (e: number, b: number) => setPageSize(b),
                showSizeChanger: true,
                pageSizeOptions: _page_size_options,
                hideOnSinglePage: data.length < _hide_when_less_than
            }}
        />
    )
}
