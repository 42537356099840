import { AppContext } from '../../../_common/app_context_provider/AppContextProvider';
import { getProfileServices } from '../../../_services/profile/ProfileServices';
import { MobilumPartnersContent } from './content/MobilumPartnersContent';
import { useContext, useEffect, useState } from 'react';

const MobilumPartnersView = () => {
    const context = useContext(AppContext);
    const [appUserInfo, setAppUserInfo] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getProfileServices()
            .then(profile_inf => {
                profile_inf.permissions = profile_inf.features;
                context.setAppUserInf(profile_inf);
                setAppUserInfo(profile_inf.availableSites);
            })
            .finally(() => setLoading(false));
    }, []);

    return (
        <>
            {!loading && <MobilumPartnersContent
                loading_dictionaries={context.loading_dictionaries}
                dictionaries={context.dictionaries}
                context={context}
                mobilum_partner_filters={context.mobilum_partner_filters}
                available_sites={appUserInfo}
                setMobilumPartnerFilters={context.setMobilumPartnerFilters}
            />}
        </>
    )
}
export default MobilumPartnersView;
