import Back_icon from '../../../images/Back_Icon.png';
import React from 'react';
import {
    Button,
    Form,
    Image,
} from 'antd';
import { forgotPasswordService, verifyOtpCode } from '../../../_services/auth/AuthenticationServices';
import { FormInstance } from 'antd/lib/form';
import { IForgotPasswordFormProps } from '../interfaces/IForgotPasswordFormProps';
import { IForgotPasswordService } from '../../../_common/interfaces/services/IForgotPasswordService';
import { openErrorNotification } from '../../../_heplers/NotificationHelper';
import { SpinComponent } from '../../../_common/components/spin/SpinComponent';
import { validateMessages } from '../../../_common/ValidationCominications';
import InputForm from '../../../_common/components/forms/InputForm';

export class CodeForm extends React.Component<IForgotPasswordFormProps> {
    _is_mounted = true;
    _form_ref = React.createRef<FormInstance>();

    state = {
        loading: false
    }

    componentWillUnmount = () => {
        this._is_mounted = false;
    };

    public render() {
        const { loading } = this.state;

        return (
            <div className='common_form_1'>
                {loading ? <SpinComponent /> : this.displayForm()}
            </div>
        );
    }

    //#region ____ Common ____

    showSuimtuBtton = () => {
        if (this._form_ref.current) {
            const fields = this._form_ref.current.getFieldsValue();
            const errors = this._form_ref.current
                .getFieldsError()
                .filter(({ errors }) => errors.length).length;

            // Check if error
            if (errors) {
                return true;
            }
            // Check if code exist
            if (fields.code === undefined) {
                return true;
            }
            else {
                return false;
            }
        }

        return true;
    };

    onFinish = (value: any) => {
        const { email } = this.props;
        verifyOtpCode(value.code, email).then((response) => {
            if (response.status === 200 && !response.data.errors?.length) {
                this.props.setCode?.(value.code);
                this.props.changeContent('set_new_password_form');
            } else if(response.data.errors.length) {
                openErrorNotification(response.data.errors[0])
            } else openErrorNotification('An error occurred')
        })
    };

    resendOtp = () => {
        const { email } = this.props;

        this.setState({
            loading: true,
            message: undefined
        });

        if (!email) {
            openErrorNotification('Email is missing');
        }

        const data: IForgotPasswordService = {
            email: email!,
        };

        forgotPasswordService(data)
            .then((response: any) => {
                if (response.status === 200) {
                    this.props.changeContent('code_form');
                }
            })
            .finally(() => this._is_mounted && this.setState({ loading: false }));
    }

    //#endregion ____ Common ____


    //#region ____ Contents ____

    displayForm = () => {
        return (
            <div className='_form_content'>
                <div className='__back_button'>
                    <Image
                        preview={false}
                        src={Back_icon}
                        onClick={() => this.props.changeContent('email_form')}
                    />
                </div>
                <div className='__form_title'>
                    Verification Required
                </div>
                <div className='__form_subtitle'>
                    To Continue, complete this verification step. We’ve sent a One Time Password (OTP) to the email<br />
                    <b>{this.props.email}</b> Please enter it below.
                </div>
                <Form
                    layout='vertical'
                    name='control-ref'
                    onFinish={this.onFinish}
                    ref={this._form_ref}
                    validateMessages={validateMessages()}
                >
                    <Form.Item
                        label='Enter OTP'
                        name='code'
                        rules={[
                            { required: true }
                        ]}
                    >
                        <InputForm
                            className='__custom_input_number'
                            placeholder='enter code'
                        />
                    </Form.Item>
                    <Form.Item shouldUpdate={true}>
                        {() => (
                            <Button
                                className='__submit_button'
                                style={{ marginTop: 16 }}
                                htmlType='submit'
                                type='primary'
                                disabled={this.showSuimtuBtton()}
                            >
                                Reset Password
                            </Button>
                        )}
                    </Form.Item>
                </Form>
                <div className='__form_subtitle_1'>
                    Didn’t receive, <Button type='text' className='common_link_button' onClick={() => this.resendOtp()}>Resend OTP</Button>
                </div>
            </div>
        );
    }

    //#endregion ____ Contents ____
}