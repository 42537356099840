import { Select } from 'antd';

const SelectOnChange = ({
    name,
    label = undefined,
    items,
    onChange,
    defaultValue = undefined,
    placeholder = '',
    required = undefined,
    label_class_name = undefined,
    className = '',
    id = undefined,
    showSearch = true,
    allowClear = false,
    style = {},
    ...rest
}) => {
    return (
        <>
            {label ? <label className={label_class_name} htmlFor={label}><b>{label}</b></label> : null}
            {required ? <label style={{ color: 'red' }}> * </label> : null}
            <Select
                allowClear
                {...rest}
                id={id}
                className={className}
                showSearch={showSearch}
                defaultActiveFirstOption
                defaultValue={defaultValue}
                placeholder={placeholder}
                style={style}
                onChange={(value) => onChange(name, value)}
                filterOption={(input, option) => (option.props.children ? option.props.children : "").toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
            >
                {
                    items &&
                    items.length > 0 &&
                    items.map(item => (
                        <Select.Option key={item.id} value={item.id}>
                            {item.name}
                        </Select.Option>
                    ))
                }
            </Select>
        </>
    );
}

export default SelectOnChange;
