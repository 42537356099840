import InputForm from '../../../../_common/components/forms/InputForm';
import React from 'react';
import {
    Button,
    Form,
    Select,
    Spin
    } from 'antd';
import { createBlockedRegionsServices } from '../../../../_services/blocked_regions/BlockedRegionsServices';
import { FormInstance } from 'antd/es/form';
import { IConfigurationTerritoryFormProps } from '../interfaces/IConfigurationTerritoryFormProps';
import { loading_icon } from '../../../../_common/components/loading/LoadingIcon';
import { openErrorNotification } from '../../../../_heplers/NotificationHelper';
import { validateMessages } from '../../../../_common/ValidationCominications';

export class ConfigurationTerritoryForm extends React.Component<IConfigurationTerritoryFormProps> {
    _is_mounted = true;
    _form_ref = React.createRef<FormInstance>();

    state = {
        loading: false
    };

    componentWillUnmount = () => {
        this._is_mounted = false;
    };

    public render() {
        const { loading } = this.state;
        const {
            setModalVisible,
            dictionaries,
            available_sites,
            loading_dictionaries,
        } = this.props;

        return (
            <Spin spinning={loading} indicator={loading_icon(60)}>
                <Form
                    layout='vertical'
                    name='control-ref'
                    ref={this._form_ref}
                    onFinish={this.onFinish}
                    style={{ textAlign: 'left' }}
                    validateMessages={validateMessages()}
                >
                    <Form.Item
                        label='Country'
                        name='country'
                        rules={[{ required: true }]}
                    >
                        <Select
                            // size='large'
                            className='width_100_percent'
                            placeholder='Select'
                            allowClear
                            showSearch
                            loading={loading_dictionaries}
                            filterOption={(input: any, option: any) => (option.props.children ? option.props.children : "").toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {dictionaries.countries?.map(x => <Select.Option key={x.value} value={x.value}>{x.name}</Select.Option>)}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        style={{ textAlign: 'left' }}
                        name='region'
                    >
                        <InputForm
                            label='Region'
                            placeholder='enter region name'
                            showTooltip
                            tootlipTitle='Leave empty to restrict whole country'
                            placement='topLeft'
                        />
                    </Form.Item>
                    <Form.Item
                        label='Site'
                        name='siteUuid'
                    >
                        <Select
                            // size='large'
                            className='width_100_percent'
                            placeholder='Select'
                            allowClear
                            showSearch
                            loading={loading_dictionaries}
                            filterOption={(input: any, option: any) => (option.props.children ? option.props.children : "").toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {available_sites?.filter((item: any) => !item.archived).map(x => <Select.Option key={x.value} value={x.value}>{x.name}</Select.Option>)}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label='Access Mode'
                        name='accessMode'
                        rules={[{ required: true }]}
                    >
                        <Select
                            // size='large'
                            className='width_100_percent'
                            placeholder='Select'
                            allowClear
                            showSearch
                            filterOption={(input: any, option: any) => (option.props.children ? option.props.children : "").toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {dictionaries.access_mode?.map(x => <Select.Option key={x.value} value={x.value}>{x.name}</Select.Option>)}
                        </Select>
                    </Form.Item>
                    <div className='grid_col_2'>
                        <Button
                            className='common_cancel_button'
                            onClick={() => setModalVisible(false)}
                        >
                            Cancel
                        </Button>
                        <Form.Item shouldUpdate={true}>
                            {() => (
                                <Button
                                    className='common_submit_button'
                                    htmlType='submit'
                                    type='primary'
                                    style={{ width: '100%' }}
                                    disabled={this.showSuimtuBtton()}
                                >
                                    Save
                                </Button>
                            )}
                        </Form.Item>
                    </div>
                </Form>
            </Spin>
        );
    }

    showSuimtuBtton = () => {
        if (this._form_ref.current) {
            const errors = this._form_ref.current
                .getFieldsError()
                .filter(({ errors }) => errors.length).length;

            // Check if error
            if (errors) {
                return true;
            }
            else {
                return false;
            }
        }

        return true;
    };

    onFinish = async (values: any) => {
        const { setModalVisible } = this.props;

        this.setState({ loading: true });

        const {
            addObjectToArray,
            dictionaries
        } = this.props;

        // Add new role
        let region: any = {
            country: values.country && dictionaries!.countries!.filter(x => x.value === values.country)[0].name,
            region: values.region,
            siteUuid: values.siteUuid,
            accessMode: values.accessMode.toString(),
            countryCode: values.country
        };

        await createBlockedRegionsServices(region)
            .then((response: any) => {
                if (response.status === 200) {
                    region.id = response.data;
                    region.created_at = new Date().getTime();

                    addObjectToArray!(region);

                    setModalVisible(false);
                }
                else {
                    openErrorNotification(response.title)
                }
            })
            .finally(() => this._is_mounted && this.setState({ loading: false }));
    };
}
