import Card from 'antd/lib/card';
import LogoSmall from '../../../images/LogoSmall.png';
import moment from 'moment';
import { AppContext } from '../../../_common/app_context_provider/AppContextProvider';
import {
	Breadcrumb,
	Button,
	Descriptions,
	Divider,
	Form,
	Image,
	Input,
	Modal,
	Spin,
} from 'antd';
import { ClearBoth } from '../../../_common/components/clearBoth/ClearBoth';
import { convertNumberToDateTimeString } from '../../../_heplers/DateHelpers';
import { createAbsoluteUrl } from '../../../_heplers/UrlCreatorHelpres';
import {
	approvePendingService,
	authorizeRefundService,
	getOrderDetails,
	refundOrderService,
} from '../../../_services/transactions/TransactionServices';
import { Left } from '../../../_common/components/left/Left';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { navPath } from '../../../_common/NavPath';
import {
	openErrorNotification,
	openSuccessNotification,
} from '../../../_heplers/NotificationHelper';
import { PageTitleComponent } from '../../../_common/components/page_title/PageTitleComponent';
import { PDFGenerator } from '../../../_heplers/PDFGenerator';
import { Right } from '../../../_common/components/right/Right';
import { Row } from 'antd/lib/grid';
import { setHeader } from '../../../_heplers/AxiosHelper';
import styles from '../../notifications/styles/Notifications.module.scss';
import { useContext, useEffect, useState } from 'react';
import { loading_icon } from '../../../_common/components/loading/LoadingIcon';
import { formatOrderStatus } from '../../../_heplers/OrderStatusHelper';
export const OrderDetails = () => {
	const [details, setDetails] = useState<any>();
	const { id }: any = useParams();
	const [showModal, setShowModal] = useState(false);
	const navigate = useNavigate();
	const validatePermission = useContext(AppContext).validatePermission;
	const [dataModal, setDataModal] = useState({ type: '', title: '' });
	const [input, setInput] = useState(false);
	const [additionalData, setAdditionalData] = useState('');
	const [showActionModal, setActionModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [transactionData, setTransactionData] = useState({}) as any;

	//#region ---- Styles ----

	const common_hr_style = {
		border: '0.5px solid #A3B0BF',
		marginBottom: '17px',
	};
	const common_div_1_style = {
		fontSize: '16px',
		marginBottom: '18px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	};
	const common_div_2_style = { display: 'inline-block', color: '#A3B0BF' };

	//#endregion ---- Styles ----
	const fetchData = () => {
		setLoading(true);
		getOrderDetails(id)
			.then((response: any) => {
				if (!response.userDetails) {
					openErrorNotification('Endpoint problem');
					setLoading(false);
				} else {
					setDetails(response);
					setTransactionData(
						response?.payments?.transactions?.find(
							(item: any) => item.id === id
						)
					);
					setLoading(false);
				}
			})
			.finally(() => setLoading(false));
	};
	useEffect(() => {
		setHeader();
		fetchData();
	}, []);

	const onSubmit = async () => {
		setActionModal(false);
		setLoading(true);
		dataModal.type === 'authorize'
			? await authorizeRefundService(details?.order?.order.orderUUID)
					.then((res) => {
						if (res) {
							openSuccessNotification('Refund Authorzation completed');
						} else {
							openErrorNotification('Refund Authorzation went wrong');
						}
					})
					.finally(() => {
						setLoading(false);
						fetchData();
					})
			: dataModal.type === 'refund'
			? await refundOrderService(
					details?.payments?.transactions[0].id,
					additionalData
			  )
					.then((res) => {
						if (res.success === true) {
							openSuccessNotification('Refund completed successfully');
						} else {
							openErrorNotification(res.message);
						}
					})
					.finally(() => {
						setLoading(false);
						fetchData();
					})
			: await approvePendingService(details?.payments?.transactions[0].id)
					.then((response) => {
						if (response) {
							openSuccessNotification(response.message);
						}
					})
					.finally(() => {
						setLoading(false);
						fetchData();
					});
		setLoading(false);
	};
	return (
		<>
			<Spin
				className='spin_60'
				spinning={loading}
				indicator={loading_icon(40)}
			>
				<Breadcrumb separator='>'>
					<Breadcrumb.Item>
						<a onClick={() => navigate(-1)}>Transaction History</a>
					</Breadcrumb.Item>
					<Breadcrumb.Item>Order Details</Breadcrumb.Item>
				</Breadcrumb>
				<div className='flex'>
					<PageTitleComponent page_title='Order Details' />
					{validatePermission('OrderAudit') && (
						<div
							className='flex'
							style={{ alignItems: 'center', marginBottom: 23 }}
						>
							<Button
								type='primary'
								onClick={() => setShowModal(true)}
							>
								Show details
							</Button>
							<div style={{ zIndex: 1 }}>
								<PDFGenerator>
									<div style={{ margin: '20px 30px' }}>
										<Left>
											<Image
												src={LogoSmall}
												preview={false}
												style={{ width: 20 }}
											/>
										</Left>
										<Right>
											<div style={{ marginTop: 6, fontSize: 12 }}>
												{convertNumberToDateTimeString({
													number: new Date().getTime(),
													request_date_format: 'DD/MMYYYY HH:mm:ss',
												})}
											</div>
										</Right>
										<ClearBoth />
										<div style={{ marginTop: 30, fontSize: 12 }}>
											{`Transaction: ${transactionData?.orderId} done by ${transactionData?.name} ${transactionData?.surname} with email ${transactionData?.email} for ${transactionData?.price} ${transactionData?.currency} to purchase ${transactionData?.orderAmount} ${transactionData?.cryptoCurrency}`}
										</div>
										<div className='custom_descriptions_style'>
											<Descriptions
												className='custom_descriptions_style'
												size='small'
												bordered
												title='Personal Details'
												style={{
													marginTop: 20,
													marginBottom: 20,
													fontSize: 10,
												}}
												column={2}
											>
												<Descriptions.Item label='Name'>{`${transactionData?.name} ${transactionData?.surname}`}</Descriptions.Item>
												<Descriptions.Item label='Address'>
													{transactionData?.userAddress}
												</Descriptions.Item>
												<Descriptions.Item label='Email'>
													{transactionData?.email}
												</Descriptions.Item>
												<Descriptions.Item label='Country'>
													{details?.userDetails.user.country}
												</Descriptions.Item>
												<Descriptions.Item label='ID'>
													{transactionData?.id}
												</Descriptions.Item>
												<Descriptions.Item label='KYC passed'>
													{transactionData?.passedKyc}
												</Descriptions.Item>
												<Descriptions.Item label='Provider'>
													{transactionData?.price}
												</Descriptions.Item>
											</Descriptions>
											<Descriptions
												bordered
												size='small'
												title='Transaction Details'
												style={{ marginBottom: 20 }}
												column={2}
											>
												<Descriptions.Item label='Site'>
													{transactionData?.siteName}
												</Descriptions.Item>
												<Descriptions.Item label='Price'>
													{transactionData?.price}
												</Descriptions.Item>
												<Descriptions.Item label='Currency'>
													{transactionData?.currency}
												</Descriptions.Item>
												<Descriptions.Item label='Crypto amount'>
													{transactionData?.orderAmount}
												</Descriptions.Item>
												<Descriptions.Item label='Crypto currency'>
													{transactionData?.cryptoCurrency}
												</Descriptions.Item>
												<Descriptions.Item label='Order ID'>
													{transactionData?.orderId}
												</Descriptions.Item>
												<Descriptions.Item label='Request ID'>
													{transactionData?.txId}
												</Descriptions.Item>
												<Descriptions.Item label='Transaction status'>
													{transactionData?.acquirerTxStatus}
												</Descriptions.Item>
												<Descriptions.Item label='Transaction date'>
													{transactionData?.txDate}
												</Descriptions.Item>
												<Descriptions.Item label='Refunded'>
													{transactionData?.refund ? 'True' : 'False'}
												</Descriptions.Item>
												<Descriptions.Item label='Failure reason'>
													{details?.transaction?.payments &&
														details?.transaction.payments.errorDetails &&
														details?.transaction.payments.errorDetails.length >
															0 &&
														details?.transaction.payments.errorDetails[
															details?.transaction.payments.errorDetails
																.length - 1
														].message}
												</Descriptions.Item>
												<Descriptions.Item label='User IP'>
													{transactionData?.userId}
												</Descriptions.Item>
											</Descriptions>
											<Descriptions
												bordered
												size='small'
												title='Delivery Details'
												style={{ marginBottom: 20 }}
												column={2}
											>
												<Descriptions.Item label='Address (wallet)'>
													{details?.transaction?.delivery?.address}
												</Descriptions.Item>
												<Descriptions.Item label='Delivered'>
													{details?.transaction?.delivery?.state
														? 'True'
														: 'False'}
												</Descriptions.Item>
												<Descriptions.Item label='Delivery date'>
													{details?.transaction?.delivery?.createdOn}
												</Descriptions.Item>
												<Descriptions.Item label='Failure reason'>
													{details?.transaction?.transaction?.delivery?.state}
												</Descriptions.Item>
												<Descriptions.Item label='Transaction hash'>
													{transactionData?.txHash}
												</Descriptions.Item>
											</Descriptions>
										</div>
									</div>
								</PDFGenerator>
							</div>
						</div>
					)}
				</div>
				<Row style={{ marginBottom: 20, justifyContent: 'space-between' }}>
					<Card
						style={{
							borderRadius: '14px',
							marginRight: 20,
							flex: 1,
						}}
					>
						<div style={common_div_1_style}>
							<div style={common_div_2_style}>Transaction ID</div>
							<div style={{ float: 'right' }}>
								{details && transactionData?.id ? transactionData?.id : '-'}
							</div>
						</div>
						<hr style={common_hr_style} />

						<div style={common_div_1_style}>
							<div style={common_div_2_style}>Order ID</div>
							<div style={{ float: 'right' }}>
								{details && details.order.order.orderUUID
									? details.order.order.orderUUID
									: '-'}
							</div>
						</div>

						<hr style={common_hr_style} />
						<div style={common_div_1_style}>
							<div style={common_div_2_style}>Transaction Date</div>
							<div style={{ float: 'right' }}>
								{details && transactionData?.txDate
									? moment(transactionData?.txDate).format('DD MMM YY')
									: '-'}
							</div>
						</div>
						<hr style={common_hr_style} />
						<div style={common_div_1_style}>
							<div style={common_div_2_style}>Site</div>
							<div style={{ float: 'right' }}>
								{details && transactionData?.siteName
									? transactionData?.siteName
									: '-'}
							</div>
						</div>
						<hr style={common_hr_style} />
						<div style={common_div_1_style}>
							<div style={common_div_2_style}>User ID</div>
							<Link
								to={createAbsoluteUrl(
									`${navPath.user_profile}/${
										details && details?.userDetails.user.userUUid
											? details?.userDetails.user.userUUid
											: undefined
									}/order`
								)}
							>
								<a style={{ float: 'right', color: '#458FE6' }}>
									{details && details?.userDetails.user.userUUid
										? details?.userDetails.user.userUUid
										: '-'}
								</a>
							</Link>
						</div>
						<hr style={common_hr_style} />
						<div style={common_div_1_style}>
							<div style={common_div_2_style}>Username</div>
							<div style={{ float: 'right' }}>
								{details && details?.userDetails?.user
									? details?.userDetails.user.name +
									  ' ' +
									  details?.userDetails.user.surname
									: '-'}
							</div>
						</div>
						<hr style={common_hr_style} />
						<div style={common_div_1_style}>
							<div style={common_div_2_style}>Email</div>
							<a style={{ float: 'right', color: '#458FE6' }}>
								{details && details?.userDetails.user.email
									? details?.userDetails.user.email
									: '-'}
							</a>
						</div>
						<hr style={common_hr_style} />
						<div style={common_div_1_style}>
							<div style={common_div_2_style}>Address</div>
							<div style={{ float: 'right' }}>
								{details && details?.userDetails.user.address
									? details?.userDetails.user.address
									: '-'}
							</div>
						</div>
						<hr style={common_hr_style} />
						<div style={common_div_1_style}>
							<div style={common_div_2_style}>Country</div>
							<div style={{ float: 'right' }}>
								{details && details?.userDetails?.user?.country
									? details?.userDetails.user.country
									: '-'}
							</div>
						</div>
						<hr style={common_hr_style} />
						<div style={common_div_1_style}>
							<div style={common_div_2_style}>KYC Status</div>
							<div
								style={{
									fontSize: '12px',
									color:
										details && details?.userDetails.user
											? details?.userDetails.user.kycVerified
												? '#0BB07E'
												: details?.userDetails.user.kycComplete
												? '#ff5252'
												: '#F2994A'
											: undefined,
									backgroundColor:
										details && details?.userDetails.user
											? details?.userDetails.user.kycVerified
												? '#7bf7bc'
												: details?.userDetails.user.kycComplete
												? '#f7d3d0'
												: '#FFF4EA'
											: undefined,
									padding: '6px 18px',
									borderRadius: '4px',
									float: 'right',
								}}
							>
								{details && details?.userDetails.user
									? details?.userDetails.user.kycVerified
										? 'Verified'
										: details?.userDetails.user.kycComplete
										? 'Failed'
										: 'Pending'
									: '-'}
							</div>
						</div>
					</Card>
					<Card style={{ borderRadius: '14px', flex: 1 }}>
						<div style={common_div_1_style}>
							<div style={common_div_2_style}>Purchase Amount</div>
							<div style={{ float: 'right' }}>
								{details && details.order.order.price
									? details.order.order.price +
									  ' ' +
									  details.order.order.selectedCurrency
									: '-'}
							</div>
						</div>
						<hr style={common_hr_style} />
						<div style={common_div_1_style}>
							<div style={common_div_2_style}>Cryptocurrency Type</div>
							<div style={{ float: 'right' }}>
								{details && details.order.order.selectedCryptoCurrency
									? details.order.order.selectedCryptoCurrency
									: '-'}
							</div>
						</div>
						<hr style={common_hr_style} />
						<div style={common_div_1_style}>
							<div style={common_div_2_style}>Crypto Amount</div>
							<div style={{ float: 'right' }}>
								{details && details.order.order.amount
									? details.order.order.amount
									: '-'}
							</div>
						</div>
						<hr style={common_hr_style} />
						<div style={common_div_1_style}>
							<div style={common_div_2_style}>Transaction Hash</div>
							<div style={{ float: 'right' }}>
								{transactionData?.txHash ? transactionData.txHash : '-'}
							</div>
						</div>
						<hr style={common_hr_style} />
						<div style={common_div_1_style}>
							<div style={common_div_2_style}>Wallet Address</div>
							<div style={{ float: 'right' }}>
								{details && details.order.order.userWallet
									? details.order.order.userWallet
									: '-'}
							</div>
						</div>
						<hr style={common_hr_style} />
						<div style={common_div_1_style}>
							<div style={common_div_2_style}>Credit Card Number</div>
							<div style={{ float: 'right' }}>
								{details && transactionData?.cardIdentificationNumber
									? transactionData?.cardIdentificationNumber
									: '-'}
							</div>
						</div>
						<hr style={common_hr_style} />
						<div style={common_div_1_style}>
							<div style={common_div_2_style}>Card UUID</div>
							<div style={{ float: 'right' }}>
								{details && transactionData?.cardMd5
									? transactionData?.cardMd5
									: '-'}
							</div>
						</div>
						<hr style={common_hr_style} />
						<div style={common_div_1_style}>
							<div style={common_div_2_style}>Card Status</div>
							<div style={{ float: 'right' }}>{'-'}</div>
						</div>
						<hr style={common_hr_style} />
						<div style={common_div_1_style}>
							<div style={common_div_2_style}>Refunded Amount</div>
							<div style={{ float: 'right' }}>
								{details && transactionData?.refundedAmount
									? transactionData?.refundedAmount
									: '-'}
							</div>
						</div>
						<hr style={common_hr_style} />
						<div style={common_div_1_style}>
							<div style={common_div_2_style}>Refunded</div>
							<div style={{ float: 'right' }}>
								{details && transactionData?.refund ? 'Yes' : 'No'}
							</div>
						</div>
					</Card>
				</Row>
				{details && (
					<>
						<Modal
							title={undefined}
							visible={showModal}
							closable={false}
							footer={false}
							width={'auto'}
							destroyOnClose
						>
							<>
								<Divider style={{ padding: 10 }}>
									<h2 style={{ color: 'green', marginTop: 10 }}>Order</h2>
								</Divider>
								<div
									style={{
										margin: 10,
										padding: 10,
										border: '1px solid gray',
										borderRadius: 16,
									}}
								>
									{details &&
										details?.order &&
										displayObject(details.order.order, 'Order Details')}
								</div>
								<Divider style={{ padding: 10 }}>
									<h2 style={{ color: 'green', marginTop: 10 }}>Payments</h2>
								</Divider>
								<div
									style={{
										margin: 10,
										padding: 10,
										border: '1px solid gray',
										borderRadius: 16,
									}}
								>
									{details && details?.payments && (
										<>
											{details?.payments.fenigeState[0] &&
												displayObject(
													details?.payments.fenigeState[0],
													'Fenige State'
												)}
											{details?.payments.fenigeTransactions[0] &&
												displayObject(
													details?.payments.fenigeTransactions[0],
													'Fenige Transactions'
												)}
											{transactionData &&
												displayObject(transactionData, 'Transactions')}
										</>
									)}
								</div>
								{details && details?.delivery && details?.delivery.delivery && (
									<>
										<Divider style={{ padding: 10 }}>
											<h2 style={{ color: 'green', marginTop: 10 }}>
												Delivery
											</h2>
										</Divider>
										<div
											style={{
												margin: 10,
												padding: 10,
												border: '1px solid gray',
												borderRadius: 16,
											}}
										>
											{displayObject(
												details?.delivery.delivery,
												'Delivery Details'
											)}
										</div>
									</>
								)}
								<Left>
									<Button
										type='primary'
										style={{ marginLeft: 10, marginBlock: 10, marginTop: 20 }}
										onClick={() => setShowModal(false)}
									>
										Close
									</Button>
								</Left>
								<div style={{ marginBlock: 10, marginTop: 20, float: 'left' }}>
									<PDFGenerator>
										<>
											<Divider style={{ padding: 10 }}>
												<h2
													style={{
														color: 'green',
														marginTop: 10,
														fontSize: 12,
													}}
												>
													Order
												</h2>
											</Divider>
											<div
												style={{
													margin: 10,
													padding: 10,
													border: '1px solid gray',
													borderRadius: 16,
												}}
												className='custom_descriptions_style'
											>
												{details &&
													details?.order &&
													displayObject(
														details.order.order,
														'Order Details',
														1
													)}
											</div>
											<Divider style={{ padding: 10 }}>
												<h2
													style={{
														color: 'green',
														marginTop: 10,
														fontSize: 12,
													}}
												>
													Payments
												</h2>
											</Divider>
											<div
												style={{
													margin: 10,
													padding: 10,
													border: '1px solid gray',
													borderRadius: 16,
												}}
											>
												{details && details?.payments && (
													<div className='custom_descriptions_style'>
														{details?.payments.fenigeState[0] &&
															displayObject(
																details?.payments.fenigeState[0],
																'Fenige State',
																1
															)}
														{details?.payments.fenigeTransactions[0] &&
															displayObject(
																details?.payments.fenigeTransactions[0],
																'Fenige Transactions',
																1
															)}
														{transactionData &&
															displayObject(transactionData, 'Transactions', 1)}
													</div>
												)}
											</div>
											{details &&
												details?.delivery &&
												details?.delivery.delivery && (
													<>
														<Divider style={{ padding: 10 }}>
															<h2
																style={{
																	color: 'green',
																	marginTop: 10,
																	fontSize: 12,
																}}
															>
																Delivery
															</h2>
														</Divider>
														<div
															style={{
																margin: 10,
																padding: 10,
																border: '1px solid gray',
																borderRadius: 16,
															}}
															className='custom_descriptions_style'
														>
															{details &&
																details?.delivery &&
																details?.delivery.delivery &&
																displayObject(
																	details?.delivery.delivery,
																	'Delivery Details',
																	1
																)}
														</div>
													</>
												)}
										</>
									</PDFGenerator>
								</div>
								<ClearBoth />
							</>
						</Modal>
						<div
							className='flex'
							style={{ justifyContent: 'space-between' }}
						>
							<Button
								className='common_previous_button'
								onClick={() => navigate(-1)}
							>
								Previous
							</Button>
							<div>
								{transactionData?.paid &&
									!transactionData?.refundAuthorized &&
									!transactionData?.refunded &&
									validatePermission('CanApproveRefund') && (
										<Button
											type='primary'
											className='common_next_button'
											onClick={() => {
												setDataModal({
													title: 'authorize the refund',
													type: 'authorize',
												});
												setInput(false);
												setActionModal(true);
											}}
										>
											Authorize refund
										</Button>
									)}
								{transactionData?.paid &&
									transactionData?.refundAuthorized &&
									!transactionData?.refunded &&
									validatePermission('CanRefund') && (
										<Button
											type='primary'
											className='common_next_button'
											onClick={() => {
												setDataModal({ title: 'refund', type: 'refund' });
												setInput(true);
												setActionModal(true);
											}}
										>
											Refund
										</Button>
									)}
								{validatePermission('CanProcessTx') && (
									<Button
										type='primary'
										className='common_add_new_button'
										style={{ height: 25, marginLeft: 5 }}
										onClick={() => {
											setDataModal({
												title: 'approve transaction',
												type: 'approve',
											});
											setInput(false);
											setActionModal(true);
										}}
									>
										Approve
									</Button>
								)}
							</div>
							<Modal
								title={undefined}
								visible={showActionModal}
								closable={false}
								footer={false}
								width={400}
								destroyOnClose
							>
								<div className={styles.modal_content}>
									<div
										className={styles._modal_title}
										style={{ marginBottom: '30px' }}
									>
										Are you sure you want to {dataModal.title}?
									</div>
									{input && (
										<div style={{ marginBottom: 20 }}>
											<Input
												placeholder='Additional data'
												onChange={(value) =>
													setAdditionalData(value.target.value)
												}
											/>
										</div>
									)}
									<div className='grid_col_2'>
										<Button
											className='common_cancel_button'
											onClick={() => setActionModal(false)}
										>
											Cancel
										</Button>
										<Form.Item shouldUpdate={true}>
											{() => (
												<Button
													className='common_submit_button'
													htmlType='submit'
													type='primary'
													onClick={() => onSubmit()}
												>
													Confirm
												</Button>
											)}
										</Form.Item>
									</div>
								</div>
							</Modal>
						</div>
					</>
				)}
			</Spin>
		</>
	);
};

const displayObject = (object: any, title: string, column?: number) => (
	<Descriptions
		bordered
		size='small'
		title={title}
		column={column}
		style={{ margin: 10, marginBottom: 20 }}
	>
		{Object.keys(object)?.map((key, index) => {
			if (key === 'orderStatus') {
				return (
					<Descriptions.Item
						key={index}
						label={key}
					>
						{formatOrderStatus(object[key])}
					</Descriptions.Item>
				);
			} else
				return (
					<Descriptions.Item
						key={index}
						label={key}
					>
						{object[key]}
					</Descriptions.Item>
				);
		})}
	</Descriptions>
);
