import { _default_page_size, _hide_when_less_than, _page_size_options } from '../../../../_heplers/GlobalValues';
import { AppContext } from '../../../../_common/app_context_provider/AppContextProvider';
import {
  Button,
  Switch,
  Table,
  Tooltip
  } from 'antd';
import { IConfigurationRolesTableProps } from '../interfaces/IConfigurationRolesTableProps';
import { useContext, useState } from 'react';

export const ConfigurationRolesTable = (props: IConfigurationRolesTableProps) => {
  const {
    data,
    changeRoleStatus,
    updateObjectInArray,
    context
  } = props;

  const validatePermission = useContext(AppContext).validatePermission;
  const [pageSize, setPageSize] = useState(_default_page_size);

  const columns: any[] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      key: 'priority',
      ellipsis: true,
    },
    {
      title: 'Is Active',
      key: 'isActive',
      render: (record: any) => (
        <Switch
          checked={record.isActive}
          onClick={() => changeRoleStatus(record)}
        />
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 150,
      render: (record: any) =>
        validatePermission('CanEditRoles') && (
          <Tooltip placement="rightTop" title="Edit Role">
            <Button
              size="small"
              type="link"
              onClick={() => updateObjectInArray!(record)}
            >
              Edit
            </Button>
          </Tooltip>
        ),
    },
  ];

  return (
    <Table
      bordered
      rowKey='id'
      size='small'
      dataSource={data}
      columns={columns}
      pagination={{
        pageSize: pageSize,
        showSizeChanger: true,
        pageSizeOptions: _page_size_options,
        onChange: (e: number, b: number) => setPageSize(b),
        hideOnSinglePage: data.length < _hide_when_less_than
      }}
    />
  )
}
