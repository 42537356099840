import Plus from '../../../images/Plus.png';
import styles from './styles/AddNewButtonComponent.module.scss';
import { Button, Image } from 'antd';

interface IAddNewButtonProps {
    onClick: () => void;
    title: string;
}

export const AddNewButtonComponent = (props: IAddNewButtonProps) => {
    const {
        onClick,
        title
    } = props;

    return (
        <Button
            className={styles.add_new_button}
            icon={<Image src={Plus} preview={false} />}
            size='small'
            type='primary'
            danger
            onClick={onClick}
        >
            {title}
        </Button>
    )
}