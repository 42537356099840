import { AppContext } from '../../../_common/app_context_provider/AppContextProvider';
import { PartnerAdminDetailsContent } from './content/PartnerAdminDetailsContent';
import { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const MobilumPartnerDetailsView = () => {
    const { id }: any = useParams();
    const context = useContext(AppContext);
    
    const navigate = useNavigate();

    return (
        <PartnerAdminDetailsContent id={id} context={context} navigate={navigate} />
    )
}

export default MobilumPartnerDetailsView;
