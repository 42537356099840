import { Breadcrumb, Button, Spin } from "antd"
import { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { loading_icon } from "../../../_common/components/loading/LoadingIcon"
import { PageTitleComponent } from "../../../_common/components/page_title/PageTitleComponent"
import { navPath } from "../../../_common/NavPath"
import { openErrorNotification, openSuccessNotification } from "../../../_heplers/NotificationHelper"
import { createAbsoluteUrl } from "../../../_heplers/UrlCreatorHelpres"
import {  getFraudRulesByLimitType, updateFraudRule } from "../../../_services/antifraud/AntiFraudService"
import AntiFraudItemErrorsTable from "./AntiFraudItemErrorsTable"
import AntiFraudItemParametersTable from "./AntiFraudItemParametersTable"

const AntiFraudDetailsItem = () => {
  const [loading, setLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const { id, name, type}: any = useParams();
  const [fraudRules, setFraudRules] = useState([] as any);
  const [fraudDetails, setFraudDetails] = useState({title: '', subTitle: ''} as {title: string, subTitle?: string});

  const getFraudData = async () => {
    setLoading(true);
  
    try {
      const response = await getFraudRulesByLimitType(id, type);
      if (response.errors) {
        response.errors.map((error: string) => {
          return openErrorNotification(error);
        });
      } else {
        setFraudRules(response.fraudRules);
        const details = {
          title: response.title,
          subTitle: response.subTitle
        }
        setFraudDetails(details);
      }
    } catch (error) {
      openErrorNotification('An error occurred while fetching fraud data');
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getFraudData();
  }, [])

  const updateItem = (item: any) => {
    setFraudRules((prevRules: any) => {
      const updatedRules = prevRules.map((rule: any) =>
        rule.id === item.id ? item : rule
      );
      return updatedRules;
    });
  }

  const updateAndSave = () => {
    setActionLoading(true)
    updateFraudRule(fraudRules).then((response: any) => {
      if(response.data) {
        openSuccessNotification('Successfully updated');
        setFraudRules(response.data);
      }
    }).finally(() => setActionLoading(false))
  }
  
  return (
    <>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>
            <Link to={createAbsoluteUrl(`${navPath.mobilum_partners}`)}>Fraud Protection Filters</Link>
            </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={createAbsoluteUrl(`${navPath.anti_fraud}/${id}/${name}`)}>
            {name}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
            {fraudDetails.title}
        </Breadcrumb.Item>
      </Breadcrumb>
      <PageTitleComponent page_title={fraudDetails?.title} />
      <div style={{backgroundColor: 'white', padding: '24px'}}>
     
      <p className="column_description">{fraudDetails.title}</p>
      <p style={{color: '#A3B0BF', marginBottom: '50px'}} className="section_description">
        {fraudDetails.subTitle}
      </p>
      <p className="section_description">Parameters</p>
      <Spin className='spin_60' spinning={loading} indicator={loading_icon(40)}>
          <AntiFraudItemParametersTable data={fraudRules} updateItem={updateItem} />
      </Spin>
      <p className="section_description" style={{ marginTop: '40px' }}>Error Codes Displayed</p>
      <Spin className='spin_60' spinning={loading} indicator={loading_icon(40)}>
          <AntiFraudItemErrorsTable data={fraudRules} />
      </Spin>
      <Button
          className="ant-btn ant-btn-primary common_next_button"
          style={{marginTop: '40px'}}
          onClick={updateAndSave}
          loading={actionLoading}
          disabled={actionLoading}
          type='primary'
      >
          Save
      </Button>
      </div> 
    </>
  )
}
export default AntiFraudDetailsItem;