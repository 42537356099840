import { Table } from "antd";
import { memo } from "react";
import { _hide_when_less_than, _page_size_options } from "../../_heplers/GlobalValues";
export const AntiFraudTable = (props: any) => {
  const {
      list,
      total,
      currentPage,
      pageSize,
      onChangePage
  } = props;


  const columns: any[] = [
    {
      title: 'User UUID',
      key: 'userUUID',
      dataIndex: 'userUUID',
    },
    {
      title: 'User Email',
      key: 'userEmail',
      dataIndex: 'userEmail',
    },
    {
      title: 'Client Site',
      key: 'siteName',
      dataIndex: 'siteName',
    },
    {
      title: 'Date blocked',
      key: 'txDate',
      dataIndex: 'txDate',
    },
    {
      title: 'Error Code',
      key: 'errorCode',
      dataIndex: 'errorCode',
    },
    {
      title: 'Error Message',
      key: 'errorMessage',
      dataIndex: 'errorMessage',
    },
  ];

  return (
      <Table
          bordered
          size='small'
          columns={columns}
          dataSource={list}
          rowKey='id'
          scroll={{ x: 500 }}
          onChange={onChangePage}
          pagination={{
              current: currentPage,
              total: total,
              pageSize: pageSize,
              pageSizeOptions: _page_size_options,
              hideOnSinglePage: total < _hide_when_less_than
          }}
      />
  )
}

export default memo(AntiFraudTable)