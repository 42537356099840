const orderStatuses = [
  { key: 'approved', title: 'Approved' },
  { key: 'complete', title: 'Complete' },
  { key: 'declined', title: 'Declined' },
  { key: 'deliveryfailed', title: 'Delivery failed' },
  { key: 'manual complete', title: 'Manual complete' },
  { key: 'no 3ds', title: 'No 3DS' },
  { key: 'paymentfailed', title: 'Payment failed' },
  { key: 'paymentinprogress', title: 'Payment in progress' },
  { key: 'processing', title: 'Processing' },
  { key: 'refund', title: 'Refund' },
  { key: 'reversal', title: 'Reversal' },
  { key: 'waitforapproval', title: 'Wait for approval' },
  { key: 'waiting 3ds', title: 'Waiting 3DS' },
];

export const formatOrderStatus = (status: string) => {
  const item = orderStatuses.find((x) => x.key === status.toLowerCase());
  if (item) {
    return item.title;
  } else return status;
};
