import { AppContext } from '../../../_common/app_context_provider/AppContextProvider';
import { MobilumPartnerDetailsContent } from './content/MobilumPartnerDetailsContent';
import { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const PartnerAdminDetailsView = () => {
    const { id }: any = useParams();
    const context = useContext(AppContext);
    
    const navigate = useNavigate();

    return (
        <MobilumPartnerDetailsContent
            id={id}
            dictionaries={context.dictionaries}
            loading_dictionaries={context.loading_dictionaries}
            navigate={navigate}
        />
    )
}

export default PartnerAdminDetailsView;
