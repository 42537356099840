import Column from 'antd/lib/table/Column';
import moment from 'moment';
import styles from '../../notifications/styles/Notifications.module.scss';
import { _hide_when_less_than, _page_size_options } from '../../../_heplers/GlobalValues';
import { AppContext } from '../../../_common/app_context_provider/AppContextProvider';
import {
  Button,
  Divider,
  Form,
  Input,
  Modal,
  Space,
  Spin,
  Table
  } from 'antd';
import { createAbsoluteUrl } from '../../../_heplers/UrlCreatorHelpres';
import { Link } from 'react-router-dom';
import { loading_icon } from '../../../_common/components/loading/LoadingIcon';
import { navPath } from '../../../_common/NavPath';
import { useContext, useState } from 'react';

export const RegisteredUsersTable = (props: any) => {
  const {
    data,
    changeUserBlockStatus,
    resetKycAttempts,
    removeKycAttempts,
    total,
    onChangePage,
    current_page,
    page_size
  } = props;

  const context = useContext(AppContext);
  const validatePermission = useContext(AppContext).validatePermission;
  const [showModal, setModal] = useState(false);
  const [titleModal, setTitleModal] = useState('');
  const [input, setInput] = useState(false);
  const [id, setId] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [reason, setReason] = useState('');

  const onReason = (value: any) => setReason(value.target.value);
  const onSubmit = async () => {
    setModal(false);
    titleModal == 'remove'
      ? removeKycAttempts(id)
      : titleModal == 'reset'
        ? resetKycAttempts(id)
        : changeUserBlockStatus(id, disabled, reason);
  }

  const closeModal = () => {
    setReason('')
    setModal(false)
  }

  return (
    <>
      <Table
        scroll={{ x: 1200 }}
        className="transaction-table"
        dataSource={data}
        bordered
        onChange={(e) => onChangePage(e)}
        size="middle"
        style={{ borderRadius: '20px' }}
        pagination={{
          pageSize: page_size,
          current: current_page,
          total: total,
          pageSizeOptions: _page_size_options,
          hideOnSinglePage: true && data.length < _hide_when_less_than
        }}
      >
        <Column
          title="Email"
          dataIndex="email"
          key="email"
          ellipsis={true}
          width={220}
          render={(text: any, record: any) => (
            <Link
              to={createAbsoluteUrl(
                `${navPath.user_profile}/${record.userUuid}/Registered Users`,
              )}
            >
              <Space>{record.email}</Space>
            </Link>
          )}
        />
        <Column
          title="User's Name"
          dataIndex="name"
          key="name"
          width={180}
          render={(text, record: any) => record.name + ' ' + record.surname}
        />
        <Column
          title="User UUID"
          dataIndex="userUuid"
          key="userUuid"

          width={300}
          render={(text: any, record: any) => (
            <Link
              to={createAbsoluteUrl(
                `${navPath.user_profile}/${record.userUuid}/Registered Users`,
              )}
            >
              <Space>{record.userUuid}</Space>
            </Link>
          )}
        />
        <Column
          title="Partner"
          key="siteName"
          ellipsis={true}
          width={150}
          render={(record: any) =>
            validatePermission('Sites') ? (
              <a
                target="_blank"
                rel="noreferrer"
                href={`${context.dictionaries.widgetUrl}?apikey=${record.siteId}`}
              >
                {record.siteName}
              </a>
            ) : (
              record.siteName
            )
          }
        />
        <Column
          title="Status"
          dataIndex="disabled"
          key="disabled"
          ellipsis={true}
          width={90}
          render={(text: any, record: any) => record.disabled ? 'Blocked' : 'Active'}
        />
        <Column
          title="Registration Date"
          dataIndex="registrationDate"
          key="registrationDate"
          ellipsis={true}
          width={150}
          render={(text: any, record: any) =>
            moment(record.registrationDate).format('DD MMM YY')
          }
        />
        <Column
          title="KYC Status"
          dataIndex="kycStatus"
          key="kycStatus"
          width={135}
          ellipsis={true}
          render={(text: any, record: any) =>
            record.kycVerified === 'True'
              ? 'Passed'
              : record.kycVerified === 'False' && record.kycComplete === 'True'
                ? 'Failed'
                : !record.kycExists
                  ? 'Not started'
                  : 'Pending'
          }
        />
        <Column
          title="KYC Attempts"
          dataIndex="kycAttempts"
          key="kycAttempts"
          ellipsis={true}
          width={130}
        />
        {validatePermission('ViewRiskLevels') && (
          <Column
            title="Risk Level"
            key="riskLevel"
            width={100}
            render={(text: any, record: any) =>
              displayRiskLevel(record.riskLevel, context)
            }
          />
        )}
        {validatePermission('ManageClients') && (
          <Column
            title="Actions"
            key="is_active"
            width={230}
            render={(record: any) => (
              <>
                <Button
                  size="small"
                  type="link"
                  style={{ fontSize: '12px', padding: '5px' }}
                  onClick={() => {
                    setModal(true);
                    record.disabled
                      ? setTitleModal('unblock')
                      : setTitleModal('block');
                    setInput(true);
                    setId(record.id);
                    setDisabled(record.disabled);
                  }}
                >
                  {record.disabled ? 'Unblock' : 'Block'}
                </Button>
                <Divider
                  type="vertical"
                  style={{ margin: 0, background: '#458FE6' }}
                />
                <Button
                  size="small"
                  type="link"
                  style={{ fontSize: '12px' }}
                  onClick={() => {
                    setModal(true);
                    setTitleModal('reset');
                    setInput(false);
                    setId(record.id);
                  }}
                >
                  {'Reset'}
                </Button>
                {record.kycExists && (
                  <Button
                    size="small"
                    type="link"
                    style={{ fontSize: '12px' }}
                    onClick={() => {
                      setModal(true);
                      setTitleModal('remove');
                      setInput(false);
                      setId(record.id);
                    }}
                  >
                    {'Remove'}
                  </Button>
                )}
              </>
            )}
          />
        )}
      </Table>
      <Modal
        title={undefined}
        visible={showModal}
        closable={false}
        footer={false}
        width={400}
        destroyOnClose
      >
        <div className={styles.modal_content}>
          <div className={styles._modal_title} style={{ marginBottom: '30px' }}>
            {titleModal == 'remove'
              ? 'Are you sure you want to remove KYC attempts?'
              : titleModal == 'reset'
                ? 'Are you sure you want to reset KYC attempts?'
                : titleModal == 'block'
                  ? 'Are you sure you want to block the user?'
                  : 'Are you sure you want to unblock the user?'}
          </div>
          {input && (
            <div style={{ marginBottom: 20 }}>
              <Input placeholder="Please give a reason" onChange={onReason} />
            </div>
          )}
          <div className="grid_col_2">
            <Button
              className="common_cancel_button"
              onClick={() => closeModal()}
            >
              Cancel
            </Button>
            <Form.Item shouldUpdate={true}>
              {() => (
                <Button
                  className="common_submit_button"
                  htmlType="submit"
                  type="primary"
                  disabled={!reason && titleModal == 'block'}
                  onClick={() => onSubmit()}
                >
                  Approve
                </Button>
              )}
            </Form.Item>
          </div>
        </div>
      </Modal>
    </>
  );
}

const displayRiskLevel = (value: number, context: any) => {
  const { dictionaries, loading_dictionaries } = context;

  if (loading_dictionaries) {
    return <Spin spinning indicator={loading_icon(10)} size='small' />
  }
  else {
    const index = dictionaries.risk_configs.findIndex((x: any) => value >= x.valueFrom && value < x.valueTo);

    return index > -1 ? <div>{dictionaries.risk_configs[index].name}</div> : '-';
  }
}

