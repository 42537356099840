import Column from 'antd/lib/table/Column';
import { Button, Spin, Table } from 'antd';
import { getRefreshLinkReportsServices, getReportsServices } from '../../_services/reports/GeneratedReportsService';
import { loading_icon } from '../../_common/components/loading/LoadingIcon';
import { useEffect, useState } from 'react';

export const ReportsTable = (props: any) => {
  const [list, setList] = useState<any[]>([]);
  const [fileUrl, setUrl] = useState('');
  const [loading, setLoading] = useState(true);

  //#region ---- Styles ----

  const common_title_style = { color: '#1C0B2C' };
  const action_button_style = {
    width: '100%',
    borderRadius: 8,
    marginBottom: 5,
    marginRight: 10,
  };

  //#endregion ---- Styles ----

  const getReports = async () => {
    setList(await getReportsServices());
    setLoading(false);
  };

  useEffect(() => {
    getReports();
  }, []);

  const downloadReport = async (id: number) => {
    setUrl(await getRefreshLinkReportsServices(id));
  };

  useEffect(() => {
    if (fileUrl != null && fileUrl != '') {
      window.open(fileUrl)
    }
  }, [fileUrl]);

  return (
    <>
      <Spin className="spin_60" spinning={loading} indicator={loading_icon(40)}>
        <Table
          scroll={{ y: 600 }}
          dataSource={list}
          bordered
          size="middle"
          className="transaction-table"
          style={{ marginTop: 20, borderRadius: 20 }}
          rowKey="id"
        >
          <Column
            title={() => <a style={common_title_style}>{'ID'}</a>}
            dataIndex="id"
            key="id"
            width={100}
          />
          <Column
            title={() => <a style={common_title_style}>{'Site ID'}</a>}
            dataIndex="siteUuid"
            key="siteUuid"
          />
          <Column
            title={() => <a style={common_title_style}>{'Name'}</a>}
            dataIndex="name"
            key="name"
          />
          <Column
            title={() => <a style={common_title_style}>{'Status'}</a>}
            dataIndex="status"
            key="status"
            width={200}
          />
          <Column
            title={() => <a style={common_title_style}>{'Action'}</a>}
            dataIndex="id"
            key="id"
            width={150}
            render={(text, record: any) => (
              <>
                <Button
                  type="primary"
                  style={action_button_style}
                  onClick={() => {
                    downloadReport(record.id);
                  }}
                >
                  {'Download'}
                </Button>
              </>
            )}
          />
        </Table>
      </Spin>
    </>
  );
};
