import { useContext } from 'react';
import { AppContext } from '../../../_common/app_context_provider/AppContextProvider';
import { ConfigurationCoinsAndTokensContent } from './content/ConfigurationCoinsAndTokensContent';

const ConfigurationCoinsAndTokensView = () => {
    const context = useContext(AppContext)
    return (
        <ConfigurationCoinsAndTokensContent context={context}/>
    )
}

export default ConfigurationCoinsAndTokensView;