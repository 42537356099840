import { InputNumber, Table } from "antd";
import { memo } from "react";
export const AntiFraudItemParametersTable = (props: any) => {
  const {
      data,
      updateItem,
  } = props;

  const updateData = (item:any, val:any) => {
    const updatedItem = {
      ...item,
      limitValue: val || 0
    }
    updateItem(updatedItem)
  }
  const columns: any[] = [
    {
      title: 'Parameter Name',
      key: 'parameterName',
      dataIndex: 'parameterName',
    },
    {
      title: 'Description',
      key: 'description',
      dataIndex: 'description',
    },
    {
      title: 'Value',
      key: 'limitValue',
      render: (record: any) => (
          <InputNumber
            min={0}
            value={record.limitValue}
            onChange={(value) => updateData(record, value)}
            style={{ height: '32px', borderColor: '#A3B0BF' }}
          />
      ),
    }
  ];

  return (
      <Table
          bordered
          className="antifraud-table"
          columns={columns}
          dataSource={data}
          size='small'
          rowKey={record => record.id}
          scroll={{ x: 500 }}
          pagination={false}
      />
  )
}

export default memo(AntiFraudItemParametersTable)