import WalletsTable from '../tables/WalletsTable';
import { _default_page_size } from '../../../_heplers/GlobalValues';
import { AppContext } from '../../../_common/app_context_provider/AppContextProvider';
import { createAbsoluteUrl } from '../../../_heplers/UrlCreatorHelpres';
import { getWallets } from '../../../_services/wallets/WalletsServices';
import { loading_icon } from '../../../_common/components/loading/LoadingIcon';
import {
    memo,
    useContext,
    useEffect,
    useState
    } from 'react';
import { navPath } from '../../../_common/NavPath';
import { PageTitleComponent } from '../../../_common/components/page_title/PageTitleComponent';
import { Select, Spin } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

export const WalletsContent = () => {
    const { Option } = Select;

    const params = useParams();
    const navigate = useNavigate();
    const context = useContext(AppContext);
    const availableSites = context?.app_user_inf?.availableSites;

    const [loading, setLoading] = useState(false);
    const [walletsList, setWalletsList] = useState([]);
    const [selectedSite, setSelectedSite] = useState(params.id || context?.app_user_inf?.availableSites[0]?.siteUuid);
    const [current_page, setCurrentPage] = useState(1);
    const [page_size, setPageSize] = useState(_default_page_size);

    const select_styles = {
        borderRadius: 8,
        backgroundColor: 'white',
        width: 300,
        marginBottom: 7,
        marginRight: 11
    };
    const getWalletsData = (apiKey: string = selectedSite) => {
        setLoading(true);

        getWallets(current_page, page_size, apiKey)
            .then((response: any) => (response.status === 200 && !response.data.errors?.length) && setWalletsList(response.data.result))
            .finally(() => setLoading(false))
    };

    const onChangeSelect = (value: any) => {
        setSelectedSite(value)
        navigate(createAbsoluteUrl(`${navPath.wallets}/${value}`))
        setCurrentPage(1)
        getWalletsData(value);
    }

    const changePageSize = (size: any) => {
        setPageSize(size)
    }
    const changePage = (operator: any) => {
        // eslint-disable-next-line no-eval
        let page = eval(current_page + operator + 1);
        setCurrentPage(page);
        getWalletsData();
    }

    useEffect(() => {
        getWalletsData();
    }, [page_size]);

    return (
        <>
            <PageTitleComponent page_title='Wallets' />
            <div style={{ marginBottom: 5 }}>
                <Select
                    value={selectedSite || availableSites[0]}
                    onChange={onChangeSelect}
                    placeholder='Sites'
                    className='filters'
                    style={select_styles}
                    bordered={false}
                    disabled={loading}
                    showSearch
                    filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                    {availableSites?.map((x: any) => <Option key={x.siteUuid} value={x.siteUuid}>{x.siteName}</Option>)}
                </Select>
            </div>
            <Spin className='spin_60' spinning={loading} indicator={loading_icon(40)}>
                <WalletsTable data={walletsList} siteUuid={selectedSite}
                    setPageSize={(size: number) => changePageSize(size)} page={current_page} changePage={changePage} />
            </Spin>
        </>
    )

}
export default memo(WalletsContent)
