import styles from './styles/SpinComponent.module.scss';
import { loading_icon } from '../loading/LoadingIcon';
import { Spin } from 'antd';

export const SpinComponent = (props?: any) => {
    const { height, icon_sise } = props;

    return (
        <div className={styles.spin_content} style={{ height: height ? height : '100vh' }} >
            <Spin
                className={styles._spin}
                spinning
                indicator={loading_icon(icon_sise ? icon_sise : 60)}
            />
        </div>
    )
}