import { AppContext } from '../../_common/app_context_provider/AppContextProvider';
import { LoginContent } from './content/LoginContent';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

const LoginView = () => {
    const context = useContext(AppContext);
    const navigate = useNavigate();

    return (
        <LoginContent
            setBlockUi={context.setBlockUi}
            setIsAuthenticated={context.setIsAuthenticated}
            navigate={navigate}
            first_page={context.app_user_inf.firstPage}
        />
    )
}

export default LoginView;
