import Card from 'antd/lib/card';
import { AppContext } from '../../../_common/app_context_provider/AppContextProvider';
import {
  Button,
  Form,
  InputNumber,
  Modal,
  Spin
  } from 'antd';
import { getUserDetails, updateUserRiskLevel } from '../../../_services/clients/ClientsService';
import { Left } from '../../../_common/components/left/Left';
import { loading_icon } from '../../../_common/components/loading/LoadingIcon';
import { openErrorNotification, openSuccessNotification } from '../../../_heplers/NotificationHelper';
import { Row } from 'antd/lib/grid';
import { useContext, useEffect, useState } from 'react';
import { validateMessages } from '../../../_common/ValidationCominications';

export const RiskManagement = (props: any) => {
  const context = useContext(AppContext);
  const [profile, setProfile] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [risk, setRisk] = useState(0);

  const { id } = props;
  const validatePermission = useContext(AppContext).validatePermission;

  const common_row_style = {
    border: '1px solid black',
    borderRadius: '14px',
    width: 413,
    height: 52,
    padding: 14
  }

  const common_div_1_style = { marginBottom: 15, fontSize: 16 };
  const margin_bottom_5 = { marginBottom: 5 };
  const button_style = {
    fontSize: 16,
    fontWeight: 700,
    width: 154,
    height: 50,
    borderRadius: 8,
    marginLeft: 20,
    marginTop: 32
  };

  const onSubmit = async () => {
    setLoading(true);

    updateUserRiskLevel(profile.userUUid, risk)
      .then(response => {
        if (response) {
          profile.riskLevel = risk;

          setProfile(profile);

          openSuccessNotification('Updated');
          setShowModal(false);
        }
        else {
          openErrorNotification('Service problem');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    getUserDetails(id)
      .then((response: any) => {
        if (!response.user) {
          openErrorNotification(response.message);
        }
        else {
          setProfile(response.user);
          setRisk(response.user.riskLevel);
        }
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      <Spin className="spin_60" spinning={loading} indicator={loading_icon(40)}>
        <Card style={{ borderRadius: '14px' }}>
          <Row>
            <div style={{ marginRight: 32 }}>
              <div style={common_div_1_style}>
                <Left>
                  <div style={margin_bottom_5}>Risk Level</div>
                  <div style={common_row_style}>
                    {displayRiskLevel(
                      profile ? profile.riskLevel : 0,
                      context,
                      loading,
                    )}
                  </div>
                </Left>
                {validatePermission('ManageRiskLevels') && (
                  <Left>
                    <Button
                      type="primary"
                      style={button_style}
                      onClick={() => setShowModal(true)}
                    >
                      Edit
                    </Button>
                  </Left>
                )}
              </div>
            </div>
          </Row>
        </Card>
      </Spin>
      <Modal
        title={undefined}
        visible={showModal}
        closable={false}
        footer={false}
        width={400}
        destroyOnClose
      >
        <div className={''}>
          {/* <div style={margin_bottom_5}>Risk Level</div> */}
          <Form
            layout="vertical"
            name="control-ref"
            onFinish={onSubmit}
            validateMessages={validateMessages()}
          >
            <Form.Item
              label="Risk Level"
              name="risk_level"
              rules={[{ required: true }]}
            >
              <InputNumber
                min={0}
                max={100}
                value={risk}
                onChange={setRisk}
                size="large"
                precision={0}
                style={{ width: '100%', marginBottom: 30 }}
                placeholder="enter value"
              />
            </Form.Item>
            <div className="grid_col_2">
              <Button
                className="common_cancel_button"
                onClick={() => {
                  setShowModal(false);
                  setRisk(profile ? profile.riskLevel : 0);
                }}
              >
                Cancel
              </Button>
              <Form.Item>
                <Button
                  className="common_submit_button"
                  htmlType="submit"
                  type="primary"
                >
                  Approve
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
};

const displayRiskLevel = (value: number, context: any, loading: boolean) => {
  const { dictionaries, loading_dictionaries } = context;

  if (loading_dictionaries || loading) {
    return <Spin spinning indicator={loading_icon(10)} size='small' />
  }
  else {
    const index = dictionaries.risk_configs.findIndex((x: any) => value >= x.valueFrom && value <= x.valueTo);
    return index > -1 ? <div>{`${dictionaries.risk_configs[index].name} (${value})`}</div> : '-';
  }
}
