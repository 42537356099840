import { AppContext } from '../../../_common/app_context_provider/AppContextProvider';
import { ConfigurationFeeConfigContent } from './content/ConfigurationFeeConfigContent';
import { useContext } from 'react';

const ConfigurationFeeConfigView = () => {
    const context = useContext(AppContext);

    return (
        <ConfigurationFeeConfigContent
            loading_dictionaries={context.loading_dictionaries}
            dictionaries={context.dictionaries}
            available_sites={context.app_user_inf.availableSites.map((x: any) => { return { value: x.siteUuid, name: x.siteName } })}
        />
    )
}

export default ConfigurationFeeConfigView;
