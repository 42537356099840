import Back_icon from '../../../images/Back_Icon.png';
import React from 'react';
import {
    Button,
    Form,
    Image,
    Input,
} from 'antd';
import { FormInstance } from 'antd/lib/form';
import { IResetPasswordByCodeService } from '../../../_common/interfaces/services/IResetPasswordByCodeService';
import { ISetNewPasswordFormProps } from '../interfaces/ISetNewPasswordFormProps';
import { resetPasswordByCodeService } from '../../../_services/auth/AuthenticationServices';
import { SpinComponent } from '../../../_common/components/spin/SpinComponent';
import { validateMessages } from '../../../_common/ValidationCominications';
import { createAbsoluteUrl } from '../../../_heplers/UrlCreatorHelpres';
import { navPath } from '../../../_common/NavPath';
import { openSuccessNotification } from '../../../_heplers/NotificationHelper';
import { passwordValidator } from '../../../_heplers/RegexHelper';

export class SetNewPasswordForm extends React.Component<ISetNewPasswordFormProps> {
    _is_mounted = true;
    _form_ref = React.createRef<FormInstance>();

    state = {
        loading: false,
        message: undefined
    };

    componentWillUnmount = () => {
        this._is_mounted = false;
    };

    public render() {
        const { loading } = this.state;

        return (
            <div className='common_form_1'>
                {loading ? <SpinComponent /> : this.displayForm()}
            </div>
        );
    }

    //#region ____ Common ____

    showSuimtuBtton = () => {
        if (this._form_ref.current) {
            const fields = this._form_ref.current.getFieldsValue();
            const errors = this._form_ref.current
                .getFieldsError()
                .filter(({ errors }) => errors.length).length;

            // Check if error
            if (errors) {
                return true;
            }
            // Check if email exist
            if (fields.new_password === undefined || fields.confirm_new_password === undefined) {
                return true;
            } else {
                return false;
            }
        }

        return true;
    };

    onFinish = (value: any) => {
        this.setState({
            loading: true,
            message: undefined
        });

        const data: IResetPasswordByCodeService = {
            newPassword: value.new_password,
            code: this.props.code
        };

        resetPasswordByCodeService(data)
            .then(async (response: any) => {
                if (response.status === 200) {
                    openSuccessNotification('Password reset successfully, please login to continue')
                    this.props.navigate(createAbsoluteUrl(navPath.home))
                }
            })
            .finally(() => this._is_mounted && this.setState({ loading: false }));
    };

    //#endregion ____ Common ____

    //#region ____ Contents ____

    displayForm = () => {
        return (
            <div className='_form_content'>
                <div className='__back_button'>
                    <Image
                        preview={false}
                        src={Back_icon}
                        onClick={() => this.props.changeContent('code_form')}
                    />
                </div>
                <div className='__form_title'>
                    Create New Password
                </div>
                <div className='__form_subtitle'>
                    We’ll ask for this password whenever you sign in.
                </div>
                <Form
                    layout='vertical'
                    name='control-ref'
                    onFinish={this.onFinish}
                    ref={this._form_ref}
                    validateMessages={validateMessages()}
                >
                    <Form.Item
                        label='New Password'
                        name='new_password'
                        rules={[{ required: true }, { pattern: passwordValidator() }]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        label="Confirm New Password"
                        name="confirm_new_password"
                        dependencies={['new_password']}
                        rules={[
                            { required: true },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('new_password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('The passwords do not match!'));
                                }
                            })
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item shouldUpdate={true}>
                        {() => (
                            <Button
                                className='__submit_button'
                                style={{ marginTop: 16 }}
                                htmlType='submit'
                                type='primary'
                                disabled={this.showSuimtuBtton()}
                            >
                                Save Password
                            </Button>
                        )}
                    </Form.Item>
                </Form>
            </div>
        );
    }

    //#endregion ____ Contents ____
}