import React from 'react';
import styles from '../styles/MobilumPartnerDetails.module.scss';
import { getEmptySiteModelService, getMobilumPartnerDetailsService } from '../../../../_services/mobilum_partners/MobilumPartnersService';
import { IMobilumPartnerDetails } from '../interfaces/IMobilumPartnerDetails';
import { IMobilumPartnerDetailsContentProps } from '../interfaces/IMobilumPartnerDetailsContentProps';
import { MobilumPartnerDetailsCommercialTermsForm } from '../forms/MobilumPartnerDetailsCommercialTermsForm';
import { MobilumPartnerDetailsCompanyInformationForm } from '../forms/MobilumPartnerDetailsCompanyInformationForm';
import { MobilumPartnerDetailsCurrenciesSelect } from '../forms/MobilumPartnerDetailsCurrenciesSelect';
import { MobilumPartnerDetailsFeaturesSelect } from '../forms/MobilumPartnerDetailsFeaturesSelect';
import { MobilumPartnerDetailsTechnicalSetUpForm } from '../forms/MobilumPartnerDetailsTechnicalSetUpForm';
import { MobilumPartnerDetailsUserInformationForm } from '../forms/MobilumPartnerDetailsUserInformationForm';
import { openErrorNotification } from '../../../../_heplers/NotificationHelper';
import { PageTitleComponent } from '../../../../_common/components/page_title/PageTitleComponent';
import { SpinComponent } from '../../../../_common/components/spin/SpinComponent';
import { Steps } from 'antd';
import { MobilumPartnerDetailsWiretransferFeesForm } from '../forms/MobilumPartnerDetailsWiretransferFeesForm';

const { Step } = Steps;

export class MobilumPartnerDetailsContent extends React.Component<IMobilumPartnerDetailsContentProps> {
    _is_mounted = true;

    state = {
        loading: true,
        current_setp: 0,
        mobilum_partner_details: {} as IMobilumPartnerDetails
    };

    componentDidMount = () => {
        this.getMobilumPartnerInfo();
    }

    componentWillUnmount = () => {
        this._is_mounted = false;
    }

    public render() {
        const { loading } = this.state;
        const { id } = this.props;

        return (
            <>
                <PageTitleComponent page_title={id === 'new' ? 'Add New Partner' : 'Edit Partner’s Information'} />
                <div className={styles.content}>
                    <div className={styles.contentForm}>{loading ? <SpinComponent height={500} /> : this.displayContent()}</div>
                    {this.displaySteps()}

                </div>
            </>
        );
    }

    getMobilumPartnerInfo = async () => {
        this.setState({ loading: true });

        const { id } = this.props;

        if (id !== 'new') {
            getMobilumPartnerDetailsService(id)
                .then(mobilum_partner_details => {
                    mobilum_partner_details.isNew = false;
                    mobilum_partner_details.Spread = mobilum_partner_details.spread;
                    mobilum_partner_details.Fees = mobilum_partner_details.fees;
                    mobilum_partner_details.Uuid = mobilum_partner_details.siteUuid;

                    delete mobilum_partner_details.spread;
                    delete mobilum_partner_details.fees;

                    this._is_mounted && this.setState({ mobilum_partner_details });
                })
                .finally(() => this._is_mounted && this.setState({ loading: false }));
        }
        else {
            getEmptySiteModelService()
                .then(mobilum_partner_details => {
                    if (mobilum_partner_details) {
                        mobilum_partner_details.isNew = true;
                        mobilum_partner_details.siteUuid = mobilum_partner_details.Uuid;

                        this._is_mounted && this.setState({ mobilum_partner_details });
                    }
                    else {
                        openErrorNotification('Problem with service');
                    }
                })
                .finally(() => this._is_mounted && this.setState({ loading: false }));
        }
    }

    setCurrentStep = (current_setp: number) => {
        this.setState({ current_setp });
    }

    updateState = (mobilum_partner_details: IMobilumPartnerDetails) => {
        this.setState({ mobilum_partner_details });
    }

    displaySteps = () => {
        const { current_setp } = this.state;
        const { id } = this.props;

        return (
            <Steps direction="vertical" current={current_setp}>
                <Step title='Company Information' />
                <Step title='User Information' />
                <Step title='Commercial Terms' />
                <Step title='Wiretransfer Fees' />
                <Step title='Features' />
                <Step title='Currencies' />
                <Step title='Technical Set Up' />
            </Steps>
        );
    }

    displayContent = () => {
        const {
            current_setp,
            mobilum_partner_details
        } = this.state;

        const {
            dictionaries,
            loading_dictionaries,
            id,
            navigate
        } = this.props;

        switch (current_setp) {
            case 0:
                return <MobilumPartnerDetailsCompanyInformationForm
                    mobilum_partner_details={mobilum_partner_details}
                    setCurrentStep={this.setCurrentStep}
                    updateState={this.updateState}
                    navigate={navigate}
                    title='Company Information'
                />;
            case 1: {
                return <MobilumPartnerDetailsUserInformationForm
                    mobilum_partner_details={mobilum_partner_details}
                    setCurrentStep={this.setCurrentStep}
                    updateState={this.updateState}
                    title='User Information'
                />;
            }
            case 2: {
                return <MobilumPartnerDetailsCommercialTermsForm
                    mobilum_partner_details={mobilum_partner_details}
                    dictionaries={dictionaries}
                    loading_dictionaries={loading_dictionaries}
                    setCurrentStep={this.setCurrentStep}
                    updateState={this.updateState}
                    id={id}
                />;
            }
            case 3: {
                return <MobilumPartnerDetailsWiretransferFeesForm
                    mobilum_partner_details={mobilum_partner_details}
                    dictionaries={dictionaries}
                    loading_dictionaries={loading_dictionaries}
                    setCurrentStep={this.setCurrentStep}
                    updateState={this.updateState}
                    navigate={navigate}
                    siteUuid={id}
                    title='Wiretransfer Fees'
                />;
            }
            case 4: {
                return <MobilumPartnerDetailsFeaturesSelect
                    mobilum_partner_details={mobilum_partner_details}
                    setCurrentStep={this.setCurrentStep}
                    updateState={this.updateState}
                    dictionaries={dictionaries}
                    loading_dictionaries={loading_dictionaries}
                    navigate={navigate}
                    id={id}
                />;
            }
            case 5: {
                return <MobilumPartnerDetailsCurrenciesSelect
                    mobilum_partner_details={mobilum_partner_details}
                    setCurrentStep={this.setCurrentStep}
                    updateState={this.updateState}
                    dictionaries={dictionaries}
                    loading_dictionaries={loading_dictionaries}
                    navigate={navigate}
                    id={id}
                />;
            }
            default: {
                return <MobilumPartnerDetailsTechnicalSetUpForm
                    mobilum_partner_details={mobilum_partner_details}
                    setCurrentStep={this.setCurrentStep}
                    updateState={this.updateState}
                    dictionaries={dictionaries}
                    loading_dictionaries={loading_dictionaries}
                    navigate={navigate}
                    id={id}
                />;
            }
        }
    }
}
