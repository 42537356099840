import { AppContext } from '../../../_common/app_context_provider/AppContextProvider';
import { useContext } from 'react';
import PartnerAdminsContent from './content/PartnerAdminsContent';

const PartnerAdminsView = () => {
    const validatePermission = useContext(AppContext).validatePermission;

    return <PartnerAdminsContent validatePermission={validatePermission} />;
}

export default PartnerAdminsView;
