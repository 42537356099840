import * as React from 'react';
import styles from './styles/SearchElement.module.scss';
import { Input } from 'antd';
import { ISearchElements } from './interfaces/ISearchElements';
import { SearchOutlined } from '@ant-design/icons';

export default class SearchElements extends React.Component<ISearchElements> {
    public render() {
        const { className, value, type, showIcon, placeholder, loading, allowClear, onSearch, width } = this.props;

        const prefix = (
            <SearchOutlined
                style={{
                    fontSize: 23,
                    color: '#A3B0BF',
                }}
            />
        );

        return (
            <Input
                className={`${styles.global_search} ${className}`}
                placeholder={placeholder ? placeholder : 'Search'}
                type={type}
                prefix={showIcon && prefix}
                name='search'
                onChange={onSearch}
                value={value}
                allowClear={allowClear}
                disabled={loading}
                style={{
                    width: width || 343,
                    borderRadius: 8,
                    backgroundColor: 'white',
                    marginBottom: 16,
                }}
            />
        )
    };
}