import * as React from 'react';

function MenuIco(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.333 10c0-.92.747-1.667 1.667-1.667h30a1.667 1.667 0 110 3.334H5c-.92 0-1.667-.746-1.667-1.667zm0 10c0-.92.747-1.667 1.667-1.667h30a1.667 1.667 0 110 3.334H5c-.92 0-1.667-.747-1.667-1.667zM5 28.333a1.667 1.667 0 100 3.334h30a1.667 1.667 0 100-3.334H5z"
                fill="#1C0B2C"
            />
        </svg>
    );
}

const MemoMenu_ico = React.memo(MenuIco);
export default MemoMenu_ico;
