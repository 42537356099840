import { AppContext } from '../_common/app_context_provider/AppContextProvider';
import { useContext, useEffect, useState } from 'react';
import { clearLocalStorage } from './LocalStorageHelpers';
import { useLocation } from 'react-router-dom';
import { revokeToken } from '../_services/auth/AuthenticationServices';

export const AuthenticatedView = (props: any) => {
    const context = useContext(AppContext);
    const [loading, setLoading] = useState(true)
    const location = useLocation().pathname;
    const getCurrentPathWithoutLastPart = (location: string) => {
        return location.slice(0, location.lastIndexOf('/'))
    }
    useEffect(() => {
        if (getCurrentPathWithoutLastPart(location) === `/${getCurrentPathWithoutLastPart(props.shouldLogoutPath)}` && context.is_authenticated) {
             clearLocalStorage();
             revokeToken();
             window.location.reload();
        } else {
            setLoading(false)
        }
    }, [])
    return (
        <>
            {(!loading && context.is_authenticated) ? props.children : ""}
        </>
    )
}

export const UnAuthenticatedView = (props: any) => {
    const context = useContext(AppContext);
    return (
        <>
            {context.is_authenticated ? "" : props.children}
        </>
    )
}