export const validateMessages = () => {
    return {
        required: 'Field is required',
        types: {
            email: 'Incorrect email',
            url: 'Incorrect url',
            number: "It is not valid number",
            integer: "It is not valid integer"
        },
        number: {
            max: 'Cannot be greater than max',
            min: 'Cannot be less than min',
        },
        pattern: {
            mismatch: 'Password must be minimum 6 characters long and include at least one upper case letter, one number and one special characters. Space are not accepted.'
        }
    }
}
