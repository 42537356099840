import axios from '../api';

const url = 'api/SiteApi';

export const getNotificationsService = () =>
  axios.get(`${url}/ListNotifications`)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.log('--------------- getNotificationsService', error.message);

      return [];
    });

export const updateNotificationsService = (body: any) =>
  axios.post(`${url}/UpdateNotificationTemplate`, body)
    .then(() => true)
    .catch(error => {
      console.log('--------------- updateNotificationsService', error.message);

      return false;
    });

export const createNotificationsService = (body: any) =>
  axios.post(`${url}/CreateNotificationTemplate`, body)
    .then(response => response)
    .catch(error => {
      console.log('--------------- createNotificationTemplateService', error.message);

      return false;
    });

export const deleteNotificationsService = (siteId: string, id: string) =>
  axios.delete(`${url}/DeleteNotificationTemplate`, { params: { siteId, id } })
    .then(response => response)
    .catch(error => {
      console.log('--------------- deleteNotificationsService', error.message);

      return false;
    });
