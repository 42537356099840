import * as React from 'react';

function MenuCoinsTokens(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.5 23C17.299 23 22 18.299 22 12.5S17.299 2 11.5 2 1 6.701 1 12.5 5.701 23 11.5 23zm.001-14.061l-2.525 2.523-1.47-1.468L11.502 6l3.994 3.995-1.468 1.47L11.5 8.938zM5 12.5l1.469-1.469 1.469 1.47-1.47 1.468L5 12.5zm6.5 3.562l-2.524-2.524-1.471 1.467.002.002L11.5 19l3.994-3.995.001-.001-1.47-1.467-2.525 2.525zm3.562-3.561l1.468-1.47L18 12.502l-1.469 1.468-1.469-1.468zM11.5 11.009l1.49 1.49v.002l-1.49 1.49-1.49-1.488-.002-.004.002-.002.26-.26.128-.127L11.5 11.01z"
                fill="#A3B0BF"
            />
        </svg>
    );
}

const MemoMenuCoinsTokens = React.memo(MenuCoinsTokens);
export default MemoMenuCoinsTokens;
