import Logo_with_text from '../../../images/Logo_with_text.png';
import styles from './styles/LoginLogoComponent.module.scss';
import { Image } from 'antd';

export const LoginLogoComponent = () => {
    return (
        <div className={styles.content}>
            <div className={styles._svg} >
                <Image className={styles._svg} src={Logo_with_text} preview={false} />
            </div>
        </div>
    )
}