import { AppContext } from '../../../_common/app_context_provider/AppContextProvider';
import { Filters } from './Filters';
import { exportData, listService } from '../../../_services/transactions/TransactionServices';
import { PageTitleComponent } from '../../../_common/components/page_title/PageTitleComponent';
import { TransactionTable } from './TransactionsTable';
import { useContext, useEffect, useRef, useState } from 'react';
import { _default_page_size } from '../../../_heplers/GlobalValues';
import {upperFirst} from 'lodash';
import { openSuccessNotification } from '../../../_heplers/NotificationHelper';

export const TransactionHistory = () => {

  const context = useContext(AppContext);
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(context.tx_current_page);
  const [loading, setLoading] = useState(true);
  const [pageSize, setPageSize] = useState(context.tx_per_page);
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
    setCurrentPage(1);
    setPageSize(_default_page_size);
    getList(
      '', false,
      1,
      _default_page_size,
      context.transaction_filters.firstDate,
      context.transaction_filters.lastDate,
      context.transaction_filters.country,
      context.transaction_filters.currency,
      context.transaction_filters.sites,
      context.transaction_filters.search,
      context.transaction_filters.status,
    );
    }
  }, [context.transaction_filters]);

  useEffect(() => {
    getList(
      '', false,
      currentPage,
      pageSize,
      context.transaction_filters.firstDate,
      context.transaction_filters.lastDate,
      context.transaction_filters.country,
      context.transaction_filters.currency,
      context.transaction_filters.sites,
      context.transaction_filters.search,
      context.transaction_filters.status,
    );
  }, []);

  const getList = (
    orderBy: string,
    desc: boolean,
    currentPage: number,
    pageSize: number,
    filterFrom?: any,
    filterTo?: any,
    country?: string,
    currency?: string,
    searchSite?: string[],
    searchName?: string,
    searchStatus?: string,
  ) => {
    setLoading(true);
    context.setTransactionFilters('tx_current_page', currentPage );
    context.setTransactionFilters('tx_per_page', pageSize );
    const params = {filterFrom: filterFrom.format('DD/MM/YYYY'), filterTo: filterTo.format('DD/MM/YYYY'), country, currency, searchName, searchStatus, startRow: (currentPage - 1) * pageSize, rows: pageSize, orderBy, desc}
    listService(params, searchSite)
    .then((response) => {
      setList(response.data)
      setTotal(response.total)
    })
    .catch((error) => console.log(error))
    .finally(() => setLoading(false))
  };

  const exportCsvData = async () => {
    const payload = {
      filterFrom: context.transaction_filters.firstDate.format('DD/MM/YYYY'),
      filterTo: context.transaction_filters.lastDate.format('DD/MM/YYYY'),
      country: context.transaction_filters.country,
      currency: context.transaction_filters.currency,
      searchName: context.transaction_filters.search,
      searchStatus: context.transaction_filters.status
    }
    setLoading(true);
    exportData(payload, context.transaction_filters.sites)
    .then((response) => {
      if(response) {
        window.open(response.fileUrl);
        openSuccessNotification('Report is successfully generated');
      }
    })
    .catch((error) => console.log(error))
    .finally(() => setLoading(false))
  };

  const onChangePage = (pagination: any, filters: any, sorter:any) => {
    if (sorter.order) {
      pagination.orderBy = upperFirst(sorter.column?.key);
      pagination.desc = sorter.order === 'descend';
    } else {
      pagination.orderBy = '';
      pagination.desc = false;
    }
    setCurrentPage(pagination.current)
    setPageSize(pagination.pageSize)
    getList(
      pagination.orderBy,
      pagination.desc,
      pagination.current,
      pagination.pageSize,
      context.transaction_filters.firstDate,
      context.transaction_filters.lastDate,
      context.transaction_filters.country,
      context.transaction_filters.currency,
      context.transaction_filters.sites,
      context.transaction_filters.search,
      context.transaction_filters.status,
    );
  }

  return (
    <>
      <PageTitleComponent page_title="Transaction History Report" />
      <Filters transactionType="transaction_filters" list={list} exportCsvData={exportCsvData} />
      <TransactionTable
       onChangePage={onChangePage}
       getList={getList}
       list={list}
       total={total}
       pageSize={pageSize}
       currentPage={currentPage}
       loading={loading} />
    </>
  );
};

