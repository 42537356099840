import InputForm from '../../../../_common/components/forms/InputForm';
import React from 'react';
import { Button, Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { IMobilumPartnerDetailsUserInformationFormProps } from '../interfaces/IMobilumPartnerDetailsUserInformationFormProps';
import { validateMessages } from '../../../../_common/ValidationCominications';

export class MobilumPartnerDetailsUserInformationForm extends React.Component<IMobilumPartnerDetailsUserInformationFormProps> {
    _form_ref = React.createRef<FormInstance>();

    public render() {
        return (
            <Form
                layout='vertical'
                name='control-ref'
                ref={this._form_ref}
                onFinish={this.onFinish}
                validateMessages={validateMessages()}
                initialValues={{ ...this.props.mobilum_partner_details }}
                className='flex' style={{ flexDirection: 'column', height: '100%' }}
            >
                <div className='column_description'>{this.props.title}</div>
                <div className='grid_col_2' style={{ marginBottom: 8 }}>
                    <Form.Item
                        name='adminName'
                        label='First Name'
                        rules={[{ required: true }]}
                    >
                        <InputForm
                            placeholder='Thomas'
                        />
                    </Form.Item>
                    <Form.Item
                        name='adminSurname'
                        label='Last Name'
                        rules={[{ required: true }]}
                    >
                        <InputForm
                            placeholder='Muller'
                        />
                    </Form.Item>
                </div>
                <Form.Item
                    name='adminEmail'
                    label='Email'
                    rules={[
                        { type: 'email' },
                        { required: true }
                    ]}
                >
                    <InputForm
                        placeholder='i.e, thomas@gmail.com'
                    />
                </Form.Item>
                <div className='flex grid_col_2' style={{ marginTop: 'auto' }}>
                    <Button
                        className='common_previous_button'
                        onClick={() => this.props.setCurrentStep(0)}
                    >
                        Previous
                    </Button>
                    <Form.Item shouldUpdate={true}>
                        {() => (
                            <Button
                                type='primary'
                                htmlType='submit'
                                className='common_next_button'
                                disabled={this.showSuimtuBtton()}
                            >
                                Next
                            </Button>
                        )}
                    </Form.Item>
                </div>
            </Form>
        );
    }

    showSuimtuBtton = () => {
        if (this._form_ref.current) {
            const errors = this._form_ref.current
                .getFieldsError()
                .filter(({ errors }) => errors.length).length;

            // Check if error
            if (errors) {
                return true;
            }
            else {
                return false;
            }
        }

        return true;
    };

    onFinish = (values: any) => {
        let {
            setCurrentStep,
            updateState,
            mobilum_partner_details
        } = this.props;

        mobilum_partner_details = { ...mobilum_partner_details, ...values };

        updateState(mobilum_partner_details);
        setCurrentStep(2);
    }
}
