import React from 'react';
import SearchElements from '../../../../_common/components/searchElements/SearchElements';
import { AddNewButtonComponent } from '../../../../_common/components/add_new_button/AddNewButtonComponent';
import { ClearBoth } from '../../../../_common/components/clearBoth/ClearBoth';
import { ConfigurationRoleForm } from '../forms/ConfigurationRoleForm';
import { ConfigurationRolesTable } from '../tables/ConfigurationRolesTable';
import { getRolesService, toggleRolesService } from '../../../../_services/roles/RolesServices';
import { IRole } from '../interfaces/IRole';
import { Left } from '../../../../_common/components/left/Left';
import { loading_icon } from '../../../../_common/components/loading/LoadingIcon';
import { Modal, Row, Spin } from 'antd';
import { openSuccessNotification } from '../../../../_heplers/NotificationHelper';
import { PageTitleComponent } from '../../../../_common/components/page_title/PageTitleComponent';
import { Right } from '../../../../_common/components/right/Right';

export class ConfigurationRolesContent extends React.Component<any> {
    _is_mounted = true;

    state = {
        loading: true,
        roles: Array<IRole>(),
        search: '',
        // Modal
        show_modal: false,
        modal_data: {} as IRole
    };

    componentDidMount = () => {
        this.getRoles();
    }

    componentWillUnmount = () => {
        this._is_mounted = false;
    }
    
    onGlobalSearch = (event: any) => {
        this.setState({ [event.target.name]: event.target.value.toLocaleLowerCase() });
    }

    public render() {
        const {
            loading,
            roles,
            search,
            // Modal
            show_modal,
            modal_data
        } = this.state;
        const {context } = this.props
        return (
            <>
                <Row align='middle' justify='space-between'>
                    <Left>
                        <PageTitleComponent page_title='Roles' />
                    </Left>
                   <Right>
                        {context.app_user_inf.permissions?.includes('CanEditRoles') && (<AddNewButtonComponent
                            title="Add New Role"
                            onClick={() => this.setModalVisible(true)}
                        />)}
                    </Right>
                </Row>
                <ClearBoth />
                <SearchElements placeholder="Search by name" onSearch={this.onGlobalSearch} showIcon />
                <Spin className='spin_60' spinning={loading} indicator={loading_icon(40)}>
                    <ConfigurationRolesTable
                        data={roles?.filter((role: any) => role.name?.toLowerCase().includes(search!))}
                        changeRoleStatus={this.changeRoleStatus}
                        updateObjectInArray={this.editRole}
                        context={context}
                    />
                </Spin>
                <Modal
                    title={modal_data?.id ? 'Edit Role' : 'Add New Role'}
                    visible={show_modal}
                    closable={false}
                    footer={false}
                    width={500}
                    destroyOnClose
                >
                    <ConfigurationRoleForm
                        data={modal_data}
                        setModalVisible={this.setModalVisible}
                        updateObjectInArray={this.updateObjectInArray}
                        addObjectToArray={this.addObjectToArray}
                        context={context}
                    />
                </Modal>
            </>
        );
    }

    setModalVisible = (show_modal: boolean) => {
        this.setState({ show_modal, modal_data: {} });
    }

    getRoles = async () => {
        this.setState({ loading: true });

        await getRolesService()
            .then(response => {
                const roles: Array<IRole> = response.map((x: any) => {
                    return {
                        key: x.id,
                        ...x
                    }
                });

                this._is_mounted && this.setState({ roles });
            })
            .finally(() => this._is_mounted && this.setState({ loading: false }));
    }

    editRole = (modal_data: IRole) => {
        this.setState({
            modal_data,
            show_modal: true
        });
    }

    addNewRole = () => {
        this.setState({
            modal_data: undefined,
            show_modal: true
        });
    }

    changeRoleStatus = async (object: IRole) => {
        let { roles } = this.state;

        object.isActive = !object.isActive;

        await toggleRolesService(object.id, object.isActive)
            .then(() => {
                const index = roles.findIndex((x: IRole) => x.id === object.id);

                roles[index].isActive = object.isActive;

                this._is_mounted && this.setState({ roles });

                openSuccessNotification(`Role ${object.name} status updated`)
            });
    }

    updateObjectInArray = (role: IRole) => {
        let { roles } = this.state;
        const updatedRoles = [...roles];
        const index = roles.findIndex((x: IRole) => x.id === role.id);
        updatedRoles[index] = role;
        this._is_mounted && this.setState({
            roles: updatedRoles,
            modal_data: {},
            show_modal: false
        });
    }

    addObjectToArray = (role: IRole) => {
        const roles = [role, ...this.state.roles];

        this._is_mounted && this.setState({
            roles,
            modal_data: {},
            show_modal: false
        });
    }
}
