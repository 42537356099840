import TransactionsWalletTable from '../tables/TransactionsWalletTable';
import { Breadcrumb, Spin } from 'antd';
import { createAbsoluteUrl } from '../../../_heplers/UrlCreatorHelpres';
import { getAllTransactionsForWallet } from '../../../_services/wallets/WalletsServices';
import { Link, useParams } from 'react-router-dom';
import { loading_icon } from '../../../_common/components/loading/LoadingIcon';
import { memo, useEffect, useState } from 'react';
import { navPath } from '../../../_common/NavPath';
import { PageTitleComponent } from '../../../_common/components/page_title/PageTitleComponent';

export const TransactionsWalletContent = () => {
    const params = useParams();
    const [loading, setLoading] = useState(false);
    const [transactionsList, setTransactionsList] = useState([]);

    const getWalletsData = () => {
        setLoading(true);
        
        getAllTransactionsForWallet({ apiKey: params.siteUuid, wallet: params.wallet }).then((response: any) => (response.status === 200 && !response.data.errors?.length) && setTransactionsList(response.data.result))
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        getWalletsData();
    }, []);

    return (
        <>
            <Breadcrumb separator=">">
                <Breadcrumb.Item>
                    <Link to={createAbsoluteUrl(`${navPath.wallets}/${params.siteUuid}`)}>Wallets</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    {params.wallet}
                </Breadcrumb.Item>
            </Breadcrumb>
            <PageTitleComponent page_title='Transactions' />
            <Spin className='spin_60' spinning={loading} indicator={loading_icon(40)}>
                <TransactionsWalletTable data={transactionsList} />
            </Spin>
        </>
    )
}

export default memo(TransactionsWalletContent)
