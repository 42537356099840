

import * as React from 'react';

function MenuUserProfileIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="55" height="55" viewBox="0 0 55 55" fill="none" {...props}>
        <path d="M32 21C32 23.2091 30.2091 25 28 25C25.7909 25 24 23.2091 24 21C24 18.7909 25.7909 17 28 17C30.2091 17 32 18.7909 32 21Z" fill="#A3B0BF"/>
        <path d="M19 35C19 31.6863 21.6863 29 25 29L31 29C34.3137 29 37 31.6863 37 35V36H19L19 35Z" fill="#A3B0BF"/>
        <circle cx="27.5" cy="27.5" r="27.5" fill="#A3B0BF" fillOpacity="0.2"/>
        </svg>
    );
}

const MemoUserProfile_icon = React.memo(MenuUserProfileIcon);
export default MemoUserProfile_icon;
