import { AppContext } from '../../_common/app_context_provider/AppContextProvider';
import { HomeContent } from './content/HomeContent';
import { useContext } from 'react';

const HomeView = () => {
    const context = useContext(AppContext);

    return <HomeContent
        sites={context.dictionaries.sites}
        dashboard_filter_currency={context.dictionaries.dashboard_analytics_filter_currency}
        dashboard_filter_countries={context.dictionaries.dashboard_analytics_filter_countries}
    />;
}

export default HomeView;
