import * as React from 'react';

function MenuResetPassword(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 22 24" fill="none" {...props}>
            <path
                d="M9.6 20.698a8.815 8.815 0 01-5.213-2.98A8.836 8.836 0 018.19 3.668l-.229.406a.643.643 0 101.121.633l.968-1.72a.65.65 0 00-.226-.872L8.134 1.093a.645.645 0 00-.665 1.104l.376.227a10.127 10.127 0 00-4.438 3.022 10.127 10.127 0 000 13.108 10.104 10.104 0 005.977 3.415.645.645 0 00.217-1.27v0zM18.788 5.446a10.103 10.103 0 00-5.977-3.415.644.644 0 00-.217 1.27 8.814 8.814 0 015.215 2.98 8.835 8.835 0 01-3.804 14.05l.228-.405a.643.643 0 10-1.121-.633l-.968 1.72a.65.65 0 00.226.872l1.692 1.022a.643.643 0 10.664-1.104l-.376-.227a10.126 10.126 0 004.438-3.022 10.127 10.127 0 000-13.108v0z"
                fill="#000"
                stroke="#000"
                strokeWidth={0.4}
            />
            <path
                d="M11.098 5.727A3.954 3.954 0 007.15 9.68v.552a1.774 1.774 0 00-1.001 1.596V15.9c0 .977.794 1.772 1.769 1.772h6.357c.976 0 1.77-.795 1.77-1.772v-4.072c0-.702-.41-1.31-1.002-1.596V9.68c0-2.18-1.77-3.953-3.946-3.953v0zm0 1.289a2.665 2.665 0 012.66 2.664v.376h-5.32V9.68a2.665 2.665 0 012.66-2.664zm3.66 8.884a.483.483 0 01-.482.483H7.92a.483.483 0 01-.482-.483v-4.072c0-.266.216-.483.482-.483h6.357c.266 0 .482.216.482.483V15.9z"
                fill="#000"
                stroke="#000"
                strokeWidth={0.4}
            />
            <path
                d="M11.098 12.773a.644.644 0 00-.644.645v.892a.644.644 0 101.287 0v-.892a.644.644 0 00-.643-.645z"
                fill="#000"
            />
        </svg>
    );
}

const MemoMenuResetPassword = React.memo(MenuResetPassword);
export default MemoMenuResetPassword;
