import { _default_page_size, _hide_when_less_than, _page_size_options } from '../../../../_heplers/GlobalValues';
import { AppContext } from '../../../../_common/app_context_provider/AppContextProvider';
import {
  Button,
  Divider,
  Table,
  Tooltip
} from 'antd';
import { convertNumberToDateTimeString } from '../../../../_heplers/DateHelpers';
import { createAbsoluteUrl } from '../../../../_heplers/UrlCreatorHelpres';
import { Link } from 'react-router-dom';
import { navPath } from '../../../../_common/NavPath';
import { useContext, useState } from 'react';

export const PartnerAdminsTable = (props: any) => {
  const {
    data,
    userStatusModal,
  } = props;
  
  const context = useContext(AppContext);
  const [pageSize, setPageSize] = useState(context.partner_admin_filters.perPage);
  const validatePermission = useContext(AppContext).validatePermission;
  const availableSites = useContext(AppContext).app_user_inf?.availableSites;
  const [defaultCurrent, setDefaultCurrentPage] = useState(context.partner_admin_filters.currentPage);

 
  const onPageChange = (currentPage: number, pageSize: number) => {
    setDefaultCurrentPage(currentPage);
    setPageSize(pageSize);
    context.setPartnerAdminFilters({ ...context.partner_admin_filters, currentPage, perPage: pageSize })
  }

  let columns: any[] = [
    {
      title: 'Name',
      key: 'name',
      render: (record: any) => `${record.name || ''} ${record.surname || ''}`,
      width: 170
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 250
    },
    {
      title: 'Partner Account',
      dataIndex: 'selectedSites',
      key: 'selectedSites',
      width: 200,
      render: (record: any) => (
        availableSites.find((site: any) => site.siteUuid === record[0])?.siteName || '-'
      )
    },
    {
      title: 'User Role',
      dataIndex: 'userRole',
      key: 'userRole',
      width: 200,
    },
    {
      title: 'Last Seen',
      key: 'lastSeen',
      width: 130,
      render: (record: any) => (
        convertNumberToDateTimeString({ number: record.lastSeen, request_date_format: 'DD-MMM-YY' })
      )
    }
  ];

  validatePermission('EditUsers') && columns.push(
    {
      title: 'Actions',
      key: 'actions',
      fixed: 'right',
      width: 130,
      render: (record: any) => (
        <>
          {validatePermission('EditUsers') && (
            <Tooltip placement="rightTop" title="Edit user">
              <Link
                to={createAbsoluteUrl(
                  `${navPath.partner_admins}/${record.id}`,
                )}
              >
                <Button
                  size="small"
                  type="link"
                  style={{ fontSize: '12px', padding: '5px' }}
                >
                  Edit
                </Button>
              </Link>
            </Tooltip>
          )}

          <Divider
            type="vertical"
            style={{ margin: 0, background: '#458FE6' }}
          />
          {validatePermission('EditUsers') && (
            <Tooltip
              placement="rightTop"
              title={record.disabled ? 'Activate' : 'Deactivate'}
            >
              <Button
                size="small"
                type="link"
                onClick={() => userStatusModal(record)}
                style={{ fontSize: '12px', padding: '3px' }}
              >
                {record.disabled ? 'Activate' : 'Deactivate'}
              </Button>
            </Tooltip>
          )}
        </>
      )
    }
  )

  return (
    <Table
      columns={columns}
      dataSource={data}
      size='small'
      rowKey='id'
      pagination={{
        pageSize: pageSize,
        defaultCurrent: defaultCurrent,
        onChange: (currentPage: number, pageSize: number) => onPageChange(currentPage, pageSize),
        showSizeChanger: true,
        pageSizeOptions: _page_size_options,
        hideOnSinglePage: data.length < _hide_when_less_than
      }}
    />
  )
}
