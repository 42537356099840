import React from 'react';
import SearchElements from '../../../_common/components/searchElements/SearchElements';
import { _default_page_size } from '../../../_heplers/GlobalValues';
import { Button, Select, Spin } from 'antd';
import { debounce } from 'lodash';
import { getBlockedUsersService, unblockBlockedUsersService } from '../../../_services/blocked_users/BlockedUsersServices';
import { getUsersServices, resetKyc } from '../../../_services/clients/ClientsService';
import { loading_icon } from '../../../_common/components/loading/LoadingIcon';
import { PageTitleComponent } from '../../../_common/components/page_title/PageTitleComponent';
import { RegisteredUsersTable } from '../tables/RegisteredUsersTable';
import { removeKyc } from '../../../_services/kyc_user_request/KycUserRequestService';

export class RegisteredUsersContent extends React.Component<any> {
  _is_mounted = true;

  state = {
    loading: false,
    registered_users: Array<any>(),
    // Pagination & Search
    current_page: this.props.context.registered_users_filters.currentPage,
    page_size: this.props.context.registered_users_filters.perPage,
    search_name:  this.props.context.registered_users_filters.search,
    total: undefined,
    searchSite:  this.props.context.registered_users_filters.site
  };

  componentDidMount = () => this.getRegisteredUsers();

  componentWillUnmount = () => this._is_mounted = false;

  //#region ---- Styles ----

  apply_filters_button = {
    width: 144,
    height: 48,
    borderRadius: 8,
    marginBottom: 16,
    marginRight: 11
  };

  button_style = {
    fontSize: 16,
    fontWeight: 700,
  };

  select_styles = {
  borderRadius: 8,
  backgroundColor: 'white',
  minWidth: 138,
  width: 'auto',
  marginBottom: 16,
  marginRight: 11,
  marginLeft: 11
};
  //#endregion ---- Styles ----

  public render() {
    const {
      loading,
      registered_users,
      total,
      current_page,
      page_size,
      search_name
    } = this.state;
    const { context } = this.props

    return (
      <>
        <PageTitleComponent page_title="Registered Users" />
        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
          <SearchElements width={425} placeholder="Search by name, email and user UUID" onSearch={this.onInputSearch} showIcon value={search_name}/>
          <Select
            placeholder='Partners'
            value={this.state.searchSite}
            onChange={this.searchBySite}
            className="filters"
            allowClear
            bordered={false}
            showSearch
            style={this.select_styles}
            filterOption={(input: any, option: any) => (option.props.children ? option.props.children : "").toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {context.app_user_inf &&
            context.app_user_inf.availableSites?.filter((item: any) => !item.archived).map((x: any) => (
                <Select.Option key={x.siteUuid} value={x.siteUuid}>
                  {x.siteName}
                </Select.Option>
              ))}
          </Select>
          <Button
            type="primary"
            style={this.apply_filters_button}
            disabled={loading}
            onClick={this.getAllData}
          >
            <div style={this.button_style}>View All</div>
          </Button>
        </div>
        <Spin
          className="spin_60"
          spinning={loading}
          indicator={loading_icon(40)}
        >
          <RegisteredUsersTable
            data={registered_users}
            total={total}
            current_page={current_page}
            page_size={page_size}
            changeUserBlockStatus={this.changeUserBlockStatus}
            onChangePage={this.onChangePage}
            resetKycAttempts={this.resetKycAttempts}
            removeKycAttempts={this.removeKycAttempts}
          />
        </Spin>
      </>
    );
  }

  getRegisteredUsers = async () => {
    const {
      search_name,
      current_page,
      page_size,
      searchSite
    } = this.state;

    this.setState({ loading: true });

    await getUsersServices((current_page - 1) * page_size, page_size, search_name, searchSite)
      .then((response) => {
        
        const registered_users = response.results.map((x: any) => {
          return {
            key: x.id,
            ...x
          };
        });

        this._is_mounted && this.setState({
          registered_users,
          total: response.total
        });
      })
      .finally(() => this._is_mounted && this.setState({ loading: false }));
  };
  getAllData = () => {
    this.setState({
      current_page: 1,
      search_name: undefined,
      searchSite: undefined
    }, () => this.getRegisteredUsers())
  }
  changeUserBlockStatus = (id: any, disabled: boolean, reason: string) => {
    if (disabled) {
      unblockBlockedUsersService(id, reason);
    }
    else {
      getBlockedUsersService(id, reason);
    }

    this.getRegisteredUsers();
  };

  resetKycAttempts = async (userId: any) => {
    await resetKyc(userId)
          .then(r=> {
            this.getRegisteredUsers();
          });
  };

  removeKycAttempts = (userId: any) => {
    removeKyc(userId);

    this.getRegisteredUsers();
  };

  //#region ---- Pagination & Search ----

  onChangePage = (obj: any) => {
    this.setState(
      {
        current_page: obj.current,
        page_size: obj.pageSize
      },
      this.getRegisteredUsers
    );
    
    this.props.context.setRegisteredUsersFilters({...this.props.context.registered_users_filters, currentPage: obj.current, perPage: obj.pageSize});
  };

  searchBySite = async (value: any) => {
    this.setState({ searchSite: value }, () => this.getRegisteredUsers())
    this.props.context.setRegisteredUsersFilters({...this.props.context.registered_users_filters, site: value});
  }
  debouncedSearch = debounce(() => {
    this.getRegisteredUsers()
  }, 1000);

  onInputSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ search_name: e.target.value.toLowerCase(), current_page: 1 });
    this.props.context.setRegisteredUsersFilters({...this.props.context.registered_users_filters, search: e.target.value.toLowerCase()});
    this.debouncedSearch();
}
  //#endregion ---- Pagination & Search ----
}
