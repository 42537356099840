import { AppContext } from '../../_common/app_context_provider/AppContextProvider';
import { NotificationsContent } from './content/NotificationsContent';
import { useContext } from 'react';

const NotificationsView = () => {
    const context = useContext(AppContext);

    return (
        <NotificationsContent
            loading_dictionaries={context.loading_dictionaries}
            dictionaries={context.dictionaries}
            available_sites={context.app_user_inf.availableSites.filter((item: any) => !item.archived).map((x: any) => { return { value: x.siteUuid, name: x.siteName } })}
        />
    )
}

export default NotificationsView;
