import { IDictionary } from '../_common/interfaces/IDictionary';
import { loading_icon } from '../_common/components/loading/LoadingIcon';
import { Spin } from 'antd';

export const findInDictionaryAndReturnValue = (value: string | number, array?: Array<IDictionary>) => {
    if (array) {
        const item = array.find(x => x.value == value);

        if (item) {
            return item.name;
        }

        return '-';
    }

    return 'Refresh page';
}

export const findInDictionaryAndReturnValueWithSpin = (value: string | number, loading: boolean, array?: Array<IDictionary>) => {
    return loading ? <Spin spinning indicator={loading_icon(10)} size='small' /> : findInDictionaryAndReturnValue(value, array);
}
