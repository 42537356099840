import styles from './styles/SelectFormStyle.module.scss';
import { Select } from 'antd';

const SelectForm = (props: any) => {
    return (
        <>
            {props.label ? <><label className={styles.input_form_label}>{props.label!}</label></> : true}
            <Select
                {...props}
                showSearch
                placeholder={props.placeholder!}
                className={`${props.className!}`}
                filterOption={(input: any, option: any) => (option.props.children ? option.props.children : "").toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
                {props.data?.map((x: any) => <Select.Option key={x.value} value={x.value}>{x.name}</Select.Option>)}
            </Select>
        </>
    );
}

export default SelectForm;
